import { RouteRecordRaw } from 'vue-router'

import { bookingsRouteNames } from './routeNames'
import { usersRouteNames } from '@player/users/router/routeNames'

export const bookingsRoutes: RouteRecordRaw[] = [
  {
    name: bookingsRouteNames.Booking,
    path: '/booking/:bookingId',
    component: () => import('../Booking'),
    props: true
  },
  {
    name: bookingsRouteNames.BookingConfirmation,
    path: '/booking/confirmation',
    component: () => import('../BookingConfirmation'),
    props: (route) => ({
      id: route.query.id
    })
  },
  {
    name: bookingsRouteNames.BookingNew,
    path: '/booking/new',
    component: () => import('../BookingNew'),
    meta: {
      requiresAuth: {
        auth: true,
        authRedirect: {
          name: usersRouteNames.SignIn
        }
      }
    },
    props: (route) => ({
      type: route.query.type,
      id: route.query.id
    })
  }
]
