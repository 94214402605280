import { checkboxHandler } from './checkboxHandler';
export function doKeyLogic(instance, event) {
    var key = event.key;
    var currentFocus = document.activeElement;
    /**
     * Escape Key
     */
    if (key == 'Escape') {
        instance.close();
    }
    /**
     * Arrow Down || Tab
     */
    if (key == 'ArrowDown' || key == 'Tab') {
        event.preventDefault();
        var indexOfOption = Array.prototype.indexOf.call(instance.optionEls, currentFocus);
        if (currentFocus == instance.selectEl) {
            instance.optionEls[0].focus();
        }
        if (indexOfOption != -1) {
            instance.optionEls[(indexOfOption + 1) % instance.optionEls.length].focus();
        }
    }
    /**
     * Arrow Up
     */
    if (key == 'ArrowUp') {
        var indexOfOption = Array.prototype.indexOf.call(instance.optionEls, currentFocus);
        if (currentFocus == instance.selectEl) {
            instance.optionEls[instance.optionEls.length - 1].focus();
        }
        if (indexOfOption != -1) {
            var newIndex = indexOfOption - 1 < 0
                ? instance.optionEls.length - 1
                : indexOfOption - 1;
            instance.optionEls[newIndex].focus();
        }
    }
    /**
     * Enter || Space
     */
    if (key == 'Enter' || key == ' ') {
        if (currentFocus == instance.selectEl) {
            instance.toggle();
        }
        else {
            var checkBox = currentFocus === null || currentFocus === void 0 ? void 0 : currentFocus.querySelector('input[type="checkbox"]');
            if (checkBox) {
                checkBox.checked = !checkBox.checked;
                checkboxHandler(instance, null, checkBox);
            }
        }
    }
}
