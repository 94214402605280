import favicon32 from '@base/images/favicons/favicon@32.png'
import favicon120 from '@base/images/favicons/favicon@120.png'
import favicon152 from '@base/images/favicons/favicon@152.png'
import favicon167 from '@base/images/favicons/favicon@167.png'
import favicon180 from '@base/images/favicons/favicon@180.png'
import favicon1024 from '@base/images/favicons/favicon@1024.png'

export const faviconHead = {
  link: [
    {
      rel: 'icon',
      type: 'image/png',
      href: favicon32
    },
    {
      rel: 'apple-touch-icon',
      sizes: '120x120',
      href: favicon120
    },
    {
      rel: 'apple-touch-icon',
      sizes: '152x152',
      href: favicon152
    },
    {
      rel: 'apple-touch-icon',
      sizes: '167x167',
      href: favicon167
    },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: favicon180
    },
    {
      rel: 'apple-touch-icon',
      sizes: '1024x1024',
      href: favicon1024
    }
  ]
}

export function openGraphHead() {
  return {}
}
