var ArrowSlider = /** @class */ (function () {
    function ArrowSlider(glide, prevBtn, nextBtn, numberOfItems, hideClassName) {
        var _this = this;
        if (numberOfItems === void 0) { numberOfItems = 1; }
        if (hideClassName === void 0) { hideClassName = 'hide'; }
        this.glide = glide;
        this.prevBtn = prevBtn;
        this.nextBtn = nextBtn;
        this.numberOfItems = numberOfItems;
        this.hideClassName = hideClassName;
        this.handleClick = function () {
            if (!_this.glide ||
                !_this.prevBtn ||
                !_this.nextBtn ||
                !_this.hideClassName ||
                _this.numberOfItems <= 1)
                return;
            if (_this.glide.index === 0)
                _this.prevBtn.classList.add(_this.hideClassName);
            else
                _this.prevBtn.classList.remove(_this.hideClassName);
            if (_this.glide.index === _this.numberOfItems - 1)
                _this.nextBtn.classList.add(_this.hideClassName);
            else
                _this.nextBtn.classList.remove(_this.hideClassName);
        };
        if (glide && prevBtn && nextBtn && numberOfItems >= 1) {
            prevBtn.addEventListener('click', this.handleClick);
            nextBtn.addEventListener('click', this.handleClick);
            this.handleClick();
        }
    }
    ArrowSlider.prototype.destroy = function () {
        if (this.prevBtn)
            this.prevBtn.removeEventListener('click', this.handleClick);
        if (this.nextBtn)
            this.nextBtn.removeEventListener('click', this.handleClick);
    };
    return ArrowSlider;
}());
export { ArrowSlider };
var GlideKeyboard = /** @class */ (function () {
    function GlideKeyboard(sliderSelector, glide) {
        var _this = this;
        if (sliderSelector === void 0) { sliderSelector = '.glide'; }
        this.sliderSelector = sliderSelector;
        this.glide = glide;
        this._handleKeyboardEvent = function (e) {
            if (e.key == 'ArrowRight')
                _this.glide.go('>');
            if (e.key == 'ArrowLeft')
                _this.glide.go('<');
        };
        this._handleFocus = function () {
            if (_this._slider)
                _this._slider.focus();
        };
        this._slider = document.querySelector(this.sliderSelector);
        if (!this._slider)
            return;
        this._slider.addEventListener('mouseover', this._handleFocus);
        this._slider.addEventListener('keyup', this._handleKeyboardEvent);
    }
    GlideKeyboard.prototype.destory = function () {
        if (this._slider) {
            this._slider.removeEventListener('mouseover', this._handleFocus);
            this._slider.removeEventListener('keyup', this._handleKeyboardEvent);
        }
    };
    return GlideKeyboard;
}());
export { GlideKeyboard };
