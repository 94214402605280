export function open(instance, direction) {
    var _a;
    instance.isOpen = true;
    instance.selectEl.classList.add('active');
    instance.selectEl.focus();
    var selectPos = instance.selectEl.getBoundingClientRect();
    var bottomHeight = document.documentElement.clientHeight - selectPos.bottom;
    var topHeight = selectPos.top;
    var selectHeight = ((_a = instance.optionsContainerEl) === null || _a === void 0 ? void 0 : _a.scrollHeight) || 0;
    if (instance.optionsContainerEl) {
        instance.optionsContainerEl.classList.remove('top');
        instance.optionsContainerEl.style.overflow = 'hidden';
        var paddingSpace = 30;
        if (direction === 'top' ||
            (bottomHeight < 400 && bottomHeight < topHeight && direction !== 'bottom')) {
            console.log('here');
            instance.optionsContainerEl.classList.add('top');
            selectHeight = Math.min(topHeight - paddingSpace, selectHeight);
        }
        else {
            selectHeight = Math.min(bottomHeight - paddingSpace, selectHeight);
        }
        selectHeight = Math.min(instance.maxDropdownHeight, selectHeight);
        var onTransitionEnd_1 = function () {
            if (selectHeight < instance.optionsContainerEl.scrollHeight || 0) {
                instance.optionsContainerEl.style.overflow = 'auto';
            }
            instance.optionsContainerEl.removeEventListener('transitionend', onTransitionEnd_1);
        };
        instance.optionsContainerEl.addEventListener('transitionend', onTransitionEnd_1);
        instance.optionsContainerEl.style.maxHeight = selectHeight + "px";
    }
}
export function close(instance) {
    instance.isOpen = false;
    instance.selectEl.classList.remove('active');
    if (instance.optionsContainerEl) {
        instance.optionsContainerEl.style.overflow = 'hidden';
    }
    instance.selectEl.focus();
    if (instance.optionsContainerEl) {
        instance.optionsContainerEl.style.maxHeight = "0px";
    }
}
export function toggle(instance, direction) {
    if (!instance.isOpen) {
        open(instance, direction);
    }
    else {
        close(instance);
    }
}
