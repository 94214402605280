import { defineStore } from 'pinia'

import { Toast, ToastSemantic } from '@base/_types/Toast'

import { v4 as uuidv4 } from 'uuid'

export const useToastsStore = defineStore({
  id: 'toasts',
  state: () => ({
    toasts: [] as Toast[]
  }),
  actions: {
    pushToast(toast: Toast) {
      const id = toast.id || uuidv4()
      this.toasts.push({ ...toast, id })

      setTimeout(() => {
        this.removeToast(id)
      }, 5000)
    },
    removeToast(id: string) {
      this.toasts = this.toasts.filter((toast) => toast.id !== id)
    },
    pushErrorToast(content: string) {
      this.pushToast({
        content,
        semantic: ToastSemantic.Error,
        icon: 'exclamation-square'
      })
    }
  }
})
