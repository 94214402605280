export function checkboxHandler(instance, e, checkbox) {
    var checkboxEl = null;
    if (e) {
        checkboxEl = e.target;
    }
    else if (checkbox) {
        checkboxEl = checkbox;
    }
    if (!checkboxEl)
        return;
    var checked = checkboxEl.checked;
    var optEl = checkboxEl.parentNode;
    var value = optEl.getAttribute('data-select-value') || '';
    if (!value)
        return;
    var newValues = instance.currentValues;
    if (checked) {
        optEl.classList.add('option--selected');
        if (instance.multiselect && !newValues.includes(value)) {
            newValues.push(value);
        }
        else if (!instance.multiselect) {
            newValues = [value];
        }
    }
    else {
        optEl.classList.remove('option--selected');
        if (newValues.includes(value)) {
            newValues.splice(newValues.indexOf(value), 1);
        }
    }
    if (instance.isOpen && !instance.multiselect) {
        instance.close();
    }
    if (!instance.multiselect) {
        instance.optionEls.forEach(function (optEl) {
            var checkboxInput = optEl.querySelector('input[type="checkbox"]');
            var value = optEl.getAttribute('data-select-value');
            if (value && checkboxInput && !newValues.includes(value)) {
                checkboxInput.checked = false;
                optEl.classList.remove('option--selected');
            }
        });
    }
    instance.currentValues = newValues;
    instance.updatedSelectedText();
    if ('update' in instance.eventCbs) {
        instance.eventCbs.update();
    }
}
