<template>
  <div
    class="base-template"
    data-test="base-template"
    :style="`visibility: ${visibility};`"
  >
    <div
      ref="header"
      class="base-template__header-wrapper"
      :style="`--header-min-height: ${headerMinHeight}`"
    >
      <div class="base-template__header-container">
        <slot name="header" />
      </div>
    </div>
    <main class="base-template__main">
      <slot />
    </main>
    <div ref="footer" class="base-template__footer-wrapper">
      <slot name="footer" />
    </div>
    <div ref="main-sibling" class="base-template__main-sibling">
      <slot name="main-sibling" />
    </div>
    <ToastWrapper
      :toasts="toasts"
      class="base-template__toasts"
      @clickToast="handleClickToast"
    />
  </div>
</template>

<script lang="ts" setup>
import {
  defineComponent,
  defineEmits,
  defineProps,
  onMounted,
  PropType,
  ref
} from 'vue'

import ToastWrapper from '@base/components/ToastsWrapper/ToastsWrapper.vue'

import { throttle } from '@base/helpers/throttle'
import { Toast } from '@base/_types/Toast'

defineComponent({
  name: 'BaseTemplate',
  components: { ToastWrapper }
})

defineProps({
  headerMinHeight: {
    type: String,
    default: '0'
  },
  toasts: {
    type: Array as PropType<Toast[]>,
    default: () => []
  }
})

const emit = defineEmits(['clickToast'])

const header = ref<HTMLElement>()
const visibility = ref('hidden')

const handleClickToast = (id: string) => {
  emit('clickToast', id)
}

onMounted(() => {
  let lastScroll = 0

  const headering = throttle(function () {
    if (!header.value) return

    if (window.scrollY < 50 || lastScroll - window.scrollY > 0) {
      header.value.classList.remove('base-template__header-wrapper--retracted')
    } else {
      header.value.classList.add('base-template__header-wrapper--retracted')
    }
    lastScroll = window.scrollY
  }, 16)

  window.addEventListener('scroll', headering)
  window.addEventListener('resize', headering)
  window.addEventListener('show-header', () => {
    if (!header.value) return
    header.value.classList.remove('base-template__header-wrapper--retracted')
  })
})

// Fix FOUC
onMounted(() => {
  visibility.value = 'visible'
})
</script>

<style lang="scss">
html,
body,
#app {
  height: 100%;
}
</style>

<style lang="scss" scoped>
@use 'sass:math';
@use '@base/styles/v1.0/scss/foundations/breakpoint' as bp;
@use '@base/styles/v1.0/scss/foundations/motion';

.base-template {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &__main {
    flex: 1 0 auto;
    overflow: hidden;
  }

  &__header-wrapper {
    min-height: var(--header-min-height);
    position: static;
    z-index: 300;

    &--retracted {
      .base-template__header-container {
        transform: translateY(-100%);
      }
    }
  }

  &__header-container {
    height: fit-content;
    min-width: bp.$breakpoint--min;
    position: fixed;
    top: 0;
    transition: transform motion.duration(3) ease-in;
    width: 100%;
    will-change: transform;
  }

  &__footer-wrapper {
    flex-shrink: 0;
  }

  &__toasts {
    bottom: var(--spacing--5);
    left: var(--grid--gutter-width);
    max-width: 540px;
    position: fixed;
    width: calc(100% - var(--grid--gutter-width) * 2);

    @include bp.min-width(bp.$breakpoint--max) {
      left: calc(
        50% - #{math.div(bp.$breakpoint--max, 2)} + var(--grid--gutter-width)
      );
    }
  }
}
</style>
