import { createSSRApp } from 'vue'
import { createHead } from '@vueuse/head'
import { createPinia } from 'pinia'

import { createClient } from '@base/api/createClient'
import App from './App.vue'
import { createRouter } from '@player/_router/router'

export function createApp(cookies?: string) {
  const app = createSSRApp(App)
  const pinia = createPinia()
  const client = createClient(cookies)
  const router = createRouter(pinia, client)
  const head = createHead()

  app.use(pinia).use(router).use(head)
  app.provide('apolloClient', client)

  return { app, router, head, pinia, client }
}
