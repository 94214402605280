<template>
  <div ref="menu" data-test="mobile-menu" class="mobile-menu">
    <button ref="close" class="mobile-menu__close">
      <USIcon name="x" :diameter="20" color="var(--color--primary-b1)" />
    </button>
    <div class="mobile-menu__container">
      <router-link
        class="mobile-menu__logo"
        :to="logoLink"
        @click="$emit('update:isOpen', false)"
      >
        <UpSwingLogo />
      </router-link>
      <ClubHeaderAvatar
        v-if="userType == 'operator'"
        :club="operatorClub.name"
        :profile-picture="operatorClub.profilePicture"
      />
      <div
        :class="[
          'mobile-menu__menu-items',
          userType != 'player' ? 'mobile-menu__menu-items--admin' : ''
        ]"
      >
        <MenuItemsWithBird
          v-if="userType == 'player'"
          :menu-items="menuItems"
          @navigate="$emit('update:isOpen', false)"
        />
        <MenuItemsWithIcon
          v-if="userType != 'player'"
          :menu-items="menuItems"
          @navigate="$emit('update:isOpen', false)"
        />
        <div
          v-if="secondaryMenuItems.length && userType != 'player'"
          class="mobile-menu__admin-items"
        >
          <router-link
            v-for="(link, idx) in secondaryMenuItems"
            :key="idx"
            :to="link.to"
            class="mobile-menu__secondary-menu-item body--p2--regular"
            @click="$emit('update:isOpen', false)"
          >
            {{ link.label }}
          </router-link>
          <USButton
            v-if="userType == 'operator'"
            type="inline"
            label="Sign Out"
            @click="$emit('signOut')"
          />
        </div>
      </div>
      <USBackground
        v-if="userType == 'player'"
        class="mobile-menu__background"
        :v-align="'bottom-0'"
        :img-args="{
          lazy: true,
          objectFit: 'contain',
          src: background,
          aspectRatio: '1.3:1'
        }"
      />
    </div>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  watch
} from 'vue'
import { RouteLocationRaw } from 'vue-router'

import USIcon from '@base/components/USIcon/USIcon.vue'
import UpSwingLogo from '@base/components/UpSwingLogo/UpSwingLogo.vue'
import USBackground from '@base/components/USBackground/USBackground.vue'
import USButton from '@base/components/USButton/USButton.vue'
import MenuItemsWithBird from '@base/components/MenuItemsWithBird/MenuItemsWithBird.vue'
import MenuItemsWithIcon from '@base/components/MenuItemsWithIcon/MenuItemsWithIcon.vue'
import ClubHeaderAvatar from '@base/components/ClubHeaderAvatar/ClubHeaderAvatar.vue'

import BackgroundImage from './assets/sidemenu.svg'

import { Lightbox } from '@base/@motiv/fe-patterns'

interface Action {
  label: string
  to: RouteLocationRaw
  icon?: string
}

interface Club {
  name: string
  profilePicture?: string
}

export default defineComponent({
  name: 'MobileMenu',
  components: {
    USIcon,
    UpSwingLogo,
    USBackground,
    USButton,
    MenuItemsWithBird,
    MenuItemsWithIcon,
    ClubHeaderAvatar
  },
  props: {
    isOpen: { type: Boolean, default: true },
    menuItems: {
      type: Array as PropType<Array<Action>>,
      default: () => []
    },
    logoLink: {
      type: Object as PropType<RouteLocationRaw>,
      default: () => ({ path: '/' })
    },
    operatorClub: {
      type: Object as PropType<Club>,
      default: () => ({})
    },
    userType: {
      type: String,
      default: 'player',
      validator: (value: string) => {
        return ['player', 'admin', 'operator'].includes(value)
      }
    },
    secondaryMenuItems: {
      type: Array as PropType<Array<Action>>,
      default: () => []
    }
  },
  emits: ['update:isOpen', 'signOut'],
  setup(props, { emit }) {
    const menu = ref<HTMLElement | undefined>(undefined)
    const close = ref<HTMLElement | undefined>(undefined)
    let lightbox: Lightbox | undefined

    onMounted(() => {
      if (!menu.value) return
      lightbox = new Lightbox(menu.value, undefined, close.value, undefined)
      lightbox.init()
      if (props.isOpen) lightbox.open()

      watch(
        () => props.isOpen,
        (newValue) => {
          if (!lightbox) return
          if (newValue) lightbox.open()
          if (!newValue) lightbox.close()
        }
      )

      lightbox.on('toggle', (e: boolean) => {
        emit('update:isOpen', e)
      })
    })

    onUnmounted(() => {
      if (lightbox) {
        lightbox.destroy()
        lightbox = undefined
      }
    })

    return { background: BackgroundImage, menu, close }
  }
})
</script>

<style lang="scss">
@use '@base/@motiv/fe-patterns/styles/lightbox' as *;

body {
  @include lightbox-body;
}
</style>

<style lang="scss" scoped>
@use '@base/@motiv/fe-patterns/styles/lightbox' as *;

@include lightbox('.mobile-menu', 0.3s);

$mobile-menu-logo-size: 135px;
$mobile-menu-height: 100vh;
$mobile-menu-padding: var(--spacing--6);

.mobile-menu {
  background: var(--color--neutral-a5);
  // height: $mobile-menu-height;
  overflow: hidden;
  padding: $mobile-menu-padding;
  width: 100%;

  &__close {
    width: 100%;

    :deep(.us-icon) {
      margin-left: auto;
    }
  }

  &__icon {
    cursor: pointer;
    margin-left: auto;

    :deep(svg) {
      color: var(--color--primary-b1);
    }
  }

  &__upswing-bird {
    --icon-color-upswing-bird: var(--color--secondary-c1);
    display: none;
    margin-right: var(--spacing--7);
    opacity: 0;
    width: 8px;
  }

  &__container {
    display: flex;
    flex-flow: column nowrap;
    height: $mobile-menu-height;
    justify-content: flex-start;
    padding-top: var(--spacing--5);
  }

  &__logo {
    align-self: center;
    width: $mobile-menu-logo-size;
    // width: 135px;
  }

  &__menu-items {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    padding-top: var(--spacing--2);
    z-index: 2;

    &--admin {
      align-items: flex-start;
    }
  }

  &__item {
    align-items: center;
    color: var(--color--primary-b2);
    cursor: pointer;
    display: flex;

    &:not(:last-child) {
      margin-bottom: var(--spacing--5);
    }

    &--admin {
      &:not(:last-child) {
        margin-bottom: var(--spacing--6);
      }
    }

    &:hover {
      .mobile-menu__upswing-bird {
        display: block;
        opacity: 1;
      }
    }
  }

  &__admin-items {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    margin-bottom: calc(var(--spacing--3) + 16px);
    height: 100%;
  }

  &__secondary-menu-item {
    align-items: center;
    color: var(--color--neutral-a3);
    cursor: pointer;
    display: flex;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: var(--spacing--7);
    }
  }

  &__background {
    bottom: auto !important;
    left: 10% !important;
    position: absolute;
    top: 30%;
    transform: rotate(0deg);
    width: 125%;
    z-index: -1;
  }
}

.router-link-active .sidebar-menu__nav-upswing-bird {
  --icon-color-upswing-bird: var(--color--primary-b1);
  display: block;
  opacity: 1;
}

.router-link-active .sidebar-menu__link--admin-hover {
  background-color: var(--color--secondary-c2);
}
</style>
