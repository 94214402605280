export enum ToastSemantic {
  Alert = 'alert',
  Error = 'error',
  Info = 'info',
  Success = 'success'
}

export type Toast = {
  id?: string
  content: string
  icon: string
  semantic: ToastSemantic
}
