// import fetch from 'isomorphic-unfetch'
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  HttpOptions
} from '@apollo/client/core'

export function createClient(cookies?: string) {
  const config: HttpOptions = {
    uri: import.meta.env.VITE_API_ENDPOINT,
    credentials: 'include',
    headers: {
      cookie: cookies
    }
  }
  // if (import.meta.env.SSR) {
  //   config.fetch = fetch
  // }

  const link = new HttpLink(config)
  return new ApolloClient({
    ssrMode: !!import.meta.env.SSR,
    link,
    cache: new InMemoryCache()
  })
}
