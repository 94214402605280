<template>
  <div
    data-test="us-dropdown-menu"
    :class="[
      'us-dropdown-menu body--p1--regular',
      { 'us-dropdown-menu--open': open },
      `us-dropdown-menu--${align}`
    ]"
    :data-dropdown-is="id"
  >
    <template v-for="(option, index) in options" :key="index">
      <LinkWrapper
        class="us-dropdown-menu__option"
        :link="{
          to: option.to ? option.to : undefined,
          path: option.href ? option.href : undefined
        }"
        @click="handleClickOption(option.label, index)"
      >
        <span
          :class="[
            'us-dropdown-menu__label',
            index < options.length - 1 ? 'us-dropdown-menu__label--border' : ''
          ]"
        >
          {{ option.label }}
        </span>
      </LinkWrapper>
    </template>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'

import LinkWrapper from '@base/components/LinkWrapper/LinkWrapper.vue'

import { DropdownAction as TypeDropdownAction } from '@base/_types/DropdownAction'

export default defineComponent({
  name: 'USDropdownMenu',
  components: { LinkWrapper },
  props: {
    options: {
      type: Array as PropType<TypeDropdownAction[]>,
      default: () => []
    },
    open: { type: Boolean, default: false },
    id: { type: [String, Number], required: true },
    align: {
      type: String,
      default: 'left',
      validator: (value: string) => {
        return ['left', 'right'].includes(value)
      }
    }
  },
  emits: ['close', 'clickOption'],
  mounted() {
    document.addEventListener('mousedown', this.closeOnClickOff)
  },
  methods: {
    closeOnClickOff(e: MouseEvent): void {
      const toggleButton = document.querySelector(
        `[data-dropdown-button="${this.id}"]`
      )

      if (
        this.open &&
        e.target != this.$el &&
        !this.$el.contains(e.target as Node)
      ) {
        if (!toggleButton) {
          this.$emit('close')
        } else if (
          e.target != toggleButton &&
          !toggleButton.contains(e.target as Node)
        ) {
          this.$emit('close')
        }
      }
    },

    handleClickOption(label: string, idx: number) {
      this.$emit('clickOption', label, idx)
      this.$emit('close')
    }
  },
  unMounted() {
    document.removeEventListener('mousedown', this.closeOnClickOff)
  }
})
</script>

<style lang="scss" scoped>
@use '@base/styles/v1.0/scss/foundations/breakpoint';
@use '@base/styles/v1.0/scss/foundations/motion';

.us-dropdown-menu {
  background-color: var(--color--neutral-a5);
  border-radius: var(--border-radius--small);
  box-shadow: var(--box-shadow--b1);
  color: var(--color--neutral-a1);
  margin-top: var(--spacing--9);
  position: absolute;
  overflow: hidden;
  opacity: 0;
  top: 100%;
  transition: opacity motion.duration(4) ease, visibility motion.duration(4);
  visibility: hidden;
  z-index: 2;

  &--left {
    border-top-left-radius: 0;
    left: 0;
  }

  &--right {
    border-top-right-radius: 0;
    right: 0;
  }

  &--open {
    opacity: 1;
    visibility: visible;
  }

  &__option {
    background-color: var(--color--neutral-a5);
    cursor: pointer;
    display: block;
    min-width: 150px;
    padding-left: var(--spacing--7);
    padding-right: var(--spacing--7);
    padding-top: var(--spacing--7);
    text-align: left;
    transition: motion.duration(2) ease-in-out;
    width: 100%;

    @include breakpoint.min-width(breakpoint.$breakpoint--tablet) {
      min-width: 200px;
    }
  }

  &__label {
    display: inline-block;
    padding-bottom: var(--spacing--7);
    width: 100%;

    &--border {
      border-bottom: var(--line-weight--light) solid var(--color--neutral-a4);
    }
  }
}
</style>
