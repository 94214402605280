<!-- Notes
  . One of `link.path` or `link.to` must be provided.
    If both provided, `link.to` takes priority.

  . `link.to` is intended to handle `RouteLocationRaw` objects.
    String routes can be passed via `link.path`
-->

<script setup lang="ts">
import { defineComponent, defineProps, PropType, computed } from 'vue'

import { Link } from '@base/_types/Link'

defineComponent({
  name: 'LinkWrapper'
})

const props = defineProps({
  link: {
    type: Object as PropType<Link>,
    required: true
  }
})

// https://developer.mozilla.org/en-US/docs/Web/API/Navigator/registerProtocolHandler#permitted_schemes
const defaultUrlSchemes = [
  'bitcoin',
  'geo',
  'im',
  'irc',
  'ircs',
  'magnet',
  'mailto',
  'matrix',
  'mms',
  'news',
  'nntp',
  'openpgp4fpr',
  'sip',
  'sms',
  'smsto',
  'ssh',
  'tel',
  'urn',
  'webcal',
  'wtai',
  'xmpp'
]
const pathSpecifiesScheme = (path: string) => {
  for (let scheme of defaultUrlSchemes) {
    if (path.startsWith(`${scheme}:`)) {
      return true
    }
  }
  return false
}

const pathHasProtocol = (path: string) => {
  // https://stackoverflow.com/a/19709846
  const protocolRegex = new RegExp('^(?:[a-z]+:)?//', 'i')
  return protocolRegex.test(path)
}

const linkIsInternal = computed((): boolean => {
  if (props.link?.to) {
    return true
  } else if (props.link.path) {
    return pathHasProtocol(props.link.path) ||
      pathSpecifiesScheme(props.link.path)
      ? false
      : true
  }
  // Shouldn't reach here
  return false
})

const computedTo = computed(() => {
  if (props.link.to) {
    return props.link.to
  } else if (props.link.path) {
    const formattedPath = props.link.path?.startsWith('/')
      ? props.link.path
      : `/${props.link.path}`

    return formattedPath
  } else {
    return '#'
  }
})
</script>

<template>
  <!-- Important not to bind href if using router-link -->
  <template v-if="linkIsInternal">
    <router-link
      data-test="link-wrapper"
      :to="computedTo"
      :hreflang="link.hreflang"
      :rel="link.rel"
      :target="link.target ? link.target : '_self'"
    >
      <slot />
    </router-link>
  </template>

  <template v-else>
    <a
      data-test="link-wrapper"
      :href="link.path"
      :hreflang="link.hreflang"
      :rel="link.rel"
      :target="link.target ? link.target : '_self'"
    >
      <slot />
    </a>
  </template>
</template>
