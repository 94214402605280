<template>
  <div
    ref="root"
    :class="[
      'us-image',
      { 'us-image--lazy': lazy },
      { 'us-image--aspect-ratio': cssAspectRatio || arNumber > 0 }
    ]"
    data-test="usimage"
    :style="`--aspect-ratio: ${cssAspectRatio ? cssAspectRatio : arNumber}`"
  >
    <img
      v-bind="srcAttributes"
      :key="src"
      :title="title"
      :alt="alt"
      :style="`object-fit: ${objectFit};
    object-position: ${objectPosition}`"
      :sizes="sizes"
      draggable="false"
    />
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
  watch,
  computed,
  nextTick
} from 'vue'

import { Lazyload } from '@base/@motiv/fe-patterns'

export default defineComponent({
  name: 'USImage',
  props: {
    src: { type: String, default: '' },
    srcset: { type: String, default: '' },
    sizes: { type: String, default: '' },
    lazy: { type: Boolean, default: true },
    manualLazy: { type: Boolean, default: false },
    alt: { type: String, default: '' },
    title: { type: String, default: '' },
    aspectRatio: {
      type: String,
      default: '16:9',
      filter: (val: string) => {
        return ['16:9', '9:16', '4:3', '3:4', '3:1', '1:1', 'none'].includes(
          val
        )
      }
    },
    cssAspectRatio: {
      type: String,
      default: ''
    },
    objectFit: {
      type: String,
      default: 'none',
      validator: (val: string) => {
        return ['initial', 'cover', 'contain', 'fill', 'none'].includes(val)
      }
    },
    objectPosition: { type: String, default: '50% 50%' }
  },
  setup(props) {
    const root = ref<HTMLElement>()
    let lazyLoadController: Lazyload

    const arNumber = computed(() => {
      if (props.aspectRatio == 'none') return 0

      const arDimensions: string[] = props.aspectRatio.split(':')

      return parseInt(arDimensions[0]) / parseInt(arDimensions[1])
    })

    const srcAttributes = computed(() => {
      return !props.lazy && !props.manualLazy
        ? { src: props.src, srcset: props.srcset }
        : { 'data-src': props.src, 'data-srcset': props.srcset }
    })

    watch(srcAttributes, () => {
      nextTick(() => {
        if (!root.value) return

        const image: NodeListOf<HTMLElement> =
          root.value.querySelectorAll('img')
        lazyLoadController.reset(image)
      })
    })

    onMounted(() => {
      if (!root.value) return

      if (props.lazy) {
        const image: NodeListOf<HTMLElement> =
          root.value.querySelectorAll('img')

        lazyLoadController = new Lazyload(image)
        lazyLoadController.init()
      }
    })

    onUnmounted(() => {
      lazyLoadController?.destory()
    })

    return { root, arNumber, srcAttributes }
  }
})
</script>

<style lang="scss" scoped>
@use '@base/@motiv/fe-patterns/styles/aspect-ratio' as *;
@use 'sass:math';

.us-image {
  width: 100%;

  &.us-image--aspect-ratio {
    --aspect-ratio: math.div(16, 9);

    @include aspect-ratio($ar: var(--aspect-ratio), $fit-child: true);
  }

  img {
    height: 100%;
    width: 100%;
  }
}

.us-image--lazy {
  img {
    opacity: 0;
    transition: opacity 240ms ease-out; // TODO Add motion
    will-change: opacity;

    &.loaded {
      opacity: 1;
    }
  }
}
</style>
