export const iconsReference = {
  'target-bg': `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54"><g transform="translate(-443 -2126)"><circle fill="var(--background-color, #eaf6fc)" cx="27" cy="27" r="27" transform="translate(443 2126)"/><path fill="currentColor" d="M20,10.323A9.677,9.677,0,1,1,10.323,20,9.672,9.672,0,0,1,20,10.323M20,8A12,12,0,1,0,32,20,12,12,0,0,0,20,8Zm0,6.968A5.032,5.032,0,1,1,14.968,20,5.038,5.038,0,0,1,20,14.968m0-2.323A7.355,7.355,0,1,0,27.355,20,7.355,7.355,0,0,0,20,12.645Zm0,4.645A2.71,2.71,0,1,0,22.71,20,2.71,2.71,0,0,0,20,17.29Z" transform="translate(450 2133)"/></g></svg>`,

  'shopping-bag-bg': `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54"><g transform="translate(-444 -2052)"><circle fill="var(--background-color, #eaf6fc)" cx="27" cy="27" r="27" transform="translate(444 2052)"/><path fill="currentColor" d="M16.5,6a6,6,0,1,0-12,0H0V20.25A3.75,3.75,0,0,0,3.75,24h13.5A3.75,3.75,0,0,0,21,20.25V6Zm-6-3.75A3.754,3.754,0,0,1,14.25,6H6.75A3.754,3.754,0,0,1,10.5,2.25Zm8.25,18a1.5,1.5,0,0,1-1.5,1.5H3.75a1.5,1.5,0,0,1-1.5-1.5v-12H4.5v1.875a1.125,1.125,0,1,0,2.25,0V8.25h7.5v1.875a1.125,1.125,0,0,0,2.25,0V8.25h2.25Z" transform="translate(460 2067)"/></g></svg>`,

  'trophy-bg': `<svg preserveAspectRatio xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54"><g transform="translate(-443 -2199)"><circle fill="var(--background-color, #eaf6fc)" cx="27" cy="27" r="27" transform="translate(443 2199)"/><path fill="currentColor"  d="M14.971,5.787l-1.808-.263-.808-1.638a.4.4,0,0,0-.708,0l-.808,1.638-1.808.263a.4.4,0,0,0-.221.675l1.308,1.275-.308,1.8a.4.4,0,0,0,.575.417L12,9.1l1.617.85a.4.4,0,0,0,.575-.417l-.308-1.8,1.308-1.275A.4.4,0,0,0,14.971,5.787Zm3.7-3.121v-2A.669.669,0,0,0,18,0H6a.669.669,0,0,0-.667.667v2H.667A.669.669,0,0,0,0,3.333V5.867c0,2.513,2.846,5.217,6.65,5.942A7,7,0,0,0,11,15.867v3.467H8.333A1.666,1.666,0,0,0,6.667,21,.334.334,0,0,0,7,21.333H17A.334.334,0,0,0,17.333,21a1.666,1.666,0,0,0-1.667-1.667H13V15.867a7.009,7.009,0,0,0,4.35-4.058c3.8-.721,6.65-3.425,6.65-5.942V3.333a.669.669,0,0,0-.667-.667ZM2,5.867v-1.2H5.333a19.236,19.236,0,0,0,.513,4.867C3.55,8.746,2,7.079,2,5.867ZM12,14c-2.208,0-4.667-3.267-4.667-9V2h9.333V5C16.667,10.854,14.133,14,12,14ZM22,5.867c0,1.213-1.55,2.879-3.846,3.667a19.256,19.256,0,0,0,.513-4.867H22Z" transform="translate(458 2215.333)"/></g></svg>`,

  trophy: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.25 18"><path fill="currentColor"  d="M12.632,4.883l-1.526-.221L10.424,3.28a.333.333,0,0,0-.6,0L9.144,4.662l-1.526.221a.334.334,0,0,0-.186.57l1.1,1.076-.26,1.519a.334.334,0,0,0,.485.352l1.364-.717,1.364.717a.335.335,0,0,0,.485-.352l-.26-1.519,1.1-1.076A.334.334,0,0,0,12.632,4.883ZM15.75,2.25V.563A.564.564,0,0,0,15.188,0H5.063A.564.564,0,0,0,4.5.563V2.25H.563A.564.564,0,0,0,0,2.813V4.95c0,2.12,2.4,4.4,5.611,5.013a5.9,5.9,0,0,0,3.67,3.424v2.925H7.031a1.406,1.406,0,0,0-1.406,1.406A.282.282,0,0,0,5.906,18h8.438a.282.282,0,0,0,.281-.281,1.406,1.406,0,0,0-1.406-1.406h-2.25V13.387a5.914,5.914,0,0,0,3.67-3.424c3.2-.608,5.611-2.89,5.611-5.013V2.813a.564.564,0,0,0-.562-.562ZM1.688,4.95V3.938H4.5a16.231,16.231,0,0,0,.432,4.106C3,7.379,1.688,5.973,1.688,4.95Zm8.438,6.862c-1.863,0-3.937-2.756-3.937-7.594V1.688h7.875V4.219C14.063,9.158,11.925,11.813,10.125,11.813ZM18.563,4.95c0,1.023-1.308,2.429-3.245,3.094a16.247,16.247,0,0,0,.432-4.106h2.813Z"/></svg>`,

  'present-bg': `<svg preserveAspectRatio xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54"><g transform="translate(-97 -2199)"><circle fill="var(--background-color, #eaf6fc)" cx="27" cy="27" r="27" transform="translate(97 2199)"/><path fill="currentColor"  d="M18.125,37h-1.02a3.406,3.406,0,0,0,.395-1.562A3.443,3.443,0,0,0,14.063,32c-1.625,0-2.676.832-4.023,2.668C8.691,32.832,7.641,32,6.016,32a3.443,3.443,0,0,0-3.437,3.438A3.358,3.358,0,0,0,2.973,37h-1.1A1.875,1.875,0,0,0,0,38.875v5a.627.627,0,0,0,.625.625H1.25v2.945A2.057,2.057,0,0,0,3.3,49.5H16.7a2.057,2.057,0,0,0,2.055-2.055V44.5h.625A.627.627,0,0,0,20,43.875v-5A1.875,1.875,0,0,0,18.125,37ZM9.063,47.625H3.3a.179.179,0,0,1-.18-.18V44.5H7.5V42.625H1.875v-3.75H9.063ZM6.012,37a1.563,1.563,0,0,1,0-3.125c.859,0,1.465.3,3.285,3.008L9.375,37Zm4.766-.117c1.82-2.711,2.426-3.008,3.285-3.008a1.563,1.563,0,0,1,0,3.125H10.7Zm7.348,5.742H12.5V44.5h4.375v2.945a.179.179,0,0,1-.18.18H10.938v-8.75h7.188Z" transform="translate(114 2185.25)"/></g></svg>`,

  'golf-club': `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 19.2"><defs></defs><path fill="currentColor" d="M23.66.32,23.12.06a.61.61,0,0,0-.8.28L17.46,10.47,2.84,7.73A2.4,2.4,0,0,0,0,9.64a2.28,2.28,0,0,0,0,.45V16.8a2.4,2.4,0,0,0,2.4,2.4H13.75a2.4,2.4,0,0,0,2.17-1.36l8-16.72A.6.6,0,0,0,23.66.32ZM16.31,12.86l-2,4.2a.63.63,0,0,1-.54.34H2.4c-.72,0-.6-.87-.6-.9H4.5a.3.3,0,0,0,.3-.3V15a.29.29,0,0,0-.3-.3H1.8V12.9H4.5a.3.3,0,0,0,.3-.3h0V11.4a.29.29,0,0,0-.3-.3H1.8v-1a.6.6,0,0,1,.6-.6h.11L15.88,12a.6.6,0,0,1,.48.7A.88.88,0,0,1,16.31,12.86Z"/></svg>`,

  'chevron-left-circle': `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44"><defs><style>.cls-1{fill:var(--background-color);}.cls-2{fill:none;stroke:currentColor;stroke-width:2px;}.cls-3{fill:currentColor;}</style></defs><g><g><circle class="cls-1" cx="22" cy="22" r="22"/><circle class="cls-2" cx="22" cy="22" r="21"/></g><path id="chevron-left" class="cls-3" d="M25.8,10.19l1,1.07a.68.68,0,0,1,0,.92L17.54,22l9.28,9.82a.68.68,0,0,1,0,.92l-1,1.07a.59.59,0,0,1-.83,0l0,0L14.18,22.46a.68.68,0,0,1,0-.92L24.93,10.18a.6.6,0,0,1,.84,0Z"/></g></svg>`,

  'chevron-right-circle': `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44"><defs><style>.cls-1{fill:var(--background-color);}.cls-2{fill:none;stroke:currentColor;stroke-width:2px;}.cls-3{fill:currentColor;}</style></defs><g><g><circle class="cls-1" cx="22" cy="22" r="22"/><circle class="cls-2" cx="22" cy="22" r="21"/></g><path id="chevron-left" class="cls-3" d="M18.2,33.81l-1-1.07a.68.68,0,0,1,0-.92L26.46,22l-9.28-9.82a.68.68,0,0,1,0-.92l1-1.07a.59.59,0,0,1,.83,0l0,0L29.82,21.54a.68.68,0,0,1,0,.92L19.07,33.82a.6.6,0,0,1-.84,0Z"/></g></svg>`,

  'chevron-left': `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 24"><path fill="currentColor" d="M11.81,23.81l1-1.07a.68.68,0,0,0,0-.92L3.54,12l9.28-9.82a.68.68,0,0,0,0-.92l-1-1.07a.6.6,0,0,0-.84,0l0,0L.18,11.54a.68.68,0,0,0,0,.92L10.93,23.81a.6.6,0,0,0,.84,0S11.79,23.82,11.81,23.81Z"/></svg>`,

  'chevron-right': `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 24"><path fill="currentColor" d="M1.2.2l-1,1.07a.68.68,0,0,0,0,.92L9.46,12,.18,21.82a.68.68,0,0,0,0,.92l1,1.07a.59.59,0,0,0,.83,0,.08.08,0,0,0,0,0L12.82,12.46a.68.68,0,0,0,0-.92L2.07.19a.6.6,0,0,0-.84,0Z"/></svg>`,

  'chevron-up': `<svg preserveAspectRatio xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 13"><path fill="currentColor"  d="M26.384,145.172l-1.073-1.016a.677.677,0,0,0-.921,0l-9.815,9.276L4.76,144.156a.677.677,0,0,0-.921,0l-1.073,1.016a.594.594,0,0,0,0,.873l11.348,10.749a.677.677,0,0,0,.921,0l11.348-10.749A.594.594,0,0,0,26.384,145.172Z" transform="translate(26.575 156.975) rotate(180)"/></svg>`,

  'chevron-down': `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 13"><path fill="currentColor"  d="M26.384,145.172l-1.073-1.016a.677.677,0,0,0-.921,0l-9.815,9.276L4.76,144.156a.677.677,0,0,0-.921,0l-1.073,1.016a.594.594,0,0,0,0,.873l11.348,10.749a.677.677,0,0,0,.921,0l11.348-10.749A.594.594,0,0,0,26.384,145.172Z" transform="translate(-2.575 -143.975)"/></svg>`,

  pencil: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g transform="translate(-1096.001 -255)"><path fill="currentColor"  d="M23.092,3.483,20.537.928a3.163,3.163,0,0,0-4.477,0L.615,16.374.033,21.62A2.146,2.146,0,0,0,2.4,23.987l5.241-.581L23.092,7.955a3.157,3.157,0,0,0,0-4.472ZM6.61,21.254l-4.327.483.483-4.327L13.8,6.371l3.849,3.849ZM21.5,6.366,19.239,8.63,15.39,4.781l2.264-2.264a.917.917,0,0,1,1.294,0L21.5,5.072a.917.917,0,0,1,0,1.294Z" transform="translate(1095.98 255)"/></g></svg>`,

  'lock-bg': `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60"><circle fill="var(--background-color, #eaf6fc)" cx="30" cy="30" r="30"/><path fill="currentColor"  d="M25.57,15.342H8.182V9.282A6.137,6.137,0,1,1,20.456,9.2v1.534a1.534,1.534,0,0,0,3.068,0V9.294A9.206,9.206,0,1,0,5.114,9.2v6.137H3.068A3.069,3.069,0,0,0,0,18.41V29.661A3.069,3.069,0,0,0,3.068,32.73h22.5a3.069,3.069,0,0,0,3.068-3.068V18.41A3.069,3.069,0,0,0,25.57,15.342Zm0,14.319H3.068V18.41h22.5ZM14.319,27.615a1.787,1.787,0,0,1-1.79-1.79v-3.58a1.79,1.79,0,1,1,3.58,0v3.58A1.787,1.787,0,0,1,14.319,27.615Z" transform="translate(15.681 13.635)"/></svg>`,

  'bell-bg': `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60"><circle fill="var(--background-color, #eaf6fc)" cx="30" cy="30" r="30"/><path fill="currentColor"  d="M27.194,21.156c-1.086-1.073-2.208-2.183-2.208-7.421A10.683,10.683,0,0,0,16.006,3.2a2.046,2.046,0,1,0-3.374,0A10.683,10.683,0,0,0,3.653,13.735c0,5.238-1.122,6.347-2.208,7.42a4.276,4.276,0,0,0,2.833,7.483h5.95a4.091,4.091,0,0,0,8.183,0h5.95A4.276,4.276,0,0,0,27.194,21.156ZM14.319,30.173a1.536,1.536,0,0,1-1.534-1.534h3.068A1.536,1.536,0,0,1,14.319,30.173Zm10.042-4.6H4.276a1.208,1.208,0,0,1-.852-2.058c1.827-1.827,3.3-3.562,3.3-9.778a7.6,7.6,0,1,1,15.2,0c0,6.242,1.484,7.963,3.3,9.778a1.207,1.207,0,0,1-.852,2.058Z" transform="translate(15.681 13.635)"/></svg>`,

  'person-bg': `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60"><circle fill="var(--background-color, #eaf6fc)" cx="30" cy="30" r="30"/><path fill="currentColor"  d="M22.7,14.509l-.72-.206a9.2,9.2,0,1,0-15.329,0l-.72.206A8.2,8.2,0,0,0,0,22.376v5.676a4.681,4.681,0,0,0,4.675,4.675H23.961a4.681,4.681,0,0,0,4.675-4.675V22.376A8.2,8.2,0,0,0,22.7,14.509ZM14.318,3.068A6.136,6.136,0,1,1,8.182,9.2,6.136,6.136,0,0,1,14.318,3.068Zm11.25,24.984a1.607,1.607,0,0,1-1.607,1.607H4.675a1.607,1.607,0,0,1-1.607-1.607V22.376a5.114,5.114,0,0,1,3.709-4.917l2.331-.666a9.209,9.209,0,0,0,10.421,0l2.331.666a5.114,5.114,0,0,1,3.709,4.917Z" transform="translate(16.363 13.638)"/></svg>`,

  grid: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><g ><rect  width="6.857" height="6.857"/><rect class="c" x="1" y="1" width="4.857" height="4.857"/></g><g  transform="translate(0 8.572)"><rect  width="6.857" height="6.857"/><rect class="c" x="1" y="1" width="4.857" height="4.857"/></g><g  transform="translate(8.571 8.572)"><rect  width="6.857" height="6.857"/><rect class="c" x="1" y="1" width="4.857" height="4.857"/></g><g  transform="translate(17.143 8.572)"><rect  width="6.857" height="6.857"/><rect class="c" x="1" y="1" width="4.857" height="4.857"/></g><g  transform="translate(0 17.143)"><rect  width="6.857" height="6.857"/><rect class="c" x="1" y="1" width="4.857" height="4.857"/></g><g  transform="translate(8.571 17.143)"><rect  width="6.857" height="6.857"/><rect class="c" x="1" y="1" width="4.857" height="4.857"/></g><g  transform="translate(17.143 17.143)"><rect  width="6.857" height="6.857"/><rect class="c" x="1" y="1" width="4.857" height="4.857"/></g><g  transform="translate(8.571)"><rect  width="6.857" height="6.857"/><rect class="c" x="1" y="1" width="4.857" height="4.857"/></g><g  transform="translate(17.143)"><rect  width="6.857" height="6.857"/><rect class="c" x="1" y="1" width="4.857" height="4.857"/></g></svg>`,

  calendar: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 18"><path fill="currentColor"  d="M13.393,2.25H11.786V.422a.413.413,0,0,0-.4-.422H10.045a.413.413,0,0,0-.4.422V2.25H5.357V.422A.413.413,0,0,0,4.955,0H3.616a.413.413,0,0,0-.4.422V2.25H1.607A1.649,1.649,0,0,0,0,3.938V16.313A1.649,1.649,0,0,0,1.607,18H13.393A1.649,1.649,0,0,0,15,16.313V3.938A1.649,1.649,0,0,0,13.393,2.25Zm-.2,14.063H1.808a.207.207,0,0,1-.2-.211V5.625H13.393V16.1A.207.207,0,0,1,13.192,16.313Z"/></svg>`,

  'map-pin': `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.25 18"><path fill="currentColor"  d="M11.25,5.625a5.625,5.625,0,1,0-6.469,5.562v5.647l.466.933a.422.422,0,0,0,.755,0l.466-.933V11.187A5.626,5.626,0,0,0,11.25,5.625ZM5.625,9.563A3.937,3.937,0,1,1,9.562,5.625,3.935,3.935,0,0,1,5.625,9.563Zm.281-6.187a.563.563,0,0,1-.562.562A1.408,1.408,0,0,0,3.937,5.344a.562.562,0,0,1-1.125,0A2.534,2.534,0,0,1,5.344,2.813.563.563,0,0,1,5.906,3.375Z" transform="translate(0 0)"/></svg>`,

  pennant: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 18"><path fill="currentColor"  d="M18.831,6.451c-.76.169-3.636.5-8.556-2.208A12.132,12.132,0,0,0,3.792,2.556,1.954,1.954,0,0,0,1.945,0a1.969,1.969,0,0,0-.833,3.741V17.719A.28.28,0,0,0,1.389,18H2.5a.28.28,0,0,0,.278-.281V15.061a11.12,11.12,0,0,1,5.757-1.976,29.118,29.118,0,0,0,4.691-.668,12.544,12.544,0,0,0,6.559-4.384.985.985,0,0,0-.955-1.582Zm-5.955,4.317a27.972,27.972,0,0,1-4.424.633,12.655,12.655,0,0,0-5.674,1.684V4.359a9.836,9.836,0,0,1,6.7,1.368,17.9,17.9,0,0,0,7.844,2.514A10.878,10.878,0,0,1,12.876,10.768Z"/></svg>`,

  share: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 24"><path fill="currentColor"  d="M21,19.5a4.5,4.5,0,1,1-8.792-1.356L7.6,15.263a4.5,4.5,0,1,1,0-6.526l4.61-2.881A4.5,4.5,0,1,1,13.4,7.763l-4.61,2.881a4.521,4.521,0,0,1,0,2.712l4.61,2.881A4.5,4.5,0,0,1,21,19.5ZM16.5,2.25A2.25,2.25,0,1,0,18.75,4.5,2.253,2.253,0,0,0,16.5,2.25m-12,7.5A2.25,2.25,0,1,0,6.75,12,2.253,2.253,0,0,0,4.5,9.75m12,7.5a2.25,2.25,0,1,0,2.25,2.25,2.253,2.253,0,0,0-2.25-2.25"/></svg>`,

  users: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 18"><path fill="currentColor"  d="M6.3,80.5H2.893A2.9,2.9,0,0,1,0,77.607v-1.52a4.3,4.3,0,0,1,2.759-4.01,4.5,4.5,0,0,1,6.576-5.758,6.328,6.328,0,0,0-.693,2.464A2.25,2.25,0,1,0,7.554,72.1a5.856,5.856,0,0,0-.616.807,6.084,6.084,0,0,0-.7,1.562A4.468,4.468,0,0,1,4.506,73.9l-.772.221A2.045,2.045,0,0,0,2.25,76.087v1.52a.643.643,0,0,0,.643.643H6v.7A4.157,4.157,0,0,0,6.3,80.5Zm20.942-8.424a4.5,4.5,0,0,0-6.576-5.758,6.329,6.329,0,0,1,.693,2.464A2.25,2.25,0,1,1,22.446,72.1a5.859,5.859,0,0,1,.62.814,6.088,6.088,0,0,1,.7,1.555,4.468,4.468,0,0,0,1.731-.571l.772.221a2.045,2.045,0,0,1,1.484,1.967v1.52a.643.643,0,0,1-.643.643H24v.7a4.157,4.157,0,0,1-.3,1.554h3.407A2.9,2.9,0,0,0,30,77.607v-1.52a4.3,4.3,0,0,0-2.759-4.01Zm-4.366,4.032v2.838A3.057,3.057,0,0,1,19.821,82H10.179a3.057,3.057,0,0,1-3.054-3.054V76.108A4.809,4.809,0,0,1,10.3,71.589a5.25,5.25,0,1,1,9.406,0A4.81,4.81,0,0,1,22.875,76.108ZM12,69.25a3,3,0,1,0,3-3A3,3,0,0,0,12,69.25Zm8.625,6.858a2.557,2.557,0,0,0-1.854-2.458l-.641-.183a5.255,5.255,0,0,1-6.258,0l-.641.183a2.557,2.557,0,0,0-1.854,2.458v2.838a.8.8,0,0,0,.8.8h9.643a.8.8,0,0,0,.8-.8Z" transform="translate(0 -64)"/></svg>`,

  camera: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 21"><g transform="translate(24) rotate(90)"><g transform="translate(-653.601 650) rotate(-90)"><path fill="currentColor"  d="M16.064,37.25H21.75v13.5H2.25V37.25H7.936l1.125-3h5.883l1.12,3ZM15.2,32H9.061a2.247,2.247,0,0,0-2.1,1.458L6.375,35H2.25A2.251,2.251,0,0,0,0,37.25v13.5A2.251,2.251,0,0,0,2.25,53h19.5A2.251,2.251,0,0,0,24,50.75V37.25A2.251,2.251,0,0,0,21.75,35H17.625l-.67-1.781A1.864,1.864,0,0,0,15.2,32ZM12,49.625A5.625,5.625,0,1,1,17.625,44,5.629,5.629,0,0,1,12,49.625Zm0-9A3.375,3.375,0,1,0,15.375,44,3.379,3.379,0,0,0,12,40.625Z" transform="translate(626 621.601)"/></g></g></svg>`,

  'knife-and-fork-bg': `<svg width="54" height="54" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54"><g transform="translate(-97 -2124)"><circle fill="var(--background-color, #eaf6fc)" cx="27" cy="27" r="27" transform="translate(97 2124)"/><path fill="currentColor"  d="M13.5,7.383a39.578,39.578,0,0,0-.731-5.236A2.774,2.774,0,0,0,9.872,0,3.353,3.353,0,0,0,8.311.366,3.37,3.37,0,0,0,6.75,0,3.37,3.37,0,0,0,5.189.366,3.34,3.34,0,0,0,3.628,0a2.773,2.773,0,0,0-2.9,2.147A39.348,39.348,0,0,0,0,7.383a6.058,6.058,0,0,0,3.272,5.47l-.478,8.47A2.542,2.542,0,0,0,5.344,24H8.156a2.542,2.542,0,0,0,2.55-2.677l-.478-8.475A6.052,6.052,0,0,0,13.5,7.383ZM7.889,11.3,8.461,21.45a.294.294,0,0,1-.3.3H5.344a.294.294,0,0,1-.3-.3L5.611,11.3A3.907,3.907,0,0,1,2.25,7.383a41.1,41.1,0,0,1,.694-4.814c.075-.431,1.327-.422,1.383.009v5.33c.042.5,1.322.506,1.383.009l.347-5.348c.075-.422,1.308-.422,1.383,0l.347,5.348c.061.5,1.341.487,1.383-.009V2.578c.056-.431,1.308-.441,1.383-.009a41.1,41.1,0,0,1,.694,4.814A3.9,3.9,0,0,1,7.889,11.3Zm10.369,3.258-.623,6.68A2.569,2.569,0,0,0,20.25,24h2.625A2.581,2.581,0,0,0,25.5,21.469V2.531A2.581,2.581,0,0,0,22.875,0C19.509,0,15,3.891,15,8.517a7.679,7.679,0,0,0,3.258,6.042ZM17.25,8.517c0-3.4,3.534-6.267,5.625-6.267.2,0,.375.131.375.281V21.469c0,.155-.173.281-.375.281H20.25c-.216,0-.389-.141-.375-.3l.741-7.945c-1.856-1.266-3.366-2.766-3.366-4.988Z" transform="translate(111 2139)"/></g></svg>`,

  'golf-club-bg': `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54"><g transform="translate(-90 -2053)"><circle fill="var(--background-color, #eaf6fc)" cx="27" cy="27" r="27" transform="translate(90 2053)"/><path fill="currentColor"  d="M29.583.479,28.908.155A.751.751,0,0,0,27.9.5L21.824,13.17,3.554,9.743A3,3,0,0,0,0,12.692v8.387a3,3,0,0,0,3,3H17.192a3,3,0,0,0,2.705-1.7L29.93,1.477a.75.75,0,0,0-.347-1ZM20.389,16.152,17.867,21.4a.758.758,0,0,1-.675.427H3c-.9,0-.75-1.078-.75-1.125H5.626A.376.376,0,0,0,6,20.329v-1.5a.376.376,0,0,0-.375-.375H2.25V16.2H5.626A.376.376,0,0,0,6,15.828v-1.5a.376.376,0,0,0-.375-.375H2.25V12.692a.745.745,0,0,1,.886-.736L19.85,15.092a.748.748,0,0,1,.539,1.06Z" transform="translate(102 2067.92)"/></g></svg>`,

  'clipboard-bg': `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54"><g transform="translate(-97 -2199)"><circle fill="var(--background-color, #eaf6fc)" cx="27" cy="27" r="27" transform="translate(97 2199)"/><path fill="currentColor"  d="M12.623,10.584a.471.471,0,0,0-.661,0L7.837,14.672l-1.786-1.8a.471.471,0,0,0-.661,0l-1.106,1.1a.471.471,0,0,0,0,.661l3.211,3.239a.471.471,0,0,0,.661,0l5.559-5.513a.471.471,0,0,0,0-.661l-1.092-1.106ZM15.75,3H12A3,3,0,1,0,6,3H2.25A2.251,2.251,0,0,0,0,5.25v16.5A2.251,2.251,0,0,0,2.25,24h13.5A2.251,2.251,0,0,0,18,21.75V5.25A2.251,2.251,0,0,0,15.75,3ZM9,2.25A.75.75,0,1,1,8.25,3,.752.752,0,0,1,9,2.25Zm6.75,19.125a.376.376,0,0,1-.375.375H2.625a.376.376,0,0,1-.375-.375V5.625a.376.376,0,0,1,.375-.375H4.5v1.5a.752.752,0,0,0,.75.75h7.5a.752.752,0,0,0,.75-.75V5.25h1.875a.376.376,0,0,1,.375.375Z" transform="translate(115 2214)"/></g></svg>`,

  'expand-photo': `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g transform="translate(-10 -10.453)"><path fill="currentColor"  d="M0,39.929V33.286A1.283,1.283,0,0,1,1.286,32H7.929a.645.645,0,0,1,.643.643v1.286a.645.645,0,0,1-.643.643H2.571v5.357a.645.645,0,0,1-.643.643H.643A.645.645,0,0,1,0,39.929Zm15.429-7.286v1.286a.645.645,0,0,0,.643.643h5.357v5.357a.645.645,0,0,0,.643.643h1.286A.645.645,0,0,0,24,39.929V33.286A1.283,1.283,0,0,0,22.714,32H16.071A.645.645,0,0,0,15.429,32.643Zm7.929,14.786H22.071a.645.645,0,0,0-.643.643v5.357H16.071a.645.645,0,0,0-.643.643v1.286a.645.645,0,0,0,.643.643h6.643A1.283,1.283,0,0,0,24,54.714V48.071A.645.645,0,0,0,23.357,47.429ZM8.571,55.357V54.071a.645.645,0,0,0-.643-.643H2.571V48.071a.645.645,0,0,0-.643-.643H.643A.645.645,0,0,0,0,48.071v6.643A1.283,1.283,0,0,0,1.286,56H7.929A.645.645,0,0,0,8.571,55.357Z" transform="translate(10 -21.547)"/></g></svg>`,

  x: `<svg viewBox="0 0 20 20"><defs><</defs><path fill="currentColor" d="M31.061,92.775l7.51-7.511a.693.693,0,0,0,0-.981l-1.3-1.3a.693.693,0,0,0-.981,0l-7.51,7.51-7.51-7.51a.693.693,0,0,0-.981,0l-1.3,1.3a.693.693,0,0,0,0,.981l7.51,7.511-7.51,7.51a.693.693,0,0,0,0,.981l1.3,1.3a.693.693,0,0,0,.981,0l7.51-7.51,7.51,7.51a.693.693,0,0,0,.981,0l1.3-1.3a.693.693,0,0,0,0-.981Z" transform="translate(-18.775 -82.775)"/></svg>`,

  'golf-ball': `<svg viewBox="0 0 19.5 24.03"><path fill="currentColor" d="M19.5,9.78a9.75,9.75,0,1,0-16.5,7v2.75a2.62,2.62,0,0,0,2.63,2.62h.75a.38.38,0,0,1,.37.38v.93a.57.57,0,0,0,.56.57H8.44A.57.57,0,0,0,9,23.46v-.93A2.62,2.62,0,0,0,6.38,19.9c-.6,0-1.13.1-1.13-.37V18h9v1.5c0,.47-.52.37-1.12.37a2.63,2.63,0,0,0-2.63,2.63v.93a.57.57,0,0,0,.56.57h1.13a.57.57,0,0,0,.56-.57v-.93a.38.38,0,0,1,.37-.38h.75a2.62,2.62,0,0,0,2.63-2.62V16.78A9.75,9.75,0,0,0,19.5,9.78ZM9.75,2.28a7.47,7.47,0,0,1,4.43,13.5H5.32a7.42,7.42,0,0,1-3.07-6A7.5,7.5,0,0,1,9.75,2.28ZM12,9a1.55,1.55,0,0,1-1.54,1.55A1.53,1.53,0,0,1,9,9.49a1.52,1.52,0,0,0,2-2A1.54,1.54,0,0,1,12,9Zm3.75.75a1.55,1.55,0,0,1-1.54,1.55,1.53,1.53,0,0,1-1.46-1,1.52,1.52,0,0,0,2-2,1.54,1.54,0,0,1,1,1.45Zm-3,3a1.55,1.55,0,0,1-1.54,1.55,1.53,1.53,0,0,1-1.46-1,1.52,1.52,0,0,0,2-2,1.54,1.54,0,0,1,1,1.45Z"/></svg>`,

  'golf-ball-bg': `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60"><g id="Group_6022" data-name="Group 6022"><circle fill="var(--background-color, #eaf6fc)" id="Ellipse_1266" data-name="Ellipse 1266" class="cls-1" cx="30" cy="30" r="30"/><path  id="golf-ball" class="cls-2" d="M43.29,26.93A13.29,13.29,0,1,0,20.8,36.49v3.74a3.58,3.58,0,0,0,3.58,3.58h1a.51.51,0,0,1,.51.51V45.6a.77.77,0,0,0,.77.76h1.53A.76.76,0,0,0,29,45.6V44.32a3.58,3.58,0,0,0-3.58-3.58c-.82,0-1.53.13-1.53-.51v-2H36.14v2c0,.65-.72.51-1.53.51A3.58,3.58,0,0,0,31,44.32V45.6a.77.77,0,0,0,.76.77h1.54a.77.77,0,0,0,.76-.77V44.32a.52.52,0,0,1,.52-.51h1a3.58,3.58,0,0,0,3.58-3.58V36.49A13.23,13.23,0,0,0,43.29,26.93ZM30,16.71a10.17,10.17,0,0,1,6,18.4H24a10.11,10.11,0,0,1-4.18-8.18A10.23,10.23,0,0,1,30,16.71Zm3.07,9.13a2.11,2.11,0,0,1-4.1.7,2.08,2.08,0,0,0,2.68-2.68,2.11,2.11,0,0,1,1.42,2Zm5.11,1a2.1,2.1,0,0,1-4.09.7,2.09,2.09,0,0,0,2.68-1.22,2.15,2.15,0,0,0,0-1.46A2.11,2.11,0,0,1,38.18,26.86ZM34.09,31a2.1,2.1,0,0,1-4.09.7,2.08,2.08,0,0,0,2.68-1.22,2.15,2.15,0,0,0,0-1.46,2.11,2.11,0,0,1,1.41,2Z"/></g></svg>`,

  'closed-book': `<svg viewBox="0 0 21 24"><path fill="currentColor" d="M6,7.13V5.63a.38.38,0,0,1,.38-.38h9.74a.38.38,0,0,1,.38.38v1.5a.38.38,0,0,1-.38.37H6.38A.38.38,0,0,1,6,7.13Zm.38,4.12h9.74a.38.38,0,0,0,.38-.37V9.38A.38.38,0,0,0,16.12,9H6.38A.38.38,0,0,0,6,9.38v1.5A.38.38,0,0,0,6.38,11.25Zm14,7.49a7.68,7.68,0,0,0,0,3,.76.76,0,0,1,.6.74v.75a.76.76,0,0,1-.75.75H3.75A3.75,3.75,0,0,1,0,20.25H0V3.75A3.75,3.75,0,0,1,3.75,0h16.5A.76.76,0,0,1,21,.75V18A.76.76,0,0,1,20.4,18.74Zm-1.93,0H3.75a1.5,1.5,0,0,0,0,3H18.47a11.59,11.59,0,0,1,0-3Zm.28-16.5h-15a1.5,1.5,0,0,0-1.5,1.5V16.81a3.91,3.91,0,0,1,1.5-.31h15Z"/></svg>`,

  'closed-book-bg': `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60"><g id="Group_6024" data-name="Group 6024"><circle fill="var(--background-color, #eaf6fc)" id="Ellipse_1282" data-name="Ellipse 1282" class="cls-1" cx="30" cy="30" r="30"/><path fill="currentColor" id="book" class="cls-2" d="M24.18,23.72v-2a.51.51,0,0,1,.51-.51H38a.51.51,0,0,1,.51.51v2a.51.51,0,0,1-.51.51H24.69A.51.51,0,0,1,24.18,23.72Zm.51,5.62H38a.51.51,0,0,0,.51-.51v-2a.51.51,0,0,0-.51-.51H24.69a.52.52,0,0,0-.51.51v2A.51.51,0,0,0,24.69,29.34ZM43.81,39.55a11,11,0,0,0,0,4.13,1,1,0,0,1,.83,1v1a1,1,0,0,1-1,1H21.11A5.11,5.11,0,0,1,16,41.62V19.11A5.11,5.11,0,0,1,21.11,14h22.5a1,1,0,0,1,1,1V38.55A1,1,0,0,1,43.81,39.55Zm-2.62,0H21.11A2,2,0,0,0,21,43.66H41.19A15.56,15.56,0,0,1,41.19,39.57Zm.38-22.5H21.11a2,2,0,0,0-2,2h0V36.93a5.11,5.11,0,0,1,2-.43H41.57Z"/></g></svg>`,

  person: `<svg viewBox="0 0 21 24.03"><path fill="currentColor" d="M16.65,10.65l-.53-.15a6.75,6.75,0,1,0-11.24,0l-.53.15A6,6,0,0,0,0,16.42v4.16A3.43,3.43,0,0,0,3.43,24H17.57A3.43,3.43,0,0,0,21,20.58V16.42A6,6,0,0,0,16.65,10.65ZM10.5,2.26A4.5,4.5,0,1,1,6,6.76,4.5,4.5,0,0,1,10.5,2.26Zm8.25,18.32a1.18,1.18,0,0,1-1.18,1.18H3.43a1.18,1.18,0,0,1-1.18-1.18h0V16.42A3.76,3.76,0,0,1,5,12.81l1.71-.48a6.75,6.75,0,0,0,7.64,0l1.71.48a3.75,3.75,0,0,1,2.72,3.61Z"/></svg>`,

  'upswing-bird': `<svg viewBox="0 0 78.44 66.15"><path fill="currentColor" d="M78.44,34.75C52.29,82,26.15,69.93,0,37.73l38.72-1C21.24,26.14,19.73,13.26,20.85,0,35.52,12.72,53.18,24.68,78.44,34.75Z"/></svg>`,

  'location-pin': `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M9,0A9,9,0,0,0,0,9c0,3.33,1.12,4.38,7.09,13.94a2.25,2.25,0,0,0,3.82,0C16.88,13.37,18,12.33,18,9A9,9,0,0,0,9,0ZM9,21.75C3,12.17,2.25,11.57,2.25,9a6.75,6.75,0,0,1,13.5,0C15.75,11.56,15,12.07,9,21.75ZM5.25,9A3.75,3.75,0,1,1,9,12.75,3.75,3.75,0,0,1,5.25,9Z"/></svg>`,

  globe: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 23.66"><path fill="currentColor" d="M12-.17a12,12,0,1,0,12,12A12,12,0,0,0,12-.17Zm8.68,7.74H17.43A16.11,16.11,0,0,0,15.92,3a9.72,9.72,0,0,1,4.76,4.58ZM12,2.15c.9,0,2.35,2,3.06,5.42H8.94C9.65,4.15,11.1,2.15,12,2.15ZM2.32,11.83a10.55,10.55,0,0,1,.2-1.94H6.28c0,.64-.08,1.28-.08,1.94s0,1.3.08,1.93H2.52A9.58,9.58,0,0,1,2.32,11.83Zm1,4.26H6.58a16.11,16.11,0,0,0,1.51,4.58,9.68,9.68,0,0,1-4.76-4.58ZM6.58,7.57H3.33A9.64,9.64,0,0,1,8.09,3,16.11,16.11,0,0,0,6.58,7.57ZM12,21.51c-.9,0-2.35-2-3.06-5.42h6.12C14.35,19.51,12.9,21.51,12,21.51Zm3.39-7.75H8.61c0-.61-.09-1.25-.09-1.93s0-1.32.09-1.94H15.4c0,.62.09,1.26.09,1.94S15.45,13.15,15.39,13.76Zm.53,6.9a16.65,16.65,0,0,0,1.51-4.58h3.25a9.74,9.74,0,0,1-4.76,4.59Zm1.81-6.9c0-.63.08-1.27.08-1.93s0-1.3-.08-1.94h3.76a10.13,10.13,0,0,1,0,3.88Z"/></svg>`,

  phone: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M22.34,1,17.92,0a2.15,2.15,0,0,0-2.45,1.25L13.43,6a2.15,2.15,0,0,0,.62,2.5L15.92,10a14.55,14.55,0,0,1-5.82,5.82L8.57,14a2.15,2.15,0,0,0-2.5-.62l-4.77,2A2.15,2.15,0,0,0,.05,17.83l1,4.42a2.15,2.15,0,0,0,2.09,1.66A20.81,20.81,0,0,0,24,3.1v0A2.12,2.12,0,0,0,22.34,1ZM3.25,21.67l-1-4.25,4.6-2,2.62,3.19A17,17,0,0,0,18.72,9.4L15.53,6.79l2-4.6,4.25,1A18.58,18.58,0,0,1,3.25,21.67Z"/></svg>`,

  email: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 18"><path fill="currentColor" class="cls-1" d="M21.75,0H2.25A2.25,2.25,0,0,0,0,2.25v13.5A2.25,2.25,0,0,0,2.25,18h19.5A2.25,2.25,0,0,0,24,15.75V2.25A2.25,2.25,0,0,0,21.75,0Zm0,2.25V4.16C20.7,5,19,6.35,15.44,9.15c-.79.63-2.35,2.12-3.44,2.1S9.35,9.78,8.56,9.15C5,6.35,3.3,5,2.25,4.16V2.25ZM2.25,15.75V7.05c1.08.86,2.6,2.06,4.92,3.87,1,.81,2.82,2.59,4.83,2.58s3.78-1.74,4.83-2.58c2.32-1.81,3.85-3,4.92-3.87v8.7Z"/></svg>`,

  facebook: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M24,33.323V54.671a1.323,1.323,0,0,1-1.323,1.323H16.559V46.705h3.118l.466-3.621H16.554V40.77c0-1.05.289-1.762,1.795-1.762h1.918V35.766a25.786,25.786,0,0,0-2.8-.145c-2.764,0-4.661,1.688-4.661,4.789v2.673H9.68v3.621h3.129V56H1.323A1.326,1.326,0,0,1,0,54.677V33.323A1.326,1.326,0,0,1,1.323,32H22.671A1.327,1.327,0,0,1,24,33.323Z" transform="translate(0 -32)"/></svg>`,

  linkedIn: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M22.286,32H1.709A1.722,1.722,0,0,0,0,33.73V54.27A1.722,1.722,0,0,0,1.709,56H22.286A1.726,1.726,0,0,0,24,54.27V33.73A1.726,1.726,0,0,0,22.286,32ZM7.254,52.571H3.7V41.118H7.259V52.571ZM5.475,39.554a2.063,2.063,0,1,1,2.062-2.062A2.063,2.063,0,0,1,5.475,39.554ZM20.588,52.571H17.03V47c0-1.329-.027-3.038-1.848-3.038-1.854,0-2.137,1.446-2.137,2.941v5.668H9.488V41.118H12.9v1.564h.048a3.747,3.747,0,0,1,3.37-1.848c3.6,0,4.27,2.373,4.27,5.459Z" transform="translate(0 -32)"/></svg>`,

  instagram: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M11.928,37.672a6.153,6.153,0,1,0,6.152,6.153A6.143,6.143,0,0,0,11.928,37.672Zm0,10.154a4,4,0,1,1,4-4A4.007,4.007,0,0,1,11.928,47.825ZM19.766,37.42a1.435,1.435,0,1,1-1.435-1.435A1.432,1.432,0,0,1,19.766,37.42Zm4.075,1.457A7.1,7.1,0,0,0,21.9,33.848a7.147,7.147,0,0,0-5.028-1.939c-1.981-.112-7.919-.112-9.9,0a7.137,7.137,0,0,0-5.028,1.933A7.127,7.127,0,0,0,.009,38.871c-.112,1.981-.112,7.921,0,9.9A7.1,7.1,0,0,0,1.948,53.8a7.156,7.156,0,0,0,5.028,1.939c1.981.112,7.919.112,9.9,0A7.1,7.1,0,0,0,21.9,53.8a7.15,7.15,0,0,0,1.938-5.029C23.953,46.792,23.953,40.858,23.841,38.877ZM21.281,50.9A4.05,4.05,0,0,1,19,53.181c-1.579.627-5.327.482-7.073.482s-5.5.139-7.073-.482A4.05,4.05,0,0,1,2.574,50.9c-.626-1.58-.482-5.329-.482-7.074s-.139-5.5.482-7.074a4.05,4.05,0,0,1,2.281-2.281c1.579-.627,5.327-.482,7.073-.482s5.5-.139,7.073.482a4.05,4.05,0,0,1,2.281,2.281c.626,1.58.482,5.329.482,7.074S21.908,49.325,21.281,50.9Z" transform="translate(0.075 -31.824)"/></svg>`,

  photo: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 43.68"><path fill="currentColor" d="M13.67,43.68C14.17,28,27.48,15.35,43.83,15.35a30.6,30.6,0,0,1,4.58.34v17a11,11,0,0,1-11,11Z"/><circle id="Ellipse_2" data-name="Ellipse 2" class="cls-1" cx="12.81" cy="12.71" r="6.61"/><path id="Exclusion_1" data-name="Exclusion 1" class="cls-1" d="M39,43.68H11a11,11,0,0,1-11-11V11A11,11,0,0,1,11,0H39A11,11,0,0,1,50,11V32.68a11,11,0,0,1-11,11ZM10.44,2.43a8,8,0,0,0-8,8V33.25a8,8,0,0,0,8,8H39.56a8,8,0,0,0,8-8V10.43a8,8,0,0,0-8-8Z"/></svg>`,

  drag: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 13.79"><path fill="currentColor" id="M_-_Icon_-_bars" data-name="M - Icon - bars" class="cls-1" d="M23.36,3.57H.64A.71.71,0,0,1,0,2.81v-2A.72.72,0,0,1,.64,0H23.36A.71.71,0,0,1,24,.77v2A.7.7,0,0,1,23.36,3.57Zm0,10.22H.64A.72.72,0,0,1,0,13V11a.72.72,0,0,1,.64-.77H23.36A.71.71,0,0,1,24,11v2A.71.71,0,0,1,23.36,13.79Z"/></svg>`,

  location: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 168c-48.6 0-88 39.4-88 88s39.4 88 88 88 88-39.4 88-88-39.4-88-88-88zm0 128c-22.06 0-40-17.94-40-40s17.94-40 40-40 40 17.94 40 40-17.94 40-40 40zm240-64h-49.66C435.49 145.19 366.81 76.51 280 65.66V16c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v49.66C145.19 76.51 76.51 145.19 65.66 232H16c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16h49.66C76.51 366.81 145.19 435.49 232 446.34V496c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-49.66C366.81 435.49 435.49 366.8 446.34 280H496c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16zM256 400c-79.4 0-144-64.6-144-144s64.6-144 144-144 144 64.6 144 144-64.6 144-144 144z"></path></svg>`,

  spinner: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z"></path></svg>`,

  trashcan: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 24"><path fill="currentColor" d="M9,8.81V18.94a.56.56,0,0,1-.56.56H7.31a.56.56,0,0,1-.56-.56V8.81a.56.56,0,0,1,.56-.56H8.44A.56.56,0,0,1,9,8.81Zm4.69-.56H12.56a.56.56,0,0,0-.56.56V18.94a.56.56,0,0,0,.56.56h1.13a.56.56,0,0,0,.56-.56V8.81a.56.56,0,0,0-.56-.56Zm6.19-4.5A1.12,1.12,0,0,1,21,4.88v.56a.56.56,0,0,1-.56.56H19.5V21.75A2.25,2.25,0,0,1,17.25,24H3.75A2.25,2.25,0,0,1,1.5,21.75V6H.56A.56.56,0,0,1,0,5.44V4.88A1.12,1.12,0,0,1,1.12,3.75H4.61l1.6-2.66A2.24,2.24,0,0,1,8.14,0h4.72a2.24,2.24,0,0,1,1.93,1.09l1.6,2.66Zm-12.64,0h6.52L13,2.39a.3.3,0,0,0-.25-.14H8.3a.27.27,0,0,0-.24.14ZM17.25,6H3.75V21.47a.29.29,0,0,0,.28.28H17a.29.29,0,0,0,.28-.28h0Z"/></svg>`,

  'double-check': `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.45 8.9"><path fill="currentColor" d="M10.28.09,3.66,6.74,1.23,4.3a.31.31,0,0,0-.43,0h0L.09,5a.3.3,0,0,0,0,.43h0L3.45,8.82a.31.31,0,0,0,.42,0h0l7.54-7.59a.3.3,0,0,0,0-.42h0L10.7.09a.29.29,0,0,0-.42,0Z"/><path id="D_-_Icon_-_check-2" data-name="D - Icon - check-2" class="cls-1" d="M14.22.09,7.61,6.74,5.18,4.3a.31.31,0,0,0-.43,0h0L4,5a.31.31,0,0,0,0,.43H4L7.39,8.82a.32.32,0,0,0,.43,0h0l7.54-7.59a.3.3,0,0,0,0-.42h0L14.65.09a.29.29,0,0,0-.42,0Z"/></svg>`,

  dollar: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"><path d="m 145,312 c -2,69 31,100 104,102 78,1 113,-34 109,-101 -6,-58 -62,-73 -106,-79 -48,-17 -99,-25 -99,-95 0,-48 32,-79 99,-78 60,0 97,25 96,84" style="fill:none;stroke:currentColor;stroke-width:50" /><path d="m 250,15 0,470" style="stroke:currentColor;stroke-width:40" /></svg>`,

  'check-circle': `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"></path></svg>`,

  'exclamation-square': `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M448 80v352c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48zm-48 346V86a6 6 0 0 0-6-6H54a6 6 0 0 0-6 6v340a6 6 0 0 0 6 6h340a6 6 0 0 0 6-6zm-134-74c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42zm-81.37-211.401l6.8 136c.319 6.387 5.591 11.401 11.985 11.401h41.17c6.394 0 11.666-5.014 11.985-11.401l6.8-136c.343-6.854-5.122-12.599-11.985-12.599h-54.77c-6.863 0-12.328 5.745-11.985 12.599z"></path></svg>`,

  clock: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"></path></svg>`,

  'clock-bg': `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60"><defs><style>fill="currentColor"</style></defs><circle fill="var(--background-color, #eaf6fc)" class="cls-1" cx="30" cy="30" r="30"/><path id="clock" class="cls-2" d="M30.36,14A16.37,16.37,0,1,0,46.73,30.36h0A16.36,16.36,0,0,0,30.37,14Zm0,29.56a13.2,13.2,0,1,1,13.2-13.2,13.2,13.2,0,0,1-13.2,13.2Zm4.08-6.89-5.6-4.07a.79.79,0,0,1-.32-.64V21.13a.79.79,0,0,1,.79-.79h2.11a.8.8,0,0,1,.8.79v9.35l4.4,3.2a.79.79,0,0,1,.18,1.11h0L35.55,36.5a.8.8,0,0,1-1.11.17Z"/></svg>`,

  bullhorn: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M544 184.88V32.01C544 23.26 537.02 0 512.01 0H512c-7.12 0-14.19 2.38-19.98 7.02l-85.03 68.03C364.28 109.19 310.66 128 256 128H64c-35.35 0-64 28.65-64 64v96c0 35.35 28.65 64 64 64l-.48 32c0 39.77 9.26 77.35 25.56 110.94 5.19 10.69 16.52 17.06 28.4 17.06h106.28c26.05 0 41.69-29.84 25.9-50.56-16.4-21.52-26.15-48.36-26.15-77.44 0-11.11 1.62-21.79 4.41-32H256c54.66 0 108.28 18.81 150.98 52.95l85.03 68.03a32.023 32.023 0 0 0 19.98 7.02c24.92 0 32-22.78 32-32V295.13c19.05-11.09 32-31.49 32-55.12.01-23.64-12.94-44.04-31.99-55.13zM127.73 464c-10.76-25.45-16.21-52.31-16.21-80 0-14.22 1.72-25.34 2.6-32h64.91c-2.09 10.7-3.52 21.41-3.52 32 0 28.22 6.58 55.4 19.21 80h-66.99zM240 304H64c-8.82 0-16-7.18-16-16v-96c0-8.82 7.18-16 16-16h176v128zm256 110.7l-59.04-47.24c-42.8-34.22-94.79-55.37-148.96-61.45V173.99c54.17-6.08 106.16-27.23 148.97-61.46L496 65.3v349.4z"></path></svg>`,

  plus: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"/></svg>`,

  eye: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"></path></svg>`,

  twitter: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 24"><path fill="currentColor" id="D_-_Icon_-_twitter" data-name="D - Icon - twitter" class="cls-1" d="M26,6c0,.26,0,.52,0,.79,0,8-6,17.23-16.91,17.23A16.61,16.61,0,0,1,0,21.28a11.51,11.51,0,0,0,1.43.07,11.7,11.7,0,0,0,7.38-2.58,6,6,0,0,1-5.55-4.2,7,7,0,0,0,1.12.09,5.87,5.87,0,0,0,1.56-.21A6,6,0,0,1,1.18,8.51V8.44a5.88,5.88,0,0,0,2.69.76A6.12,6.12,0,0,1,2,1.11,16.77,16.77,0,0,0,14.28,7.44a7.11,7.11,0,0,1-.15-1.38A6,6,0,0,1,20.08,0h0a5.87,5.87,0,0,1,4.34,1.91A11.42,11.42,0,0,0,28.19.45a6,6,0,0,1-2.61,3.34A12,12,0,0,0,29,2.85,13,13,0,0,1,26,6Z"/></svg>`,

  dots: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.43 30"><defs><style></style></defs><path fill="currentColor" id="D_-_Icon_-_ellipsis-v" data-name="D - Icon - ellipsis-v" class="cls-1" d="M3.72,11.29A3.57,3.57,0,1,1,0,14.85,3.65,3.65,0,0,1,3.72,11.29ZM0,3.56A3.71,3.71,0,1,0,3.86,0H3.72A3.65,3.65,0,0,0,0,3.56ZM0,26.14a3.71,3.71,0,1,0,3.86-3.56H3.72A3.66,3.66,0,0,0,0,26.14Z"/></svg>`,

  'star-bg': `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60"><defs><style>.cls-1{fill:var(--background-color);}</style></defs><g id="Group_6282" data-name="Group 6282"><circle id="Ellipse_351" data-name="Ellipse 351" class="cls-1" cx="30" cy="30" r="30"/><path fill="currentColor" class="cls-2" d="M43.46,25.26l-8.19-1.19-3.66-7.43a1.8,1.8,0,0,0-2.42-.8,1.77,1.77,0,0,0-.8.8l-3.66,7.43-8.19,1.19a1.8,1.8,0,0,0-1,3.06l5.93,5.77-1.4,8.16a1.79,1.79,0,0,0,1.47,2.07,1.72,1.72,0,0,0,1.13-.18L30,40.29l7.33,3.86a1.8,1.8,0,0,0,2.42-.78,1.78,1.78,0,0,0,.18-1.11l-1.4-8.16,5.93-5.78a1.79,1.79,0,0,0,0-2.54A1.73,1.73,0,0,0,43.46,25.26Zm-7.82,7.9L37,40.92l-7-3.66-7,3.66,1.33-7.76-5.64-5.5,7.79-1.14L30,19.46l3.49,7.07,7.79,1.13Z"/></g></svg>`,

  'piggybank-bg': `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60"><defs><style>.cls-1{fill:var(--background-color);}</style></defs><g id="Group_6358" data-name="Group 6358"><circle id="Ellipse_1300" data-name="Ellipse 1300" class="cls-1" cx="30" cy="30" r="30"/><path fill="currentColor" class="cls-2" d="M45.94,28.12H44.21a9.4,9.4,0,0,0-3-3.71V20.62h-.94a8.3,8.3,0,0,0-4.74,1.54q0-.3.06-.6a6.57,6.57,0,0,0-13.13-.11,6.46,6.46,0,0,0,.22,1.75A9.41,9.41,0,0,0,17.06,30H16.4A1.41,1.41,0,0,1,15,28.59a1.33,1.33,0,0,1,0-.28,1.43,1.43,0,0,1,1.43-1.12h.06a.36.36,0,0,0,.36-.35V25.66a.36.36,0,0,0-.36-.35,3.37,3.37,0,0,0-3.37,2.85A3.3,3.3,0,0,0,16,31.85c.14,0,.28,0,.42,0h.47a9.34,9.34,0,0,0,3.75,7.45v4.73a.94.94,0,0,0,.94.94h6.57a.94.94,0,0,0,.93-.94V41.25h3.75v2.81a.94.94,0,0,0,.94.94h6.56a.94.94,0,0,0,.94-.94V39.32a9.28,9.28,0,0,0,1.83-1.82h2.86a1,1,0,0,0,.94-.94v-7.5A1,1,0,0,0,45.94,28.12ZM29.06,17.81a3.75,3.75,0,0,1,3.75,3.75,3.68,3.68,0,0,1-.13.94H26.25c-.26,0-.52.06-.78.08a3.88,3.88,0,0,1-.16-1A3.75,3.75,0,0,1,29.06,17.81Zm15,16.88H41.68a10.65,10.65,0,0,1-3.24,3.24v4.26H35.62V38.44H26.25v3.75H23.44V37.93c-.86-.64-3.75-2.33-3.75-6.05a6.57,6.57,0,0,1,6.56-6.57h9.81a8.29,8.29,0,0,1,2.38-1.53v2c2.66,2,2.59,2.06,3.93,5.13h1.69ZM38.44,30a.94.94,0,1,0,.94.94h0A1,1,0,0,0,38.44,30Z"/></g></svg>`,

  'arrow-h': `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 11.26"><defs><style>.cls-1{fill:var(--background-color);}</style></defs><path fill="currentColor" class="cls-1" d="M23.43.63a.7.7,0,0,0,0,1h0l3,3H3.52l3-3a.71.71,0,0,0,0-1h0L6.15.21a.7.7,0,0,0-1,0h0L.21,5.12a.71.71,0,0,0,0,1h0l5,4.92a.7.7,0,0,0,1,0h0l.41-.41a.69.69,0,0,0,0-1h0l-3.05-3h23l-3,3a.7.7,0,0,0,0,1h0l.41.41a.7.7,0,0,0,1,0h0l4.95-4.92a.7.7,0,0,0,0-1h0L24.84.21a.7.7,0,0,0-1,0h0Z"/></svg>`,

  star: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><defs><style>.cls-1{fill:var(--background-color);}</style></defs><g><path class="cls-1" d="M39.73,49.46a.53.53,0,0,1-.31-.11L26,39.57a1.65,1.65,0,0,0-1.9,0L10.58,49.35a.53.53,0,0,1-.31.11.59.59,0,0,1-.44-.22.53.53,0,0,1-.08-.47l4.7-16.71a1.59,1.59,0,0,0-.54-1.7L.74,19.71a.53.53,0,0,1-.17-.6.52.52,0,0,1,.49-.36l16.49-.45A1.6,1.6,0,0,0,19,17.2L24.49.91a.54.54,0,0,1,1,0L31,17.2a1.6,1.6,0,0,0,1.48,1.1l16.49.45a.52.52,0,0,1,.49.36.53.53,0,0,1-.17.6L36.09,30.36a1.59,1.59,0,0,0-.54,1.7l4.7,16.71a.53.53,0,0,1-.08.47A.59.59,0,0,1,39.73,49.46Z"/><path fill="currentColor" d="M25,1.08h0L19.54,17.37a2.15,2.15,0,0,1-2,1.47l-16.48.45L14.25,29.94A2.14,2.14,0,0,1,15,32.2l-4.7,16.72,13.47-9.78a2.14,2.14,0,0,1,2.52,0l13.47,9.78h0L35,32.2a2.14,2.14,0,0,1,.72-2.26L48.92,19.29l-16.48-.45a2.15,2.15,0,0,1-2-1.47L25,1.08M25,0a1.06,1.06,0,0,1,1,.73L31.48,17a1.06,1.06,0,0,0,1,.73L49,18.21a1.08,1.08,0,0,1,.65,1.92L36.42,30.78a1.1,1.1,0,0,0-.36,1.13l4.71,16.71a1.08,1.08,0,0,1-1.67,1.17L25.63,40a1.08,1.08,0,0,0-1.26,0L10.9,49.79a1.08,1.08,0,0,1-1.67-1.17l4.71-16.71a1.1,1.1,0,0,0-.36-1.13L.4,20.13a1.08,1.08,0,0,1,.65-1.92l16.48-.45a1.06,1.06,0,0,0,1-.73L24,.73A1.06,1.06,0,0,1,25,0Z"/></g></svg>`,

  checkmark: `<svg xmlns="http://www.w3.org/2000/svg" width="19.479" height="15" viewBox="0 0 19.479 15"><path d="M44.784,80.1,33.578,91.307,29.46,87.19a.511.511,0,0,0-.723,0l-1.2,1.2a.511.511,0,0,0,0,.723L33.216,94.8a.511.511,0,0,0,.723,0L46.712,82.029a.511.511,0,0,0,0-.723l-1.2-1.2A.511.511,0,0,0,44.784,80.1Z" transform="translate(-27.382 -79.951)" fill="currentColor"/></svg>`,

  'arrow-right': `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="8.991" viewBox="0 0 14 8.991"><path d="M9.239,112.311l-.614.614a.375.375,0,0,0,.005.536l2.524,2.424H.375A.375.375,0,0,0,0,116.26v.875a.375.375,0,0,0,.375.375H11.154l-2.524,2.424a.375.375,0,0,0-.005.536l.614.614a.375.375,0,0,0,.531,0l4.121-4.121a.375.375,0,0,0,0-.531L9.77,112.311a.375.375,0,0,0-.531,0Z" transform="translate(0 -112.202)" fill="currentColor"/></svg>`,

  'arrow-left': `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="8.991" viewBox="0 0 14 8.991"><path d="M4.963,121.083l.614-.614a.375.375,0,0,0-.005-.536L3.047,117.51H13.826a.375.375,0,0,0,.375-.375v-.875a.375.375,0,0,0-.375-.375H3.047l2.524-2.424a.375.375,0,0,0,.005-.536l-.614-.614a.375.375,0,0,0-.531,0L.311,116.432a.375.375,0,0,0,0,.531l4.121,4.121A.375.375,0,0,0,4.963,121.083Z" transform="translate(-0.202 -112.202)" fill="currentColor"/></svg>`,

  play: `<svg xmlns="http://www.w3.org/2000/svg" width="26.342" height="30.108" viewBox="0 0 26.342 30.108"><path fill="currentColor" d="M24.955,12.655,4.257.418A2.807,2.807,0,0,0,0,2.847V27.314a2.82,2.82,0,0,0,4.257,2.429l20.7-12.231a2.82,2.82,0,0,0,0-4.857ZM2.822,26.7V3.47a.358.358,0,0,1,.535-.306L23.009,14.778a.353.353,0,0,1,0,.606L3.358,27A.358.358,0,0,1,2.822,26.7Z" transform="translate(0 -0.032)"/></svg>`,

  lock: `<svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="20" viewBox="0 0 17.5 20"><path d="M15.625,7.5h-1.25V5.679a5.625,5.625,0,1,0-11.25-.055V7.5H1.875A1.875,1.875,0,0,0,0,9.375v8.75A1.875,1.875,0,0,0,1.875,20h13.75A1.875,1.875,0,0,0,17.5,18.125V9.375A1.875,1.875,0,0,0,15.625,7.5ZM5,5.625a3.75,3.75,0,1,1,7.5,0V7.5H5Zm10.625,12.5H1.875V9.375h13.75Z" transform="translate(0 0)" fill="currentColor"/></svg>`,

  'text-bubble-bg': `<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><g transform="translate(-759 -2562)"><circle id="Ellipse_1316" data-name="Ellipse 1316" cx="25" cy="25" r="25" transform="translate(759 2562)" fill="var(--background-color, #eaf6fc)"  /><path d="M26.667,8.333h-5v-5A3.336,3.336,0,0,0,18.333,0h-15A3.336,3.336,0,0,0,0,3.333v8.333A3.336,3.336,0,0,0,3.333,15H5v2.708a.623.623,0,0,0,.625.625.6.6,0,0,0,.37-.125L10,15.943V20a3.336,3.336,0,0,0,3.333,3.333h5l5.672,3.208a.632.632,0,0,0,.37.125A.623.623,0,0,0,25,26.042V23.333h1.667A3.336,3.336,0,0,0,30,20V11.667A3.336,3.336,0,0,0,26.667,8.333ZM5,12.5H3.333a.836.836,0,0,1-.833-.833V3.333A.836.836,0,0,1,3.333,2.5h15a.836.836,0,0,1,.833.833v8.333a.836.836,0,0,1-.833.833H11.01l-.573.323L7.5,14.484V12.5ZM27.5,20a.836.836,0,0,1-.833.833H22.5v1.984l-2.937-1.661-.573-.323H13.333A.836.836,0,0,1,12.5,20V15h5.833a3.336,3.336,0,0,0,3.333-3.333v-.833h5a.836.836,0,0,1,.833.833Z" transform="translate(769 2573.667)" fill="currentColor" /></g></svg>`,

  'unlocked-bg': `<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><g id="Group_9599" data-name="Group 9599" transform="translate(-759 -2562)"><circle id="Ellipse_1316" data-name="Ellipse 1316" cx="25" cy="25" r="25" transform="translate(759 2562)" fill="var(--background-color)" /><path d="M23.437,14.062H7.5V8.507a5.625,5.625,0,1,1,11.25-.07V9.843a1.406,1.406,0,1,0,2.812,0V8.519A8.438,8.438,0,1,0,4.687,8.437v5.625H2.812A2.813,2.813,0,0,0,0,16.875V27.187A2.813,2.813,0,0,0,2.812,30H23.437a2.813,2.813,0,0,0,2.812-2.812V16.875A2.813,2.813,0,0,0,23.437,14.062Zm0,13.125H2.812V16.875H23.437Z" transform="translate(770.875 2572)" fill="currentColor"/></g></svg>`,

  download: `<svg xmlns="http://www.w3.org/2000/svg" width="15.429" height="18" viewBox="0 0 15.429 18"><path d="M14,40.04,8.056,46a.483.483,0,0,1-.683,0L1.426,40.04a.483.483,0,0,1,0-.683l.787-.788a.477.477,0,0,1,.687.008l3.765,3.9v-10A.484.484,0,0,1,7.148,32H8.273a.484.484,0,0,1,.482.482v10l3.765-3.9a.484.484,0,0,1,.687-.008l.788.788A.476.476,0,0,1,14,40.04Zm.944,7.871H.482A.484.484,0,0,0,0,48.393v1.125A.484.484,0,0,0,.482,50H14.946a.484.484,0,0,0,.482-.482V48.393A.484.484,0,0,0,14.946,47.911Z" transform="translate(0 -32)" fill="currentColor"/></svg>`,

  'creditcard-bg': `<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><g id="Group_9599" data-name="Group 9599" transform="translate(-759 -2562)"><circle id="Ellipse_1316" data-name="Ellipse 1316" cx="25" cy="25" r="25" transform="translate(759 2562)" fill="var(--background-color)"/><path id="credit-card" d="M27.495,32H2.505A2.5,2.5,0,0,0,0,34.5V52.833a2.5,2.5,0,0,0,2.505,2.5h24.99A2.5,2.5,0,0,0,30,52.833V34.5A2.5,2.5,0,0,0,27.495,32ZM2.818,34.5H27.182a.313.313,0,0,1,.313.313V37H2.505V34.813A.313.313,0,0,1,2.818,34.5ZM27.182,52.833H2.818a.313.313,0,0,1-.312-.312V43.667h24.99v8.854A.313.313,0,0,1,27.182,52.833ZM10,47.625v2.083a.627.627,0,0,1-.625.625H5.625A.627.627,0,0,1,5,49.708V47.625A.627.627,0,0,1,5.625,47h3.75A.627.627,0,0,1,10,47.625Zm10,0v2.083a.627.627,0,0,1-.625.625H12.292a.627.627,0,0,1-.625-.625V47.625A.627.627,0,0,1,12.292,47h7.083A.627.627,0,0,1,20,47.625Z" transform="translate(769 2543.333)" fill="currentColor"/></g></svg>`,

  'arrow-bottom': `<svg xmlns="http://www.w3.org/2000/svg" width="15.429" height="18" viewBox="0 0 15.429 18"><path d="M14,40.04,8.056,46a.483.483,0,0,1-.683,0L1.426,40.04a.483.483,0,0,1,0-.683l.787-.788a.477.477,0,0,1,.687.008l3.765,3.9v-10A.484.484,0,0,1,7.148,32H8.273a.484.484,0,0,1,.482.482v10l3.765-3.9a.484.484,0,0,1,.687-.008l.788.788A.476.476,0,0,1,14,40.04Zm.944,7.871H.482A.484.484,0,0,0,0,48.393v1.125A.484.484,0,0,0,.482,50H14.946a.484.484,0,0,0,.482-.482V48.393A.484.484,0,0,0,14.946,47.911Z" transform="translate(0 -32)" fill="#currentColor"/></svg>`,

  'globe-bg': `<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><g transform="translate(-468 -3937)"><circle id="Ellipse_1399" data-name="Ellipse 1399" cx="25" cy="25" r="25" transform="translate(468 3937)" fill="var(--background-color, #eaf6fc)"/><path id="globe" d="M15,8A15,15,0,1,0,30,23,15,15,0,0,0,15,8Zm10.845,9.677H21.78a20.156,20.156,0,0,0-1.887-5.728,12.126,12.126,0,0,1,5.952,5.728ZM15,10.9c1.125,0,2.94,2.492,3.823,6.774H11.177C12.06,13.4,13.875,10.9,15,10.9ZM2.9,23a12.247,12.247,0,0,1,.242-2.419h4.7c-.06.792-.1,1.591-.1,2.419s.042,1.627.1,2.419h-4.7A12.247,12.247,0,0,1,2.9,23Zm1.252,5.323H8.22a20.156,20.156,0,0,0,1.887,5.728,12.126,12.126,0,0,1-5.952-5.728ZM8.22,17.677H4.155a12.126,12.126,0,0,1,5.952-5.728A20.156,20.156,0,0,0,8.22,17.677ZM15,35.1c-1.125,0-2.94-2.492-3.823-6.774h7.651C17.94,32.6,16.125,35.1,15,35.1Zm4.24-9.677H10.76c-.067-.774-.115-1.573-.115-2.419s.048-1.645.115-2.419h8.486c.067.774.115,1.573.115,2.419S19.306,24.645,19.24,25.419Zm.653,8.631a20.347,20.347,0,0,0,1.887-5.728h4.065A12.126,12.126,0,0,1,19.893,34.05Zm2.262-8.631c.06-.792.1-1.591.1-2.419s-.042-1.627-.1-2.419h4.7a12.218,12.218,0,0,1,0,4.839Z" transform="translate(478 3939)" fill="#currentColor"/></g></svg>`,

  'location-pin-bg': `<svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44"><g transform="translate(-938 -3556)"><circle id="Ellipse_1383" data-name="Ellipse 1383" cx="22" cy="22" r="22" transform="translate(938 3556)" fill="var(--background-color, #eaf6fc)"/><path id="D_-_Icon_-_map-marker-alt" data-name="D - Icon - map-marker-alt" d="M9,0A9,9,0,0,0,0,9c0,3.334,1.125,4.375,7.091,13.942a2.251,2.251,0,0,0,3.818,0C16.879,13.37,18,12.333,18,9A9,9,0,0,0,9,0ZM9,21.75C3.027,12.173,2.25,11.568,2.25,9a6.75,6.75,0,1,1,13.5,0C15.75,11.557,15.039,12.067,9,21.75ZM5.25,9A3.75,3.75,0,1,1,9,12.75,3.75,3.75,0,0,1,5.25,9Z" transform="translate(951 3565.52)" fill="#currentColor"/></g></svg>`,

  search: `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><path fill="currentColor" data-name="D - Icon - search" d="M13.9,12.821,10.584,9.5a.325.325,0,0,0-.232-.1H9.991a5.686,5.686,0,1,0-.585.585v.361a.336.336,0,0,0,.1.232L12.821,13.9a.328.328,0,0,0,.465,0l.618-.618A.328.328,0,0,0,13.9,12.821ZM5.687,10.062a4.375,4.375,0,1,1,4.375-4.375A4.374,4.374,0,0,1,5.687,10.062Z" fill="#583d82"/></svg>`,

  'text-bubble': `<svg xmlns="http://www.w3.org/2000/svg" width="27" height="24" viewBox="0 0 27 24"><path fill="currentColor" d="M24,7.5H19.5V3a3,3,0,0,0-3-3H3A3,3,0,0,0,0,3v7.5a3,3,0,0,0,3,3H4.5v2.438a.561.561,0,0,0,.563.563.538.538,0,0,0,.333-.112L9,14.348V18a3,3,0,0,0,3,3h4.5l5.1,2.888a.569.569,0,0,0,.333.112.561.561,0,0,0,.563-.562V21H24a3,3,0,0,0,3-3V10.5A3,3,0,0,0,24,7.5ZM4.5,11.25H3a.752.752,0,0,1-.75-.75V3A.752.752,0,0,1,3,2.25H16.5a.752.752,0,0,1,.75.75v7.5a.752.752,0,0,1-.75.75H9.909l-.516.291-2.644,1.5V11.25ZM24.75,18a.752.752,0,0,1-.75.75H20.25v1.786l-2.644-1.5-.516-.291H12a.752.752,0,0,1-.75-.75V13.5H16.5a3,3,0,0,0,3-3V9.75H24a.752.752,0,0,1,.75.75Z" fill="#583d82"/></svg>`
}
