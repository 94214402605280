<template>
  <div data-test="up-swing-logo" class="up-swing-logo">
    <ImageWrapper
      :object-fit="objectFit || null"
      :src="upSwingIcon"
      class="up-swing-logo__image"
      height="auto"
      width="100%"
    />
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import ImageWrapper from '@base/components/ImageWrapper/ImageWrapper.vue'
import upSwingLogo from './assets/upswing-logo.svg'
import upSwingLogoDark from './assets/upswing-logo-dark.svg'
import upSwingLogoStacked from './assets/upswing-logo-stacked.svg'

export default defineComponent({
  name: 'UpSwingLogo',
  components: { ImageWrapper },
  props: {
    objectFit: { type: String, default: '' },
    mode: {
      type: String as PropType<'light' | 'dark' | 'light-stacked'>,
      default: 'light'
    }
  },
  setup(props) {
    const upSwingIcon = computed(() => {
      let icon = upSwingLogo

      switch (props.mode) {
        case 'light':
          icon = upSwingLogo
          break
        case 'dark':
          icon = upSwingLogoDark
          break
        case 'light-stacked':
          icon = upSwingLogoStacked
          break
      }

      return icon
    })

    return { upSwingIcon }
  }
})
</script>

<style lang="scss" scoped></style>
