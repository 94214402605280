import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core'
import { defineStore } from 'pinia'

import { Role, Gender } from '__generated__/globalTypes'
import { getUser } from '__generated__/getUser'
import { GET_USER } from '@base/api/queries/getUser'
import { ugcImageSrc } from '@base/lib/ugcImageSrc'

type Club = {
  id: string
  slug: string
  clubName: string
  logo: string
}

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    id: '',
    username: '',
    email: '',
    createdAt: undefined as Date | undefined,
    role: undefined as Role | undefined,
    firstName: '',
    lastName: '',
    verified: false,
    profilePicture: '',
    profilePictureSrcset: '',
    phoneNum: '',
    dob: undefined as Date | undefined,
    gender: undefined as Gender | undefined,
    addressLine1: '',
    addressLine2: '',
    city: '',
    province: '',
    postalCode: '',
    country: '',
    addressGeom: undefined as { lat: number; lon: number } | undefined,
    golfMembershipId: '',
    bio: '',
    emailNotifications: false,
    clubs: [] as string[],
    clubsData: [] as Club[],
    membership: {
      active: false,
      currentPeriodStart: undefined as Date | undefined,
      currentPeriodEnd: undefined as Date | undefined
    },
    pointsBalance: 0
  }),
  getters: {
    isAuth: (state) => !!state.id,
    // Typed because it can't be missing
    isAdmin(state): boolean {
      return this.isAuth && state.role === Role.ADMIN
    },
    isOperator(state): boolean {
      return this.isAuth && state.role === Role.OPERATOR
    },
    isAdminOrOperator(): boolean {
      return this.isAdmin || this.isOperator
    },
    isPlayer(state): boolean {
      return this.isAuth && state.role === Role.PLAYER
    },
    fullName: (state) => `${state.firstName} ${state.lastName}`,
    initials: (state) => `${state.firstName[0]}${state.lastName[0]}`,
    isPremium: (state) => {
      const { active, currentPeriodEnd } = state.membership
      return active && currentPeriodEnd && new Date() <= currentPeriodEnd
    }
  },
  actions: {
    async fetchUser(client: ApolloClient<NormalizedCacheObject>) {
      try {
        const { data } = await client.query<getUser>({
          query: GET_USER,
          fetchPolicy: 'no-cache'
        })
        const { getUser } = data

        if (
          getUser.__typename === 'ServerError' ||
          getUser.__typename === 'UnauthorizedError'
        ) {
          console.error(
            'Something went wrong fetching user:',
            getUser.__typename
          )
          return this.$reset()
        }

        if (getUser.__typename === 'UnauthenticatedError' || !getUser.user) {
          return this.$reset()
        }

        const { user } = getUser

        this.id = user.id
        this.username = user.username
        this.email = user.email
        this.createdAt = new Date(user.createdAt) || undefined
        this.role = user.role
        this.firstName = user.firstName
        this.lastName = user.lastName
        this.verified = user.verified
        this.phoneNum = user.phoneNum || ''
        this.dob = new Date(user.dob) || undefined
        this.gender = user.gender || undefined
        this.addressLine1 = user.addressLine1 || ''
        this.addressLine2 = user.addressLine2 || ''
        this.city = user.city || ''
        this.province = user.province || ''
        this.postalCode = user.postalCode || ''
        this.country = user.country || ''
        this.addressGeom = user.addressGeom || undefined
        this.golfMembershipId = user.golfMembershipId || ''
        this.bio = user.bio || ''
        this.emailNotifications = user.emailNotifications || false
        this.pointsBalance = user.pointsBalance || 0
        if (user.clubs.__typename === 'ListClubsOutput' && user.clubs.clubs) {
          const mappedClubs = user.clubs.clubs.map((club) => club.id)
          this.clubs = mappedClubs

          const mappedClubData: Club[] = user.clubs.clubs.map((club) => ({
            id: club.id,
            slug: club.slug,
            clubName: club.clubName,
            logo: club.logo || ''
          }))
          this.clubsData = mappedClubData
        }

        const [src, srcset] = ugcImageSrc(user.profilePicture || '')
        this.profilePicture = src
        this.profilePictureSrcset = srcset

        const { membership } = user
        if (membership) {
          const { active, currentPeriodStart, currentPeriodEnd } = membership

          const currentPeriodStartDate =
            active && currentPeriodStart
              ? new Date(currentPeriodStart * 1000)
              : undefined
          const currentPeriodEndDate =
            active && currentPeriodEnd
              ? new Date(currentPeriodEnd * 1000)
              : undefined

          this.membership = {
            active,
            currentPeriodEnd: currentPeriodEndDate,
            currentPeriodStart: currentPeriodStartDate
          }
        }
      } catch (e) {
        this.$reset()
        console.error(e)
      }
    }
  }
})
