var Lazyload = /** @class */ (function () {
    function Lazyload(elements, type, loadedClass) {
        if (type === void 0) { type = 'image'; }
        if (loadedClass === void 0) { loadedClass = 'loaded'; }
        this.elements = elements;
        this.type = type;
        this.loadedClass = loadedClass;
        this._observeCb = null;
        this._elementsObserver = null;
        this._initialized = false;
    }
    Lazyload.prototype.init = function () {
        var _this = this;
        if (!this.elements)
            return false;
        switch (this.type) {
            case 'video':
                this._observeCb = function (entries, observer) {
                    entries.forEach(function (entry) {
                        if (entry.isIntersecting) {
                            var sources = entry.target.querySelectorAll('source');
                            sources.forEach(function (source) {
                                if (source.dataset.src)
                                    source.src = source.dataset.src;
                            });
                            entry.target.load();
                            entry.target.classList.add(_this.loadedClass);
                            observer.unobserve(entry.target);
                        }
                    });
                };
                break;
            case 'image':
            default:
                this._observeCb = function (entries, observer) {
                    entries.forEach(function (entry) {
                        if (entry.isIntersecting) {
                            if (entry.target.dataset.src)
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                entry.target.src = entry.target.dataset.src;
                            if (entry.target.dataset.srcset)
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                entry.target.srcset = entry.target.dataset.srcset;
                            if (entry.target.dataset.sizes)
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                entry.target.sizes = entry.target.dataset.sizes;
                            entry.target.classList.add(_this.loadedClass);
                            observer.unobserve(entry.target);
                        }
                    });
                };
                break;
        }
        if ('IntersectionObserver' in window) {
            this._elementsObserver = new IntersectionObserver(this._observeCb);
            this.elements.forEach(function (element) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                _this._elementsObserver.observe(element);
            });
            this._initialized = true;
            return true;
        }
        return false;
    };
    Lazyload.prototype.reset = function (newElements) {
        var _this = this;
        if (!this._initialized || !this.elements || !this._elementsObserver)
            return;
        this.elements.forEach(function (element) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            _this._elementsObserver.unobserve(element);
        });
        this.elements = newElements;
        this.elements.forEach(function (element) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            _this._elementsObserver.observe(element);
        });
    };
    Lazyload.prototype.destory = function () {
        var _this = this;
        if ('IntersectionObserver' in window &&
            this._elementsObserver !== null &&
            this.elements) {
            this.elements.forEach(function (element) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                _this._elementsObserver.unobserve(element);
            });
        }
    };
    return Lazyload;
}());
export { Lazyload };
