import { RouteRecordRaw } from 'vue-router'
import { coursesRouteNames } from './routeNames'

export const coursesRoutes: Array<RouteRecordRaw> = [
  {
    name: coursesRouteNames.Courses,
    path: '/courses/:page?',
    component: () => import('../Courses'),
    props: (route) => {
      const handleNumber = (val: string | undefined, float = false) => {
        if (!val) return undefined
        if (float) {
          const floatVal = parseFloat(val)
          if (!floatVal) return undefined
          return floatVal
        } else {
          const intVal = parseInt(val)
          if (!intVal) return undefined
          return intVal
        }
      }

      const pageNumber = route.params.page
        ? handleNumber(route.params.page.toString())
        : 1

      return {
        sort: route.query.sort?.toString(),
        page: pageNumber,
        start: route.query.start?.toString(),
        end: route.query.end?.toString(),
        holes: handleNumber(route.query.holes?.toString()),
        players: handleNumber(route.query.players?.toString()),
        lat: handleNumber(route.query.lat?.toString(), true),
        lon: handleNumber(route.query.lon?.toString(), true),
        loc: route.query.loc?.toString(),
        query: route.query.query?.toString(),
        price: route.query.price?.toString(),
        clubs: route.query.clubs?.toString(),
        amenities: route.query.amenities?.toString()
      }
    }
  },
  {
    name: coursesRouteNames.CourseSingle,
    path: '/course/:slug',
    component: () => import('../CourseSingle'),
    props: true
  },
  {
    name: coursesRouteNames.HoleSingle,
    path: '/course/:slug/hole/:holeIndex',
    component: () => import('../HoleSingle'),
    props: true
  }
]
