<template>
  <PlayerView />
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useHead } from '@vueuse/head'
import { useRoute } from 'vue-router'

import { faviconHead } from '@base/lib/globalHead'
import { playerTitle } from './_lib/playerTitle'
import PlayerView from './_views/PlayerView.vue'

import imageUpSwingOpenGraph from '@player/_assets/upswing-open-graph.jpg'

// Required for v-calendar v3.0.0-alpha.7+ https://vcalendar.io/vue-3.html
import 'v-calendar/dist/style.css'

export default defineComponent({
  name: 'App',
  components: { PlayerView },
  setup() {
    const route = ref(useRoute())

    const domain = import.meta.env.VITE_DOMAIN || `https://upswinggolf.ca`

    const url = computed(() => {
      return `${domain}${route.value.fullPath}`
    })

    const ogImageUrl = computed(() => {
      return `${domain}${imageUpSwingOpenGraph}`
    })

    const metaDescription = `Discover, book, and enjoy Alberta’s best social golf experiences. Explore amazing playing opportunities and connect with players from across the province.`

    useHead({
      ...faviconHead,
      title: playerTitle(),
      meta: [
        {
          name: 'description',
          content: metaDescription
        },
        {
          name: 'maximum-scale',
          content: 1
        },
        {
          property: 'og:title',
          content: playerTitle()
        },
        {
          property: 'og:description',
          content: metaDescription
        },
        {
          property: 'og:site_name',
          content: 'UpSwing Golf'
        },
        {
          property: 'og:url',
          content: url
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:locale',
          content: 'en_CA'
        },
        {
          property: 'og:image',
          content: ogImageUrl
        },
        {
          property: 'og:image:alt',
          content: 'UpSwing Golf'
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          name: 'twitter:site',
          content: '@UpSwing_Golf'
        },
        {
          name: 'twitter:title',
          content: playerTitle()
        },
        {
          name: 'twitter:description',
          content: metaDescription
        },
        {
          name: 'twitter:image',
          content: ogImageUrl
        },
        {
          name: 'twitter:image:alt',
          content: 'UpSwing Golf'
        }
      ]
    })
  }
})
</script>

<style lang="scss">
@use '@base/styles/main';
</style>
