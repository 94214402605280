export const accountRouteNames = {
  Bookings: 'account/bookings',
  Events: 'account/events',
  PurchaseSingle: 'account/purchase-single',
  SavedPayments: 'account/saved-payments',
  Purchases: 'account/purchases',
  Membership: 'account/membership',
  ManageReviews: 'account/manage-reviews',
  ManageNotifications: 'account/manage-notifications',
  EditProfile: 'account/edit-profile',
  ChangePassword: 'account/change-password',
  Profile: 'account/profile',
  Account: 'account/account'
}
