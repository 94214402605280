var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { checkboxHandler } from './lib/checkboxHandler';
import { open, close, toggle } from './lib/toggleLogic';
import { doKeyLogic } from './lib/uiNavLogic';
var Select = /** @class */ (function () {
    function Select(select, selectedSelector, optionsContainerSelector, optionSelector, multiSelect, valueLabelDict, defaultSelectText, maxDropdownHeight, direction) {
        var _this = this;
        if (selectedSelector === void 0) { selectedSelector = '.select__selected'; }
        if (optionsContainerSelector === void 0) { optionsContainerSelector = '.select__options-container'; }
        if (optionSelector === void 0) { optionSelector = '.select__option'; }
        if (multiSelect === void 0) { multiSelect = false; }
        if (defaultSelectText === void 0) { defaultSelectText = 'Select'; }
        if (maxDropdownHeight === void 0) { maxDropdownHeight = 500; }
        if (direction === void 0) { direction = 'bottom'; }
        this.direction = direction;
        this.selectEl = select;
        this.selectedEl = select.querySelector(selectedSelector);
        this.selectedEl = select.querySelector(selectedSelector);
        this.optionsContainerEl = select.querySelector(optionsContainerSelector);
        this.optionEls = select.querySelectorAll(optionSelector);
        this.multiselect = multiSelect;
        this.maxDropdownHeight = maxDropdownHeight;
        this.defaultSelectText = defaultSelectText;
        this.eventCbs = {};
        this.currentValues = [];
        this.valueLabelDict = valueLabelDict;
        this.isOpen = false;
        this.onWindowMouseDown = function (e) {
            if (e.target != _this.selectEl &&
                !_this.selectEl.contains(e.target) &&
                _this.isOpen) {
                _this.close();
            }
        };
        var that = this;
        this.onCheckboxValueChange = function (ev) {
            checkboxHandler(that, ev, this);
        };
        this.onKeyDown = function (event) {
            doKeyLogic(_this, event);
        };
    }
    /**
     * Add event listeners toggle, and to listen to checkbox inputs
     */
    Select.prototype.init = function () {
        var _this = this;
        var _a;
        this.optionEls.forEach(function (optEl) {
            // Make label focusabled
            optEl.setAttribute('tabindex', '-1');
            var checkbox = optEl.querySelector('input[type="checkbox"]');
            checkbox === null || checkbox === void 0 ? void 0 : checkbox.addEventListener('click', _this.onCheckboxValueChange);
        });
        this.selectEl.setAttribute('tabindex', '0');
        this.selectEl.addEventListener('keydown', this.onKeyDown);
        (_a = this.selectedEl) === null || _a === void 0 ? void 0 : _a.addEventListener('click', this.toggle.bind(this));
        window.addEventListener('mousedown', this.onWindowMouseDown);
    };
    /**
     * Update text of "selected" heading
     */
    Select.prototype.updatedSelectedText = function () {
        var _this = this;
        var displayValues = [];
        this.currentValues.forEach(function (val) {
            var opt = _this.valueLabelDict.find(function (opt) { return opt.value == val; });
            if (opt) {
                displayValues.push(opt.label);
            }
        });
        if (this.selectedEl && this.currentValues.length) {
            this.selectedEl.innerHTML = displayValues.join(', ');
        }
        else if (this.selectedEl) {
            this.selectedEl.innerHTML = this.defaultSelectText;
        }
    };
    Select.prototype.setValuesManually = function (values) {
        var _this = this;
        if (!this.optionEls)
            return;
        this.optionEls.forEach(function (optEl) {
            var dataValue = optEl.getAttribute('data-select-value') || '';
            var checkboxInput = optEl.querySelector('input[type="checkbox"]');
            if (values.includes(dataValue)) {
                checkboxInput.checked = true;
            }
            else {
                checkboxInput.checked = false;
            }
            checkboxHandler(_this, null, checkboxInput);
        });
    };
    Select.prototype.toggle = function () {
        toggle(this, this.direction);
    };
    Select.prototype.open = function () {
        open(this, this.direction);
    };
    Select.prototype.close = function () {
        close(this);
    };
    Select.prototype.destroy = function () {
        var _this = this;
        this.optionEls.forEach(function (optEl) {
            var checkbox = optEl.querySelector('input[type="checkbox"]');
            checkbox === null || checkbox === void 0 ? void 0 : checkbox.removeEventListener('click', _this.onCheckboxValueChange);
        });
        window.removeEventListener('mousedown', this.onWindowMouseDown);
        this.selectEl.removeEventListener('keyup', this.onKeyDown);
    };
    Select.prototype.getValues = function () {
        return __spreadArray([], this.currentValues);
    };
    Select.prototype.on = function (event, cb) {
        this.eventCbs[event] = cb;
    };
    return Select;
}());
export { Select };
