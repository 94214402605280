<template>
  <header data-test="player-header" class="player-header">
    <div class="grid__row">
      <div class="player-header__container grid__column grid__column--12">
        <div
          class="player-header__menu-button-wrapper"
          @click="$emit('toggleMobileMenu')"
        >
          <button class="player-header__menu-button">
            <span class="player-header__menu-icon" />
          </button>
        </div>
        <div class="player-header__mobile">
          <router-link v-if="headerLogoLink" :to="'/'">
            <UpSwingLogo class="player-header__logo" />
          </router-link>
          <UpSwingLogo v-else class="player-header__logo" />
          <nav class="player-header__nav">
            <router-link
              v-for="(link, idx) in links"
              :key="idx"
              :to="link.to"
              class="player-header__nav-link"
            >
              {{ link.label }}
              <USIcon
                :diameter="10"
                name="upswing-bird"
                class="player-header__nav-upswingbird"
                color="var(--icon-color-upswingbird)"
              />
            </router-link>
          </nav>
        </div>
        <div v-if="!isAuth" class="player-header__button">
          <router-link :to="{ name: usersRouteNames.SignIn }">
            <USButton label="Log In" type="inline" />
          </router-link>
          <router-link
            :to="{ name: usersRouteNames.Register }"
            class="player-header__sign-up"
          >
            <USButton label="Create Account" />
          </router-link>
        </div>

        <HeaderUserAvatar
          v-else
          class="player-header__header-user-avatar"
          dropdown-id="player-header__header-user-avatar-dropdown-menu"
          :dropdown-menu-items="[
            {
              label: 'Profile',
              to: { name: accountRouteNames.Profile }
            },
            {
              label: 'My Account',
              to: { name: accountRouteNames.Account }
            },
            {
              label: 'Leaderboard',
              to: {
                name: leaderboardRouteNames.Leaderboard
              }
            }
          ]"
          :user="user"
        />
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { RouteLocationRaw } from 'vue-router'

import HeaderUserAvatar from '@base/components/HeaderUserAvatar/HeaderUserAvatar.vue'
import UpSwingLogo from '@base/components/UpSwingLogo/UpSwingLogo.vue'
import USButton from '@base/components/USButton/USButton.vue'
import USIcon from '@base/components/USIcon/USIcon.vue'

import { accountRouteNames } from '@player/account/router/routeNames'
import { usersRouteNames } from '@player/users/router/routeNames'
import { leaderboardRouteNames } from '@player/leaderboard/router/routeNames'

interface Link {
  label: string
  to: RouteLocationRaw
}

interface User {
  firstName: string
  lastName: string
  profilePicture?: string
  profilePictureSrcset?: string
  activeMembership?: boolean
  pointsBalance?: number
}

export default defineComponent({
  name: 'PlayerHeader',
  components: {
    HeaderUserAvatar,
    UpSwingLogo,
    USButton,
    USIcon
  },
  props: {
    links: { type: Array as PropType<Array<Link>>, required: true },
    user: { type: Object as PropType<User>, required: true },
    headerLogoLink: { type: Boolean, default: true },
    isAuth: { type: Boolean, default: false }
  },
  emits: ['toggleMobileMenu'],
  setup() {
    return { accountRouteNames, usersRouteNames, leaderboardRouteNames }
  }
})
</script>

<style lang="scss">
@use '@base/styles/v1.0/scss/foundations/breakpoint' as bp;

:root {
  --player-header-height: 65px;

  @media (min-width: bp.$breakpoint--tablet) {
    --player-header-height: 90px;
  }

  @media (min-width: bp.$breakpoint--desktop) {
    --player-header-height: 110px;
  }
}
</style>

<style lang="scss" scoped>
@use '@base/styles/v1.0/scss/foundations/breakpoint' as bp;

$player-button-height-sm: 44px;
$player-button-width-sm: 44px;
$player-button-height-lg: 60px;
// $player-button-width-lg: 60px;
// $player-logo-size: 123px;
$player-menu-button-size: 20px;
$player-menu-button-block-height: 3px;
$player-menu-button-block-starting-width: 60%;
$player-upswing-bird-width: 10px;

.player-header {
  background-color: var(--color--neutral-a5);
  height: var(--player-header-height);
  width: 100%;

  &__container {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    justify-content: space-between;
    max-width: bp.$breakpoint--max;

    @media (min-width: bp.$breakpoint--tablet) {
      max-width: bp.$breakpoint--max;
    }
  }

  &__menu-button-wrapper {
    margin-right: calc(var(--spacing--5) / 2);
    padding-right: calc(var(--spacing--5) / 2);
  }

  &__menu-button {
    cursor: pointer;
    height: $player-menu-button-size;
    width: $player-menu-button-size;

    @media (min-width: bp.$breakpoint--desktop) {
      display: none;
    }

    &:hover {
      &:before,
      &:after {
        transition-timing-function: var(
          --animation-curve-exit
        ); // TODO update motion
        width: 100%;
      }
    }

    &:before,
    &:after {
      background-color: var(--color--primary-b1);
      content: '';
      display: block;
      height: $player-menu-button-block-height;
      transition: width var(--animation-speed-medium)
        var(--animation-curve-enter); // TODO update motion
      width: $player-menu-button-block-starting-width;
      will-change: width;
    }

    &:before {
      position: absolute;
      top: 0;
    }

    &:after {
      bottom: 0;
      right: 0;
      position: absolute;
    }
  }

  &__menu-icon {
    background-color: var(--color--primary-b1);
    display: block;
    height: $player-menu-button-block-height;
    width: 100%;
  }

  &__mobile {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;

    // @media (min-width: bp.$breakpoint--tablet) {
    //   align-items: center;
    //   display: flex;
    //   flex-flow: row nowrap;
    // }
  }

  &__logo {
    // min-width: $player-logo-size;
    // min-width: 225px;
    width: 80px;

    @media (min-width: bp.$breakpoint--tablet) {
      width: 130px;
    }

    @media (min-width: bp.$breakpoint--desktop) {
      width: 135px;
    }
  }

  &__nav {
    display: none;

    @media (min-width: bp.$breakpoint--desktop) {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      width: 100%;
      margin-left: var(--spacing--4);
    }
  }

  &__nav-link {
    color: var(--color--primary-b1);
    cursor: pointer;
    display: flex;
    flex-flow: column nowrap;
    font-size: 1rem;
    font-weight: var(--font-weight-medium);
    margin-right: var(--spacing--5);

    &:last-child {
      margin-right: 0;
    }

    @media (min-width: bp.$breakpoint--tablet) {
      align-items: center;
    }

    @media (min-width: 1024px) {
      margin-right: 58px;
    }

    &:hover .player-header__nav-upswingbird {
      left: 50%;
      opacity: 1;
    }
  }

  &__nav-upswingbird {
    --icon-color-upswingbird: var(--color--secondary-c1);
    left: calc(50% - $player-upswing-bird-width);
    opacity: 0;
    padding-top: var(--spacing--9);
    position: absolute;
    top: 100%;
    transform: translateX(-50%);
    transition-duration: 0.3s; // TODO update motion
    transition-property: opacity, left;
    transition-timing-function: ease-in-out;
    width: $player-upswing-bird-width;
  }

  &__button {
    align-items: center;
    display: flex;
    margin-left: auto;
  }

  &__sign-up {
    margin-left: var(--spacing--6);
  }

  &__header-user-avatar {
    margin-left: auto;
  }
}

.router-link-active .player-header__nav-upswingbird {
  --icon-color-upswingbird: var(--color--primary-b1);
  left: 50%;
  opacity: 1;
  right: 0;
}
</style>
