<template>
  <router-link
    v-for="(link, idx) in menuItems"
    :key="idx"
    data-test="menu-items-with-icon"
    :to="link.to"
    class="menu-items-with-icon__link font-size--d2"
    @click="$emit('navigate')"
  >
    <USIcon
      v-if="link && link.icon"
      :name="link.icon"
      :diameter="20"
      color="var(--color--primary-b1)"
      class="menu-items-with-icon__link-icon"
    />
    <!-- <USIcon
      :diameter="10"
      name="upswing-bird"
      class="menu-items-with-icon__upswing-bird"
      color="var(--icon-color-upswing-bird)"
    /> -->
    <span class="menu-items-with-icon__label">
      {{ link.label }}
    </span>
  </router-link>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { RouteLocationRaw } from 'vue-router'

import USIcon from '@base/components/USIcon/USIcon.vue'

interface Action {
  label: string
  to: RouteLocationRaw
  icon?: string
}

export default defineComponent({
  name: 'MenuItemsWithIcon',
  components: { USIcon },
  props: {
    menuItems: {
      type: Array as PropType<Array<Action>>,
      default: () => []
    }
  },
  emits: ['navigate']
})
</script>

<style lang="scss" scoped>
.menu-items-with-icon {
  &__link {
    align-items: center;
    border-radius: var(--border-radius--small);
    color: var(--color--primary-b2);
    cursor: pointer;
    display: flex;
    flex-flow: row;
    padding: var(--spacing--8);
    transition: var(--animation-speed-medium); // TODO update motion
    width: fit-content;

    &:not(:last-child) {
      margin-bottom: var(--spacing--6);
    }

    &:first-child {
      margin-bottom: var(--spacing--7);
    }

    &:hover {
      background-color: var(--color--secondary-c2);
      // TODO add motion
    }

    &-icon {
      margin-right: var(--spacing--6);
    }
  }
}
</style>
