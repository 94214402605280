<template>
  <div data-test="club-header-avatar" class="club-header-avatar">
    <USAvatar
      :src="profilePicture || ''"
      :alt="club"
      sizes="var(--iconDiameter)"
      class="club-header-avatar__avatar"
    />
    <span class="club-header-avatar__club-name">
      {{ club }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import USAvatar from '@base/components/USAvatar/USAvatar.vue'

export default defineComponent({
  name: 'ClubHeaderAvatar',
  components: { USAvatar },
  props: {
    club: {
      type: String,
      required: true
    },
    profilePicture: {
      type: String,
      required: true
    }
  }
})
</script>

<style lang="scss" scoped>
@use '@base/styles/v1.0/scss/foundations/breakpoint' as bp;

.club-header-avatar {
  --iconDiameter: 40px;
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  padding-top: var(--spacing--5);

  @include bp.min-width(bp.$breakpoint--9) {
    --iconDiameter: 40px;
  }

  &__club-name {
    color: var(--color--primary-b2);
    display: block;
  }

  &__avatar {
    margin-right: var(--spacing--7);
  }
}
</style>
