import { RouteRecordRaw } from 'vue-router'
import { staticRouteNames } from './routeNames'

export const staticRoutes: Array<RouteRecordRaw> = [
  {
    name: staticRouteNames.Home,
    path: '/',
    component: () => import('../Home')
  },
  {
    name: staticRouteNames.About,
    path: '/about',
    component: () => import('../About')
  },
  {
    name: staticRouteNames.OperatorsLanding,
    path: '/operators',
    component: () => import('../OperatorsLanding')
  },
  {
    name: staticRouteNames.CancellationPolicy,
    path: '/cancellation-policy',
    component: () => import('../CancellationPolicy')
  },
  {
    name: staticRouteNames.Privacy,
    path: '/privacy',
    component: () => import('../Privacy')
  },
  {
    name: staticRouteNames.Terms,
    path: '/terms',
    component: () => import('../Terms')
  },
  {
    name: staticRouteNames.NotFound,
    path: '/:pathMatch(.*)*',
    component: () => import('../NotFound'),
    meta: {
      is404: true
    }
  }
]
