import { RouteRecordRaw } from 'vue-router'

import { communityRouteNames } from './routeNames'

export const communityRoutes: RouteRecordRaw[] = [
  {
    name: communityRouteNames.CommunityBoard,
    path: '/community/:page?',
    component: () => import('../CommunityBoard'),
    props: (route) => {
      const handleNumber = (val: string | undefined, float = false) => {
        if (!val) return undefined
        if (float) {
          const floatVal = parseFloat(val)
          if (!floatVal) return undefined
          return floatVal
        } else {
          const intVal = parseInt(val)
          if (!intVal) return undefined
          return intVal
        }
      }

      const pageNumber = route.params.page
        ? handleNumber(route.params.page.toString())
        : 1
      const tags = route.query.tags
        ? route.query.tags.toString().split(',')
        : []

      return {
        page: pageNumber,
        tags
      }
    }
  }
]
