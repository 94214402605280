import { gql } from '@apollo/client/core'

export const UNAUTHENTICATED_ERROR = gql`
  fragment UnauthenticatedError on UnauthenticatedError {
    unauthenticated
  }
`

export const UNAUTHORIZED_ERROR = gql`
  fragment UnauthorizedError on UnauthorizedError {
    unauthorized
  }
`

export const SERVER_ERROR = gql`
  fragment ServerError on ServerError {
    serverError
  }
`
