import { gql } from '@apollo/client/core'

export const USER_FRAGMENT = gql`
  fragment User on User {
    id
    username
    email
    createdAt
    role
    firstName
    lastName
    verified
    profilePicture
    phoneNum
    dob
    gender
    addressLine1
    addressLine2
    city
    province
    postalCode
    country
    addressGeom {
      lat
      lon
    }
    golfMembershipId
    bio
    emailNotifications
    clubs {
      ... on ListClubsOutput {
        clubs {
          id
          slug
          clubName
          logo
        }
      }
    }
    membership {
      active
      currentPeriodStart
      currentPeriodEnd
    }
    stripeCustomer
    pointsBalance
  }
`
