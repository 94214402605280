/* eslint-disable @typescript-eslint/no-explicit-any */
import VCalendar from 'v-calendar'

import { createApp } from './app'

declare global {
  interface Window {
    __PINIA_STATE__: any
    __GQL_STATE__: any
  }
}

const { app, router, pinia, client } = createApp()
app.use(VCalendar)

// Hydrate
if (window.__PINIA_STATE__) {
  pinia.state.value = window.__PINIA_STATE__
}
if (window.__GQL_STATE__) {
  client.cache.restore(window.__GQL_STATE__)
}

// wait until router is ready before mounting to ensure hydration match
router.isReady().then(() => {
  app.mount('#app')
})
