import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export const redirectToNonTrailingSlash = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (to.path !== '/' && to.path[to.path.length - 1] === '/') {
    const slicedPath = to.path.slice(0, to.path.length - 1)
    next({
      path: slicedPath
    })
  } else {
    next()
  }
}
