import { RouteRecordRaw } from 'vue-router'

import { accountRouteNames } from './routeNames'
import { usersRouteNames } from '@player/users/router/routeNames'

const accountRouteMeta = {
  requiresAuth: {
    auth: true,
    authRedirect: {
      name: usersRouteNames.SignIn
    }
  }
}

export const accountRoutes: RouteRecordRaw[] = [
  {
    name: accountRouteNames.Account,
    path: '/account',
    component: () => import('../Account'),
    meta: { ...accountRouteMeta }
  },
  {
    name: accountRouteNames.ChangePassword,
    path: '/account/password',
    component: () => import('../ChangePassword'),
    meta: { ...accountRouteMeta }
  },
  {
    name: accountRouteNames.EditProfile,
    path: '/account/edit-profile',
    component: () => import('../EditProfile'),
    meta: { ...accountRouteMeta }
  },
  {
    name: accountRouteNames.ManageNotifications,
    path: '/account/notifications',
    component: () => import('../ManageNotifications'),
    meta: { ...accountRouteMeta }
  },
  {
    name: accountRouteNames.ManageReviews,
    path: '/account/reviews',
    component: () => import('../ManageReviews'),
    meta: { ...accountRouteMeta }
  },
  {
    name: accountRouteNames.Membership,
    path: '/account/membership',
    component: () => import('../Membership'),
    meta: { ...accountRouteMeta }
  },
  {
    name: accountRouteNames.Bookings,
    path: '/account/bookings',
    component: () => import('../Bookings'),
    props: (route) => {
      const handleNumber = (val: string | undefined, float = false) => {
        if (!val) return undefined
        if (float) {
          const floatVal = parseFloat(val)
          if (!floatVal) return undefined
          return floatVal
        } else {
          const intVal = parseInt(val)
          if (!intVal) return undefined
          return intVal
        }
      }

      return {
        name: route.query.name?.toString(),
        sort: route.query.sort?.toString(),
        page: handleNumber(route.query.page?.toString())
      }
    },
    meta: { ...accountRouteMeta }
  },
  {
    name: accountRouteNames.Events,
    path: '/account/events',
    component: () => import('../Events'),
    meta: { ...accountRouteMeta }
  },
  {
    name: accountRouteNames.Purchases,
    path: '/account/purchases',
    component: () => import('../Purchases'),
    meta: { ...accountRouteMeta }
  },
  {
    name: accountRouteNames.PurchaseSingle,
    path: '/account/purchases/:purchaseId',
    component: () => import('../PurchaseSingle'),
    props: (route) => ({
      purchaseId: route.params.purchaseId
    }),
    meta: { ...accountRouteMeta }
  },
  {
    name: accountRouteNames.SavedPayments,
    path: '/account/saved-payments',
    component: () => import('../SavedPayments'),
    meta: { ...accountRouteMeta }
  },
  {
    name: accountRouteNames.Profile,
    path: '/profile',
    component: () => import('../Profile'),
    meta: { ...accountRouteMeta }
  }
]
