<template>
  <div data-test="us-rating-stars">
    <div v-if="heading" class="us-rating-stars__heading display--d2--medium">
      <component :is="`h${headingLevel}`">
        {{ heading }}
      </component>
      <slot name="heading" />
    </div>
    <div
      v-if="description"
      class="us-rating-stars__description body--p1--regular"
    >
      <p>
        {{ description }}
      </p>
      <slot name="description" />
    </div>
    <div
      :class="['us-rating-stars', error ? 'us-rating-stars--error' : '']"
      @mouseleave="!readonly ? resetStars() : null"
    >
      <USIcon
        v-for="item of stars"
        :key="item"
        name="star"
        :class="[
          'us-rating-stars__star',
          !readonly
            ? 'us-rating-stars__star--edit'
            : disabled
            ? 'us-rating-stars__star--disabled'
            : error
            ? 'us-rating-stars__star--error'
            : ''
        ]"
        color="var(--color--secondary-c1)"
        :fill="
          item <= filledRef
            ? 'var(--color--secondary-c1)'
            : 'var(--color--neutral-a5)'
        "
        css-diameter="var(--iconDiameter)"
        @click="!readonly ? updateStars(item) : null"
        @mouseover="!readonly ? updateHoverFill(item) : null"
      />
    </div>
    <div v-if="error" class="error body--p2--regular">
      {{ error }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import USIcon from '@base/components/USIcon/USIcon.vue'

export default defineComponent({
  name: 'USRatingStars',
  components: { USIcon },
  props: {
    stars: { type: Number, required: true },
    filled: { type: Number, required: true },
    readonly: { type: Boolean, default: true },
    error: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    heading: {
      type: String,
      default: ''
    },
    headingLevel: {
      type: Number as PropType<1 | 2 | 3 | 4 | 5 | 6>,
      default: 1
    },
    description: {
      type: String,
      default: ''
    },
    cssDiameter: { type: String, default: '10px' }
  },
  emits: ['updateRating'],
  setup(props, { emit }) {
    const filledRef = ref(props.filled)
    const hoverFill = ref(props.filled)

    const updateStars = (star: number) => {
      filledRef.value = star
      hoverFill.value = star
      emit('updateRating', star)
    }

    const updateHoverFill = (star: number) => {
      filledRef.value = star
    }

    const resetStars = () => {
      filledRef.value = hoverFill.value
    }

    return {
      updateStars,
      resetStars,
      filledRef,
      updateHoverFill
    }
  }
})
</script>

<style lang="scss" scoped>
@use '@base/styles/v1.0/scss/foundations/breakpoint';

// TODO add motion

.us-rating-stars {
  --iconDiameter: v-bind("cssDiameter ? cssDiameter : '40px'");
  display: flex;
  flex-flow: row;
  width: fit-content;

  &__heading {
    margin-bottom: var(--spacing--9);
  }

  &__description {
    margin-bottom: var(--spacing--8);
  }

  &__star {
    &:not(:first-child) {
      margin-left: var(--spacing--8);
    }

    &--edit {
      cursor: pointer;
    }

    &--error {
      color: var(--color--semantic--error-2) !important;
      :deep(.cls-1) {
        fill: var(--color--neutral-a5) !important;
      }
    }

    &--disabled {
      color: var(--color--neutral-a4) !important;

      :deep(.cls-1) {
        fill: var(--color--neutral-a4) !important;
      }

      &:hover,
      &:focus {
        cursor: not-allowed;
      }
    }
  }
}

.error {
  color: var(--color--semantic--error-2);
  padding-top: var(--spacing--9);
}
</style>
