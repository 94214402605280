const universalBtoa = (str: string) => {
  try {
    return btoa(str)
  } catch (err) {
    return Buffer.from(str).toString('base64')
  }
}

export function ugcImageSrc(
  key: string,
  sizes = [150, 300, 500, 1200]
): [string, string] {
  if (!key) return ['', '']

  const bucket = import.meta.env.VITE_UGC_BUCKET
  const endpoint = import.meta.env.VITE_UGC_ENDPOINT

  if (!bucket) return [`${endpoint}/${key}`, '']
  // Query user generated videos using plain key instead of serverless-image-handler
  if (key.endsWith('.mp4')) return [`${endpoint}/${key}`, '']

  const fullImage = generateImageRequest(bucket, key, endpoint)
  const srcsetArray = sizes.map((size) => {
    return `${generateImageRequest(bucket, key, endpoint, size)} ${size}w`
  })
  srcsetArray.push(`${fullImage} 2560w`)

  return [fullImage, srcsetArray.join(', ')]
}

function generateImageRequest(
  bucket: string,
  key: string,
  endpoint: string,
  size?: number
): string {
  const imageRequest = JSON.stringify({
    bucket,
    key,
    edits:
      size !== undefined
        ? {
            resize: {
              width: size,
              height: size,
              fit: 'outside'
            }
          }
        : {}
  })
  return `${endpoint}/${universalBtoa(imageRequest)}`
}
