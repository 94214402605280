<template>
  <PlayerTemplate
    class="player-view"
    header-min-height="var(--player-header-height)"
    :toasts="toasts"
    :is-auth="isAuth"
    :header-links="[
      {
        label: 'Courses',
        to: { name: coursesRouteNames.Courses }
      },
      {
        label: 'Indoor Golf',
        to: { name: clubsRouteNames.IndoorGolf }
      },
      {
        label: 'Events',
        to: { name: eventsRouteNames.Events }
      },
      /*
      {
        label: 'Community',
        to: { name: communityRouteNames.CommunityBoard }
      }*/
    ]"
    :mobile-menu-open="false"
    :user="user"
    :footer-links="[
      {
        label: 'About',
        to: {
          name: staticRouteNames.About
        }
      },
      {
        label: 'Golf Operators',
        to: {
          name: staticRouteNames.OperatorsLanding
        }
      },
      {
        label: 'Cancellation Policy',
        to: {
          name: staticRouteNames.CancellationPolicy
        }
      },
      {
        label: 'Privacy Policy',
        to: { name: staticRouteNames.Privacy }
      }
    ]"
    :footer-social-links="[
      { icon: 'facebook', url: 'https://www.facebook.com/UpSwingGolfAB/' },
      {
        icon: 'instagram',
        url: 'https://www.instagram.com/upswing.golf/'
      },
      { icon: 'twitter', url: 'https://twitter.com/upswing_golf' }
    ]"
    @clickToast="handleClickToast"
  >
    <div class="player-view__main">
      <NotFound v-if="is404" />
      <div v-else class="margin-top--5 margin-bottom--5">
        <router-view v-slot="{ Component }">
          <!-- <transition name="fade" mode="out-in"> -->
          <!-- TODO switch to keep alive -->
          <!-- <keep-alive> -->
          <component :is="Component" v-if="true" />
          <!-- </keep-alive> -->
          <!-- </transition> -->
        </router-view>
      </div>
    </div>
  </PlayerTemplate>
</template>

<script lang="ts" setup>
import { computed, defineComponent, toRefs } from 'vue'

import NotFound from '@player/static/NotFound'
import PlayerTemplate from '@player/_components/PlayerTemplate/PlayerTemplate.vue'

import { communityRouteNames } from '@player/community/router/routeNames'
import { coursesRouteNames } from '@player/courses/router/routeNames'
import { clubsRouteNames } from '@player/clubs/router/routeNames'
import { eventsRouteNames } from '@player/events/router/routeNames'
import { staticRouteNames } from '@player/static/router/routeNames'
import { useRouterStore } from '@base/store/routerStore'
import { useToastsStore } from '@base/store/toastsStore'
import { useUserStore } from '@base/store/userStore'

interface User {
  firstName: string
  lastName: string
  profilePicture?: string
  profilePictureSrcset?: string
  activeMembership?: boolean
  pointsBalance?: number
}

defineComponent({
  name: 'PlayerView',
  components: { PlayerTemplate, NotFound }
})

const toastsStore = toRefs(useToastsStore())
const { toasts, removeToast } = toastsStore

const handleClickToast = (id: string) => {
  removeToast.value(id)
}

const routerStore = toRefs(useRouterStore())
const { is404 } = routerStore

const userStore = toRefs(useUserStore())
const {
  isAuth,
  firstName,
  lastName,
  profilePicture,
  profilePictureSrcset,
  membership,
  pointsBalance
} = userStore

const user = computed(() => {
  const userObj: User = {
    firstName: '',
    lastName: '',
    profilePicture: '',
    profilePictureSrcset: '',
    activeMembership: false,
    pointsBalance: 0
  }
  if (isAuth) {
    userObj.firstName = firstName.value
    userObj.lastName = lastName.value
    userObj.profilePicture = profilePicture.value
    userObj.profilePictureSrcset = profilePictureSrcset.value
    userObj.activeMembership = membership.value.active
    userObj.pointsBalance = pointsBalance.value
  }
  return userObj
})
</script>

<style lang="scss" scoped>
.player-view {
  &__main {
    // min-height: calc(100vh - var(--player-header-height));
  }
}
</style>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
