<template>
  <div
    data-test="us-avatar"
    class="us-avatar"
    :class="src !== '' ? 'us-avatar__bg-white' : 'us-avatar__bg-purple'"
  >
    <USImage
      v-if="src"
      class="us-avatar__image"
      :src="src"
      :srcset="srcset"
      :alt="alt"
      :sizes="sizes"
      :object-fit="objectFit"
      aspect-ratio="none"
    />
    <p
      v-else-if="initials && !src"
      class="us-avatar__initials-label display--d2--medium"
    >
      {{ initials }}
    </p>
    <slot v-else></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import USImage from '@base/components/USImage/USImage.vue'

export default defineComponent({
  name: 'USAvatar',
  components: { USImage },
  props: {
    src: { type: String, default: '' },
    srcset: { type: String, default: '' },
    sizes: { type: String, default: '' },
    objectFit: { type: String, default: 'cover' },
    alt: { type: String, default: '' },
    initials: {
      type: String,
      default: '',
      validator(value: string) {
        return value.length <= 2
      }
    },
    size: {
      type: String,
      default: ''
    }
  }
})
</script>

<style lang="scss" scoped>
@use '@base/styles/v1.0/scss/foundations/breakpoint' as bp;

$us-avatar-size-sm: v-bind("size ? size : '44px'");
$us-avatar-size-lg: v-bind("size ? size : '50px'");

.us-avatar {
  align-items: center;
  border-radius: 50%;
  display: flex;
  flex-flow: row nowrap;
  height: $us-avatar-size-sm;
  justify-content: center;
  min-width: $us-avatar-size-sm;
  overflow: hidden;
  width: $us-avatar-size-sm;

  @media (min-width: bp.$breakpoint--tablet) {
    height: $us-avatar-size-lg;
    min-width: $us-avatar-size-lg;
    width: $us-avatar-size-lg;
  }

  &__image {
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &__initials-label {
    color: var(--color--neutral-a5);
    text-align: center;
    text-transform: uppercase;
  }
}

.us-avatar__bg-purple {
  background-color: var(--color--primary-b1);
}

.us-avatar__bg-white {
  background-color: var(--color--neutral-a5);
}

:deep(img) {
  border-radius: 50%;
}
</style>
