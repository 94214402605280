/* Wrapper for the Intersection Observer API
   https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API

   Based on code from:
    @motiv/fe-patterns/lazyload
   Modified below to work with a single element
*/

export class IntersectionObserverWrapper {
  private _observeCb: IntersectionObserverCallback | null = null
  private _elementsObserver: IntersectionObserver | null = null
  private _initialized = false

  constructor(
    private _element: HTMLElement | null = null,
    private _observerOptions: IntersectionObserverInit | undefined = undefined,
    private onIntersectingCallback: (
      entry: IntersectionObserverEntry
    ) => void = () => {},
    private onNotIntersectingCallback: (
      entry: IntersectionObserverEntry
    ) => void = () => {}
  ) {}

  init(): boolean {
    if (!this._element) return false

    this._observeCb = (
      entries: IntersectionObserverEntry[],
      observer: IntersectionObserver
    ) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
          this.onIntersectingCallback(entry)
        } else {
          this.onNotIntersectingCallback(entry)
        }
      })
    }

    if ('IntersectionObserver' in window) {
      this._elementsObserver = new IntersectionObserver(
        this._observeCb,
        this._observerOptions
      )

      this._elementsObserver.observe(this._element)

      this._initialized = true
      return true
    }

    return false
  }

  reset(newElement: HTMLElement): void {
    if (!this._initialized || !this._element || !this._elementsObserver) return

    this._elementsObserver.unobserve(this._element)

    this._element = newElement

    this._elementsObserver.observe(this._element)
  }

  destroy(): void {
    if (
      'IntersectionObserver' in window &&
      this._elementsObserver !== null &&
      this._element
    ) {
      this._elementsObserver.unobserve(this._element)
    }
  }
}
