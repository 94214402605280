var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var optDefaults = {
    openData: 'toast-open',
    removeOnClose: false,
    removeTimeout: 0
};
var Toast = /** @class */ (function () {
    function Toast(toast, closeBtn, opts) {
        if (opts === void 0) { opts = optDefaults; }
        this.toast = toast;
        this.closeBtn = closeBtn;
        this.opts = opts;
        this.opts = __assign(__assign({}, optDefaults), opts);
        if (this.closeBtn) {
            this.closeBtn.addEventListener('click', this.close.bind(this));
        }
    }
    Toast.prototype.open = function () {
        this.toast.setAttribute("data-" + this.opts.openData, '');
    };
    Toast.prototype.close = function () {
        var _this = this;
        this.toast.removeAttribute("data-" + this.opts.openData);
        if (this.opts.removeOnClose) {
            setTimeout(function () {
                var _a;
                (_a = _this.toast.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(_this.toast);
            }, this.opts.removeTimeout);
        }
    };
    return Toast;
}());
export { Toast };
