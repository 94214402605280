<template>
  <component
    :is="is"
    data-test="us-button"
    :class="[
      'us-button',
      type ? `us-button--${type}` : '',
      disabled ? `us-button--disabled` : '',
      icon && !label ? 'us-button--icon-only' : ''
    ]"
    :disabled="disabled ? true : null"
  >
    <USIcon
      v-if="icon"
      :name="icon"
      css-diameter="var(--iconDiameter)"
      :fill="iconFill"
      :color="iconColor"
      class="us-button__icon"
    />
    <p v-if="label" class="us-button__label button--label">
      {{ label }}
    </p>
  </component>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import USIcon from '@base/components/USIcon/USIcon.vue'
export default defineComponent({
  name: 'USButton',
  components: { USIcon },
  props: {
    is: { type: String, default: 'div' },
    type: {
      type: String,
      default: 'primary',
      validator: (value: string) => {
        return ['primary', 'inline', 'icon-circle'].includes(value)
      }
    },
    label: { type: String, default: '' },
    icon: { type: String, default: '' },
    iconColor: { type: String, default: undefined },
    iconFill: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
    semantic: {
      type: String as PropType<'alert' | 'error' | 'info' | 'none' | 'success'>,
      default: 'none'
    }
  }
})
</script>

<style lang="scss" scoped>
@use '@base/styles/v1.0/scss/foundations/breakpoint';
@use '@base/styles/v1.0/scss/foundations/motion';

// TODO add transition color effects
.us-button {
  --iconDiameter: 16px;
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  flex-flow: row nowrap;
  max-width: 100%;
  overflow: hidden;
  vertical-align: middle;

  &:disabled,
  &--disabled {
    cursor: not-allowed;
  }

  @include breakpoint.min-width(breakpoint.$breakpoint--tablet) {
    --iconDiameter: 20px;
  }

  @include breakpoint.min-width(breakpoint.$breakpoint--desktop) {
    --iconDiameter: 24px;
  }

  &__icon {
    margin-right: var(--spacing--8);
  }

  &__label {
    user-select: none;
    white-space: nowrap;
  }

  &--icon-only {
    .us-button__icon {
      margin-right: 0;
    }
  }
}

.us-button--primary {
  background-color: var(--color--primary-b1);
  border-radius: var(--border-radius--large);
  color: var(--color--neutral-a5);
  padding: var(--spacing--8) var(--spacing--6);
  transition: background-color motion.duration(1) ease-in-out;
  will-change: background-color;

  &:active {
    background-color: var(--color--primary-b3);
  }

  &:focus,
  &:hover {
    background-color: var(--color--primary-b2);
  }

  &:disabled,
  &.us-button--disabled {
    background-color: var(--color--neutral-a4);
    color: var(--color--neutral-a3);
  }
}

.us-button--inline,
.us-button--icon-circle {
  border-radius: 0;
  color: var(--color--primary-b1);
  padding: 0;

  &:focus,
  &:hover {
    color: var(--color--primary-b2);
  }

  &:active {
    color: var(--color--primary-b3);
  }

  &:disabled,
  &.us-button--disabled {
    color: var(--color--neutral-a3);
  }
}

.us-button--icon-circle {
  $buttonDiameter--mobile: 30px;
  $buttonDiameter--tablet: 40px;
  $iconDiameter--mobile: 8px;
  $iconDiameter--tablet: 13px;

  --iconDiameter: #{$iconDiameter--mobile};
  border-radius: var(--border-radius--round);
  box-shadow: var(--box-shadow--b1);
  padding: calc((#{$buttonDiameter--mobile} - var(--iconDiameter)) / 2);

  @include breakpoint.min-width(breakpoint.$breakpoint--tablet) {
    --iconDiameter: #{$iconDiameter--tablet};
    padding: calc((#{$buttonDiameter--tablet} - var(--iconDiameter)) / 2);
  }
}
</style>
