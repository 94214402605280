import {
  createMemoryHistory,
  createRouter as vCreateRouter,
  createWebHistory,
  RouteRecordRaw
} from 'vue-router'

export function createBaseRouter(routes: Array<RouteRecordRaw>) {
  const router = vCreateRouter({
    history: import.meta.env.SSR ? createMemoryHistory() : createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
        return {
          el: to.hash
        }
      }
      if (savedPosition) {
        return savedPosition
      } else {
        return {
          left: 0,
          top: 0
        }
      }
    }
  })
  return router
}
