import { defineStore } from 'pinia'

export const useRouterStore = defineStore({
  id: 'router',
  state: () => ({
    redirect: {
      code: 0,
      path: ''
    },
    is404: false
  }),
  getters: {
    isNot404: (state) => !state.is404
  }
})
