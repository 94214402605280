<template>
  <router-link
    v-for="(link, idx) in menuItems"
    :key="idx"
    :to="link.to"
    class="menu-items-with-bird__link heading--h3"
    @click="$emit('navigate')"
  >
    <USIcon
      :diameter="15"
      name="upswing-bird"
      class="menu-items-with-bird__upswing-bird"
      color="var(--icon-color-upswing-bird)"
    />
    <span class="menu-items-with-bird__label">
      {{ link.label }}
    </span>
  </router-link>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { RouteLocationRaw } from 'vue-router'

import USIcon from '@base/components/USIcon/USIcon.vue'

interface Action {
  label: string
  to: RouteLocationRaw
  icon?: string
}

export default defineComponent({
  name: 'MenuItemsWithIcon',
  components: { USIcon },
  props: {
    menuItems: {
      type: Array as PropType<Array<Action>>,
      default: () => []
    }
  },
  emits: ['navigate']
})
</script>

<style lang="scss" scoped>
.menu-items-with-bird {
  &__link {
    align-items: center;
    border-radius: var(--border-radius--small);
    color: var(--color--primary-b2);
    cursor: pointer;
    display: flex;
    flex-flow: row;
    padding: 0;
    transition: var(--animation-speed-medium); // TODO update motion
    width: fit-content;

    &:not(:last-child) {
      margin-bottom: var(--spacing--3);
    }

    &:hover {
      .menu-items-with-bird__upswing-bird {
        display: block;
        opacity: 1;
      }
      // TODO add motion
    }

    &-icon {
      margin-right: var(--spacing--6);
    }
  }

  &__upswing-bird {
    --icon-color-upswing-bird: var(--color--secondary-c1);
    display: none;
    margin-right: var(--spacing--7);
    opacity: 0;
  }
}
</style>
