/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BookingSortField {
  NEWEST = "NEWEST",
  OLDEST = "OLDEST",
}

export enum BookingStatus {
  CANCELLED = "CANCELLED",
  CONFIRMED = "CONFIRMED",
  ERROR = "ERROR",
  PENDING = "PENDING",
}

export enum BookingTeeTimeType {
  HOLE_18 = "HOLE_18",
  HOLE_9 = "HOLE_9",
}

export enum BookingType {
  EVENT = "EVENT",
  TEE_TIME = "TEE_TIME",
}

export enum ClubSortField {
  CREATED_AT = "CREATED_AT",
  DISTANCE = "DISTANCE",
  NAME = "NAME",
}

export enum CoursePriceRange {
  RANGE_1 = "RANGE_1",
  RANGE_2 = "RANGE_2",
  RANGE_3 = "RANGE_3",
  RANGE_4 = "RANGE_4",
  RANGE_5 = "RANGE_5",
}

export enum CourseSortField {
  CLUB_REVIEWS = "CLUB_REVIEWS",
  COURSE_NAME = "COURSE_NAME",
  CREATED_AT = "CREATED_AT",
  DISTANCE = "DISTANCE",
  PRICE_RANGE = "PRICE_RANGE",
}

export enum EventPurchaseType {
  EXTERNAL = "EXTERNAL",
  UPSWING = "UPSWING",
}

export enum EventSortField {
  CREATED_AT = "CREATED_AT",
  DATE_END = "DATE_END",
  DATE_START = "DATE_START",
  DISTANCE = "DISTANCE",
  ENTRY_FEE = "ENTRY_FEE",
  NAME = "NAME",
  REGISTRATION_END = "REGISTRATION_END",
  REGISTRATION_START = "REGISTRATION_START",
}

export enum EventStartType {
  SHOTGUN_START = "SHOTGUN_START",
  TEE_OFF_START = "TEE_OFF_START",
}

export enum EventStatus {
  ACCEPTING_REGISTRATIONS = "ACCEPTING_REGISTRATIONS",
  CANCELLED = "CANCELLED",
  FULLY_BOOKED = "FULLY_BOOKED",
  NO_STATUS = "NO_STATUS",
  REGISTRATION_CLOSED = "REGISTRATION_CLOSED",
  REGISTRATION_OPEN = "REGISTRATION_OPEN",
  WAITLIST_AVAILABLE = "WAITLIST_AVAILABLE",
}

/**
 * Accepted image file extension
 */
export enum FileExtension {
  JPEG = "JPEG",
  JPG = "JPG",
  MP4 = "MP4",
  PNG = "PNG",
}

export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
  OTHER = "OTHER",
}

export enum PurchaseSortField {
  NEWEST = "NEWEST",
  OLDEST = "OLDEST",
}

export enum ResourceSortField {
  DATE = "DATE",
}

export enum Role {
  ADMIN = "ADMIN",
  OPERATOR = "OPERATOR",
  PLAYER = "PLAYER",
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum TeeTimeSortField {
  AVAILABILITY = "AVAILABILITY",
  TIME = "TIME",
}

export enum TeeTimesHoleFilterValue {
  ALL = "ALL",
  ALL18 = "ALL18",
  ALL9 = "ALL9",
}

export enum UserSortField {
  DATE_REGISTERED = "DATE_REGISTERED",
  FIRST_NAME = "FIRST_NAME",
  LAST_NAME = "LAST_NAME",
}

/**
 * Standardized validation errors
 */
export enum ValidationError {
  ALPHANUM = "ALPHANUM",
  ANNOUNCEMENT_NO_OWNER = "ANNOUNCEMENT_NO_OWNER",
  ANNOUNCEMENT_TOO_MANY_OWNERS = "ANNOUNCEMENT_TOO_MANY_OWNERS",
  DOES_NOT_EXIST = "DOES_NOT_EXIST",
  INCORRECT_LOGIN = "INCORRECT_LOGIN",
  INVALID_DATE = "INVALID_DATE",
  INVALID_EMAIL = "INVALID_EMAIL",
  INVALID_LAT = "INVALID_LAT",
  INVALID_LON = "INVALID_LON",
  INVALID_PASSWORD = "INVALID_PASSWORD",
  INVALID_PHOTO_PATH = "INVALID_PHOTO_PATH",
  INVALID_PRICE_RANGE = "INVALID_PRICE_RANGE",
  LESS_THAN_START_DATE = "LESS_THAN_START_DATE",
  LIMIT_TOO_LARGE = "LIMIT_TOO_LARGE",
  MAX_LENGTH = "MAX_LENGTH",
  MAX_LENGTH_500 = "MAX_LENGTH_500",
  MIN_LENGTH = "MIN_LENGTH",
  NEGATIVE = "NEGATIVE",
  NON_UNIQUE = "NON_UNIQUE",
  NOT_INTEGER = "NOT_INTEGER",
  NOT_OPERATOR = "NOT_OPERATOR",
  NOT_POSITIVE = "NOT_POSITIVE",
  NO_PARENT_ARGUMENT = "NO_PARENT_ARGUMENT",
  NUMBER_MAX = "NUMBER_MAX",
  NUMBER_MAX_DECIMAL_2 = "NUMBER_MAX_DECIMAL_2",
  NUMBER_MAX_DECIMAL_6 = "NUMBER_MAX_DECIMAL_6",
  NUMBER_MIN = "NUMBER_MIN",
  ONE_REQUIRED = "ONE_REQUIRED",
  ONE_TRUTHY = "ONE_TRUTHY",
  PAST_DATE = "PAST_DATE",
  REQUIRED = "REQUIRED",
  SORT_DUPLICATE = "SORT_DUPLICATE",
  TEE_TIME_DATE_PRECISION = "TEE_TIME_DATE_PRECISION",
  TOKEN_EXPIRED = "TOKEN_EXPIRED",
  TOKEN_INVALID = "TOKEN_INVALID",
  TOO_MANY_PARENT_ARGUMENTS = "TOO_MANY_PARENT_ARGUMENTS",
}

export enum Visibility {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
}

export interface CancelBookingInput {
  bookingId: string;
}

export interface CancelMembershipInput {
  userId: string;
}

export interface GeomInput {
  lat: number;
  lon: number;
}

export interface GetAdmissionTypesFilter {
  admissionTypeIds?: string[] | null;
  clubId?: string | null;
}

export interface GetBookingInput {
  bookingId: string;
}

export interface GetBookingsFilters {
  clubId?: string | null;
  eventId?: string | null;
  status?: BookingStatus | null;
  teeTimeId?: string | null;
  type?: BookingType | null;
  userId?: string | null;
}

export interface GetBookingsInput {
  filters: GetBookingsFilters;
  pagination: Pagination;
  sort: BookingSortField;
}

export interface ListClubsFilter {
  addressGeom?: GeomInput | null;
  amenities?: string[] | null;
  clubName?: string | null;
  includeDeactivated?: boolean | null;
  isNonGolf?: boolean | null;
}

export interface ListClubsSort {
  sortField: ClubSortField;
  sortOrder: SortOrder;
}

export interface ListCoursesFilter {
  addressGeom?: GeomInput | null;
  amenities?: string[] | null;
  availability?: number | null;
  clubId?: string | null;
  clubIds?: string[] | null;
  clubSlug?: string | null;
  courseName?: string | null;
  endDate?: any | null;
  includeDeactivated?: boolean | null;
  numHoles?: number | null;
  priceRange?: CoursePriceRange[] | null;
  startDate?: any | null;
}

export interface ListCoursesSort {
  sortField: CourseSortField;
  sortOrder: SortOrder;
}

export interface ListEventsFilter {
  addressGeom?: GeomInput | null;
  amenities?: string[] | null;
  clubId?: string | null;
  clubIds?: string[] | null;
  clubSlug?: string | null;
  createdByPlayerId?: string | null;
  dateStartAfter?: any | null;
  dateStartBefore?: any | null;
  eventType?: string[] | null;
  ids?: string[] | null;
  includeArchived?: boolean | null;
  includeDeactivated?: boolean | null;
  includeDrafts?: boolean | null;
  name?: string | null;
}

export interface ListEventsSort {
  sortField: EventSortField;
  sortOrder: SortOrder;
}

export interface ListTeeTimesFilter {
  courseId?: string | null;
  courseSlug?: string | null;
  endTimestamp?: any | null;
  holes?: TeeTimesHoleFilterValue | null;
  ids?: string[] | null;
  includeDeactivated?: boolean | null;
  maxAvailability?: number | null;
  maxCost?: number | null;
  minAvailability?: number | null;
  minCost?: number | null;
  startTimestamp?: any | null;
}

export interface ListTeeTimesSortObject {
  sortField: TeeTimeSortField;
  sortOrder: SortOrder;
}

export interface NewAdmissionTypeOptions {
  defaultGreenFee: number;
  name: string;
  position: number;
}

export interface NewBookingAttendee {
  email: string;
  firstName?: string | null;
  golfEventAdmissionId?: string | null;
  lastName?: string | null;
  teeTimeAdmissionId?: string | null;
}

export interface NewMembershipInput {
  gift?: boolean | null;
  userId: string;
}

export interface NewReviewInput {
  attributeIds?: string[] | null;
  body?: string | null;
  bookingId: string;
  gallery?: string[] | null;
  rating: number;
  userId: string;
}

export interface NewTeeTimeAdmissionInput {
  admissionTypeId: string;
  fee9?: number | null;
  fee18?: number | null;
}

export interface Pagination {
  limit: number;
  offset: number;
}

export interface TeeTimeInput {
  admissions: NewTeeTimeAdmissionInput[];
  all18: boolean;
  availability: number;
  back9: boolean;
  dateTime: any;
  front9: boolean;
}

export interface UpdateAdmissionTypeOptions {
  defaultGreenFee?: number | null;
  name?: string | null;
  position?: number | null;
}

export interface UpdateBookingAttendeeInput {
  bookingAttendeeId: string;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
}

export interface UpdateCourseInputType {
  attributes?: string[] | null;
  courseName?: string | null;
  courseRating?: number | null;
  description?: string | null;
  gallery?: string[] | null;
  map?: string | null;
  numHoles?: number | null;
  open?: boolean | null;
  par?: number | null;
  priceRange?: CoursePriceRange | null;
}

export interface UpdateEventAdmission {
  admissionTypeId?: string | null;
  id?: string | null;
  price: number;
}

export interface UpdateReviewOptions {
  attributeIds?: string[] | null;
  body?: string | null;
  gallery?: string[] | null;
  rating?: number | null;
}

export interface UpdateReviewResponseOptions {
  body: string;
}

export interface UpdateTeeTimeInputType {
  admissions?: UpdateTeeTimesAdmission[] | null;
  all18?: boolean | null;
  availability?: number | null;
  back9?: boolean | null;
  front9?: boolean | null;
  id: string;
}

export interface UpdateTeeTimesAdmission {
  admissionTypeId?: string | null;
  fee9?: number | null;
  fee18?: number | null;
  id?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
