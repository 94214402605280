import { RouteRecordRaw } from 'vue-router'

import { leaderboardRouteNames } from './routeNames'

export const leaderboardRoutes: RouteRecordRaw[] = [
  {
    name: leaderboardRouteNames.Leaderboard,
    path: '/leaderboard',
    component: () => import('../Leaderboard')
  }
]
