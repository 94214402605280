import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core'
import { Pinia } from 'pinia'
import { RouteLocationRaw, Router } from 'vue-router'

import { useUserStore } from '@base/store/userStore'
import { redirectToNonTrailingSlash } from './redirectToWithoutTrailingSlash'
import { useRouterStore } from '@base/store/routerStore'

const redirectFn = (route: RouteLocationRaw): RouteLocationRaw => {
  if (typeof route === 'string') {
    return {
      path: route,
      params: {
        redirectCode: 302 // temporary redirect
      }
    }
  }
  return {
    ...route,
    params:
      'params' in route
        ? { ...route.params, redirectCode: 302 }
        : { redirectCode: 302 }
  }
}

export function baseGuards(
  router: Router,
  pinia: Pinia,
  client: ApolloClient<NormalizedCacheObject>
): void {
  const userStore = useUserStore(pinia)
  const routerStore = useRouterStore(pinia)

  router.beforeEach(redirectToNonTrailingSlash)

  router.beforeEach((to, from, next) => {
    if (typeof window !== 'undefined') {
      window.dispatchEvent(new Event('show-header'))
    }
    next()
  })

  // Populate the user
  router.beforeEach(async (to, from, next) => {
    if (!userStore.id) {
      await userStore.fetchUser(client)
    }
    return next()
  })

  router.beforeEach((to, from, next) => {
    const requiresAuth = to.meta.requiresAuth
    if (!requiresAuth) return next()
    const { auth, admin, operator, player, clubs } = requiresAuth
    if (
      auth === undefined &&
      admin === undefined &&
      operator === undefined &&
      player === undefined &&
      clubs === undefined
    ) {
      return next()
    }

    if (auth !== undefined && auth !== userStore.isAuth) {
      return next(redirectFn(requiresAuth.authRedirect || '/'))
    }
    if (admin !== undefined && admin !== userStore.isAdmin) {
      return next(redirectFn(requiresAuth.adminRedirect || '/'))
    }
    if (operator !== undefined && operator !== userStore.isOperator) {
      return next(redirectFn(requiresAuth.operatorRedirect || '/'))
    }
    if (player !== undefined && player !== userStore.isPlayer) {
      return next(redirectFn(requiresAuth.playerRedirect || '/'))
    }
    if (clubs !== undefined && clubs.length) {
      const overlap = <T>(arr: T[], target: T[]) =>
        target.every((id) => arr.includes(id))

      if (!overlap(userStore.clubs, clubs))
        return next(redirectFn(requiresAuth.clubsRedirect || ''))
    }
    return next()
  })

  router.afterEach(() => {
    routerStore.$reset()
    routerStore.is404 = false
  })
}
