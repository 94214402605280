<template>
  <div
    data-test="us-toast"
    :class="['us-toast', `us-toast--semantic--${semantic}`, 'body--p1--medium']"
  >
    <USIcon v-if="icon" :name="icon" css-diameter="var(--iconDiameter)" />
    <p class="us-toast__content">
      {{ content }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

import USIcon from '@base/components/USIcon/USIcon.vue'

import { ToastSemantic } from '@base/_types/Toast'

export default defineComponent({
  name: 'USToast',
  components: {
    USIcon
  },
  props: {
    content: { type: String, required: true },
    icon: { type: String, required: true },
    semantic: {
      type: String as PropType<ToastSemantic>,
      default: ToastSemantic.Info
    }
  }
})
</script>

<style lang="scss" scoped>
@use '@base/styles/v1.0/scss/foundations/breakpoint';

.us-toast {
  --iconDiameter: 16px;

  align-items: center;
  border-radius: var(--border-radius--small);
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  max-width: 100%;
  padding: var(--spacing--7) var(--spacing--5);
  visibility: visible;

  @include breakpoint.min-width(breakpoint.$breakpoint--tablet) {
    --iconDiameter: 20px;
  }

  @include breakpoint.min-width(breakpoint.$breakpoint--desktop) {
    --iconDiameter: 24px;
  }

  &__icon {
    align-self: flex-start;
    flex: 0 0 auto;
  }

  &__content {
    margin-left: var(--spacing--7);
  }

  &--semantic--alert {
    background-color: var(--color--semantic--alert-4);
    color: var(--color--semantic--alert-1);
  }

  &--semantic--error {
    background-color: var(--color--semantic--error-4);
    color: var(--color--semantic--error-1);
  }

  &--semantic--info {
    background-color: var(--color--semantic--info-4);
    color: var(--color--semantic--info-1);
  }

  &--semantic--success {
    background-color: var(--color--semantic--success-4);
    color: var(--color--semantic--success-1);
  }
}
</style>
