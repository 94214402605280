import { RouteRecordRaw } from 'vue-router'

import { accountRouteNames } from '@player/account/router/routeNames'
import { resetPasswordRoute } from '@base/lib/resetPasswordRoute'
import { usersRouteNames } from './routeNames'

const usersMeta = {
  requiresAuth: {
    auth: false,
    authRedirect: { name: accountRouteNames.Profile }
  }
}

export const usersRoutes: RouteRecordRaw[] = [
  {
    name: usersRouteNames.Register,
    path: '/register',
    component: () => import('../Register'),
    props: true,
    meta: {
      ...usersMeta
    }
  },
  {
    name: usersRouteNames.SignIn,
    path: '/sign-in',
    component: () => import('../SignIn'),
    props: (route) => ({
      redirectTo: route.query.redirectTo
    }),
    meta: {
      ...usersMeta
    }
  },
  {
    name: usersRouteNames.ForgotPassword,
    path: '/forgot-password',
    component: () => import('../ForgotPassword'),
    props: true,
    meta: {
      ...usersMeta
    }
  },
  {
    name: usersRouteNames.ResetPassword,
    path: '/reset-password',
    component: () => import('../ResetPassword'),
    props: (route) => ({
      token: route.query[resetPasswordRoute.tokenParam]
    }),
    meta: {
      ...usersMeta
    }
  }
]
