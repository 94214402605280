<template>
  <BaseTemplate
    class="player-template"
    data-test="player-template"
    header-min-height="var(--player-header-height)"
    :toasts="toasts"
    @clickToast="handleClickToast"
  >
    <template #header>
      <PlayerHeader
        :is-auth="isAuth"
        :links="headerLinks"
        :user="user"
        :header-min-height="headerMinHeight"
        :header-logo-link="headerLogoLink"
        @toggleMobileMenu="handleToggleMobileMenu"
      />
    </template>
    <template #default>
      <slot />
    </template>
    <template #main-sibling>
      <MobileMenu
        v-model:is-open="mobileIsOpen"
        :is-open="mobileMenuOpen"
        :menu-items="headerLinks"
      />
    </template>
    <template #footer>
      <Footer :links="footerLinks" :social-links="footerSocialLinks" />
    </template>
  </BaseTemplate>
</template>

<script lang="ts" setup>
import { defineComponent, defineEmits, defineProps, PropType, ref } from 'vue'
import { onBeforeRouteUpdate, RouteLocationRaw } from 'vue-router'

import PlayerHeader from '../PlayerHeader/PlayerHeader.vue'
import MobileMenu from '@base/components/MobileMenu/MobileMenu.vue'
import Footer from '@base/components/Footer/Footer.vue'
import BaseTemplate from '@base/components/BaseTemplate/BaseTemplate.vue'

import { Toast } from '@base/_types/Toast'

interface Link {
  label: string
  to: RouteLocationRaw
}

interface SocialLink {
  icon: string
  url: string
}

interface User {
  firstName: string
  lastName: string
  profilePicture?: string
  profilePictureSrcset?: string
  activeMembership?: boolean
  pointsBalance?: number
}

defineComponent({
  name: 'PlayerTemplate',
  components: {
    BaseTemplate,
    Footer,
    MobileMenu,
    PlayerHeader
  }
})

const props = defineProps({
  headerMinHeight: {
    type: String,
    default: '0px'
  },
  headerLinks: {
    type: Array as PropType<Array<Link>>,
    required: true
  },
  isAuth: { type: Boolean, default: false },
  user: { type: Object as PropType<User>, required: true },
  mobileMenuOpen: { type: Boolean, default: false },
  footerLinks: {
    type: Array as PropType<Array<Link>>,
    default: [] as Array<Link>
  },
  footerSocialLinks: {
    type: Array as PropType<Array<SocialLink>>,
    default: [] as Array<SocialLink>
  },
  headerLogoLink: {
    type: Boolean,
    default: true
  },
  toasts: {
    type: Array as PropType<Toast[]>,
    default: () => []
  }
})

const emit = defineEmits(['clickToast'])

const mobileIsOpen = ref(props.mobileMenuOpen)

const handleToggleMobileMenu = () => {
  mobileIsOpen.value = !mobileIsOpen.value
}
const handleClickToast = (id: string) => {
  emit('clickToast', id)
}
</script>
