<template>
  <div class="grid__row">
    <div class="not-found grid__column grid__column--12">
      <USImage
        :src="bgImage"
        :lazy="true"
        object-fit="initial"
        aspect-ratio="none"
        class="not-found__bg"
      />
      <h1 class="heading--h1">fore-oh-fore</h1>
      <p
        class="not-found__main body--p1--regular margin-bottom--5 margin-top--6"
      >
        Oops…Sorry about that, we can’t seem to find the page you’re looking
        for.
      </p>
      <div>
        <router-link
          class="not-found__button"
          :to="{
            name: coursesRouteNames.Events
          }"
        >
          <USButton label="Explore Events" icon="trophy" />
        </router-link>
        <router-link
          class="not-found__button"
          :to="{
            name: eventsRouteNames.Courses
          }"
        >
          <USButton label="Explore Courses" icon="clock" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useHead } from '@vueuse/head'
import { playerTitle } from '@player/_lib/playerTitle'
import USImage from '@base/components/USImage/USImage.vue'
import USButton from '@base/components/USButton/USButton.vue'
import bgImage from '@player/static/assets/NotFound.svg'

import { coursesRouteNames } from '@player/courses/router/routeNames'
import { eventsRouteNames } from '@player/events/router/routeNames'

export default defineComponent({
  name: 'NotFound',
  components: { USImage, USButton },
  setup() {
    useHead({
      title: playerTitle('Page Not Found')
    })
    return {
      bgImage,
      coursesRouteNames,
      eventsRouteNames
    }
  }
})
</script>

<style lang="scss" scoped>
.not-found {
  align-items: center;
  color: var(--color--neutral-a1);
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-bottom: var(--spacing--3);
  margin-top: var(--spacing--3);

  &__bg {
    margin-bottom: var(--spacing--5);
    max-width: 90px;
  }

  &__main {
    text-align: center;
  }

  &__button {
    margin-left: var(--spacing--8);
    margin-right: var(--spacing--8);
  }
}
</style>
