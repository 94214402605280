<template>
  <div
    data-test="usicon"
    :class="['us-icon', `us-icon--${name}`]"
    v-html="iconsReference[name]"
  ></div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

import { iconsReference } from './iconsReference'

export default defineComponent({
  name: 'USIcon',
  props: {
    name: { type: String, required: true },
    fill: { type: String, default: 'var(--color--secondary-c2)' },
    color: { type: String, default: 'inherit' },
    diameter: { type: Number, default: 28 },
    cssDiameter: { type: String, default: '' }
  },
  data: () => ({
    iconsReference: iconsReference
  })
})
</script>

<style lang="scss" scoped>
.us-icon {
  --us-icon-diameter: v-bind("cssDiameter ? cssDiameter : diameter + 'px'");
  --background-color: v-bind(fill);
  color: v-bind(color);
  width: var(--us-icon-diameter, 15px);
  min-width: var(--us-icon-diameter, 15px);

  :deep(svg) {
    fill: currentColor;
    // write in default diameter
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
}
</style>
