<template>
  <div data-test="usbackground" class="us-background" :style="styles">
    <USImage v-if="imgArgs" v-bind="imgArgs" />

    <slot v-else />
  </div>
</template>

<script lang="ts">
import USImage from '../USImage/USImage.vue'

import { defineComponent, PropType } from 'vue'

const alignValidator = (val: string, directions: string[]): boolean => {
  const parts: string[] = val.split('-')
  if (parts.length < 2) return false

  const direction: string = parts[0]
  const amount: string = parts[1]

  return (
    directions.includes(direction) &&
    ['0', '10', '25', '50', '75', '100'].includes(amount)
  )
}

interface imgArgs {
  src: string
  srcset?: string
  alt?: string
  title?: string
  lazy?: boolean
  sizes?: string
  objectPosition?: string
  objectFit?: string
  aspectRatio?: string
}

export default defineComponent({
  name: 'USBackground',
  components: {
    USImage
  },
  props: {
    imgArgs: { type: Object as PropType<imgArgs>, required: true },
    vAlign: {
      type: String,
      // validator: (val: string): boolean => {
      //   return alignValidator(val, ['top', 'bottom'])
      // },
      default: 'top-0'
    },
    hAlign: {
      type: String,
      // validator: (val: string): boolean => {
      //   return alignValidator(val, ['left', 'right'])
      // },
      default: 'left-0'
    }
  },
  computed: {
    styles() {
      const styles: any = {}

      const hParts: string[] | undefined = this.hAlign?.split('-')
      const vParts: string[] | undefined = this.vAlign?.split('-')

      if (hParts && hParts.length >= 2) {
        styles[hParts[0]] = `${hParts[1]}%`
      }

      if (vParts && vParts.length >= 2) {
        styles[vParts[0]] = `${vParts[1]}%`
      }

      return styles
    }
  }
})
</script>

<style lang="scss" scoped>
.us-background {
  position: absolute;
}
</style>
