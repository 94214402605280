import { gql } from '@apollo/client/core'

import {
  SERVER_ERROR,
  UNAUTHENTICATED_ERROR,
  UNAUTHORIZED_ERROR
} from '../fragments/errors'
import { USER_FRAGMENT } from '../fragments/user'

export const GET_USER = gql`
  ${SERVER_ERROR}
  ${UNAUTHENTICATED_ERROR}
  ${UNAUTHORIZED_ERROR}
  ${USER_FRAGMENT}

  query getUser {
    getUser {
      ... on GetUserOutput {
        user {
          ...User
        }
      }
      ...UnauthenticatedError
      ...UnauthorizedError
      ...ServerError
    }
  }
`
