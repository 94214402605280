import { Router, RouteRecordRaw } from 'vue-router'
import { Pinia } from 'pinia'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core'

import { baseGuards } from '@base/router/guards'
import { createBaseRouter } from '@base/router/router'

import { accountRoutes } from '@player/account/router/routes'
import { bookingsRoutes } from '@player/bookings/router/routes'
import { clubsRoutes } from '@player/clubs/router/routes'
import { communityRoutes } from '@player/community/router/routes'
import { coursesRoutes } from '@player/courses/router/routes'
import { eventsRoutes } from '@player/events/router/routes'
import { leaderboardRoutes } from '@player/leaderboard/router/routes'
import { reviewsRoutes } from '@player/reviews/router/routes'
import { staticRoutes } from '@player/static/router/routes'
import { usersRoutes } from '@player/users/router/routes'

const routes: Array<RouteRecordRaw> = [
  ...accountRoutes,
  ...bookingsRoutes,
  ...clubsRoutes,
  ...communityRoutes,
  ...coursesRoutes,
  ...eventsRoutes,
  ...leaderboardRoutes,
  ...reviewsRoutes,
  ...staticRoutes,
  ...usersRoutes
]

export function createRouter(
  pinia: Pinia,
  client: ApolloClient<NormalizedCacheObject>
): Router {
  const router = createBaseRouter(routes)

  baseGuards(router, pinia, client)

  return router
}
