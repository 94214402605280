var Accordion = /** @class */ (function () {
    function Accordion(accordion, itemSelector, titleSelector, descriptionSelector, forbidMultiOpen) {
        var _this = this;
        if (itemSelector === void 0) { itemSelector = '.accordion__item'; }
        if (titleSelector === void 0) { titleSelector = '.accordion__title'; }
        if (descriptionSelector === void 0) { descriptionSelector = '.accordion__description'; }
        if (forbidMultiOpen === void 0) { forbidMultiOpen = false; }
        this.accordion = accordion;
        this.itemSelector = itemSelector;
        this.titleSelector = titleSelector;
        this.descriptionSelector = descriptionSelector;
        this.forbidMultiOpen = forbidMultiOpen;
        this._listeners = {};
        this._resizeObserver = null;
        this.items = this.accordion.querySelectorAll(this.itemSelector);
        this._resizeObserver = new ResizeObserver(function (entries) {
            for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
                var entry = entries_1[_i];
                var item = entry.target.parentElement;
                if (item && item.classList.contains('open')) {
                    var desc = item.querySelector(descriptionSelector);
                    if (!desc)
                        continue;
                    desc.style.maxHeight = desc.scrollHeight + "px";
                }
            }
        });
        var _loop_1 = function (i) {
            var title = this_1.items[i].querySelector(this_1.titleSelector);
            if (!title)
                return "continue";
            this_1._listeners[i] = function () {
                _this.toggle(i);
            };
            title.addEventListener('click', this_1._listeners[i]);
            this_1._resizeObserver.observe(title);
        };
        var this_1 = this;
        for (var i = 0; i < this.items.length; i++) {
            _loop_1(i);
        }
    }
    Accordion.prototype.toggle = function (itemIdx) {
        if (itemIdx === void 0) { itemIdx = 0; }
        var item = this.items[itemIdx];
        if (!item)
            return [false, false];
        if (item.classList.contains('open')) {
            return [this._close(item), false];
        }
        else {
            return [this._open(item), true];
        }
    };
    Accordion.prototype.open = function (itemIdx) {
        if (itemIdx === void 0) { itemIdx = 0; }
        var item = this.items[itemIdx];
        if (!item)
            return false;
        return this._open(item);
    };
    Accordion.prototype.close = function (itemIdx) {
        if (itemIdx === void 0) { itemIdx = 0; }
        var item = this.items[itemIdx];
        if (!item)
            return false;
        return this._close(item);
    };
    Accordion.prototype._open = function (item) {
        var desc = item.querySelector(this.descriptionSelector);
        if (!desc)
            return false;
        if (this.forbidMultiOpen) {
            for (var i = 0; i < this.items.length; i++) {
                var openItem = this.items[i];
                if (openItem !== item && openItem.classList.contains('open')) {
                    this._close(openItem);
                    break;
                }
            }
        }
        item.classList.add('open');
        desc.style.maxHeight = desc.scrollHeight + "px";
        return true;
    };
    Accordion.prototype._close = function (item) {
        var desc = item.querySelector(this.descriptionSelector);
        if (!desc)
            return false;
        item.classList.remove('open');
        desc.style.removeProperty('max-height');
        return true;
    };
    Accordion.prototype.destory = function () {
        for (var i = 0; i < this.items.length; i++) {
            var title = this.items[i].querySelector(this.titleSelector);
            if (!title)
                continue;
            title.removeEventListener('click', this._listeners[i]);
            if (this._resizeObserver)
                this._resizeObserver.unobserve(title);
        }
    };
    return Accordion;
}());
export { Accordion };
