<template>
  <div data-test="header-user-avatar" class="header-user-avatar">
    <button
      class="header-user-avatar__button"
      :data-dropdown-button="dropdownId"
      @click="openDropdown = !openDropdown"
    >
      <USAvatar
        :src="user.profilePicture || ''"
        :srcset="user.profilePictureSrcset || ''"
        :alt="profilePictureAltText"
        :initials="userInitials"
        sizes="var(--iconDiameter)"
        class="header-user-avatar__avatar"
      />
      <div v-if="!iconOnly" class="header-user-avatar__info">
        <span class="header-user-avatar__full-name">
          {{ userFullName }}
        </span>
        <div
          v-if="user.activeMembership"
          class="header-user-avatar__points margin-top--8"
        >
          <USRatingStars :stars="1" :filled="0" css-diameter="17px" />
          <span class="header-user-avatar__points-label">
            {{ Math.max(user.pointsBalance, 0) }} pts
          </span>
        </div>
      </div>
    </button>
    <USDropdownMenu
      v-if="dropdownMenuItems.length"
      :id="dropdownId"
      class="header-user-avatar__dropdown-menu"
      :options="dropdownMenuItems"
      align="right"
      :open="openDropdown"
      @close="openDropdown = false"
      @clickOption="handleClickOption"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import { RouteLocationRaw } from 'vue-router'

import USAvatar from '@base/components/USAvatar/USAvatar.vue'
import USDropdownMenu from '@base/components/USDropdownMenu/USDropdownMenu.vue'
import USRatingStars from '@base/components/USRatingStars/USRatingStars.vue'

interface MenuItem {
  label: string
  to?: RouteLocationRaw
}

interface User {
  firstName: string
  lastName: string
  profilePicture?: string
  profilePictureSrcset?: string
  activeMembership?: boolean
  pointsBalance?: number
}

export default defineComponent({
  name: 'HeaderUserAvatar',
  components: { USAvatar, USDropdownMenu, USRatingStars },
  props: {
    dropdownId: {
      type: String,
      required: true
    },
    dropdownMenuItems: {
      type: Array as PropType<Array<MenuItem>>,
      default: [] as Array<MenuItem>
    },
    user: {
      type: Object as PropType<User>,
      required: true
    },
    iconOnly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['clickOption'],
  setup(props, { emit }) {
    const openDropdown = ref(false)
    const userInitials = computed(
      () => props.user.firstName.charAt(0) + props.user.lastName.charAt(0)
    )
    const userFullName = computed(
      () => props.user.firstName + ' ' + props.user.lastName
    )
    const profilePictureAltText = computed(() => userFullName.value)
    const handleClickOption = (label: string, idx: number) =>
      emit('clickOption', label, idx)

    return {
      openDropdown,
      userInitials,
      userFullName,
      profilePictureAltText,
      handleClickOption
    }
  }
})
</script>

<style lang="scss" scoped>
@use '@base/styles/v1.0/scss/foundations/breakpoint' as bp;

.header-user-avatar {
  --iconDiameter: 40px;
  display: inline-block;

  @include bp.min-width(bp.$breakpoint--tablet) {
    --iconDiameter: 40px;
  }

  &__button {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
  }

  &__info {
    display: none;
    margin-left: var(--spacing--7);

    @include bp.min-width(bp.$breakpoint--9) {
      display: block;
    }
  }

  &__full-name {
    color: var(--color--primary-b2);
    display: block;
  }

  &__points {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  &__points-label {
    padding-left: var(--spacing--8);
  }

  &__dropdown-menu {
    margin-top: var(--spacing--8);
  }
}
</style>
