<script setup lang="ts">
import {
  defineComponent,
  defineProps,
  ref,
  onMounted,
  computed,
  onBeforeUnmount
} from 'vue'
import AspectRatioContainer from '@base/components/AspectRatioContainer/AspectRatioContainer.vue'
import { IntersectionObserverWrapper } from '@base/helpers/IntersectionObserverWrapper'

defineComponent({
  name: 'ImageWrapper'
})

const props = defineProps({
  // --- HTML attributes ---
  alt: {
    type: String,
    default: ''
  },
  height: {
    type: String,
    default: ''
  },
  longdesc: {
    type: String,
    default: ''
  },
  referrerpolicy: {
    type: String,
    default: 'strict-origin-when-cross-origin',
    validator: (value: string) => {
      return [
        'no-referrer',
        'no-referrer-when-downgrade',
        'origin',
        'origin-when-cross-origin',
        'same-origin',
        'strict-origin',
        'strict-origin-when-cross-origin',
        'unsafe-url'
      ].includes(value)
    }
  },
  sizes: {
    type: String,
    default: ''
  },
  src: {
    type: String,
    default: ''
  },
  srcset: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  },
  width: {
    type: String,
    default: ''
  },

  // --- Non-HTML attributes ---
  aspectRatio: {
    type: String,
    default: '16-9',
    validator: (value: string) => {
      return ['16-9', '9-16', '4-3', '3-4', '1'].includes(value)
    }
  },
  fadeIn: {
    type: Boolean,
    default: true
  },
  lazy: {
    type: Boolean,
    default: true
  },
  objectFit: {
    type: String,
    default: 'contain',
    validator: (value: string) => {
      return ['contain', 'cover', 'fill', 'none', 'scale-down'].includes(value)
    }
  },
  objectPosition: {
    type: String,
    default: 'center center'
  },
  placeholderBackgroundColor: {
    type: String,
    default: '#EBEBEB'
  },
  showPlaceholder: {
    type: Boolean,
    default: false
  }
})

const imageRef = ref<HTMLImageElement>()
let imageLoaded = ref(false)
let intersectionObserver: IntersectionObserverWrapper | null = null

onMounted(() => {
  // @load not consistently called for non-lazy, so we force it to true
  imageLoaded.value = props.lazy ? false : true

  lazyLoadInit()
})

onBeforeUnmount(() => {
  if (intersectionObserver) {
    intersectionObserver.destroy()
  }
})

const usesAspectRatio = () => {
  return props.height === ''
}

const computedWrapperTag = computed(() => {
  return usesAspectRatio() ? AspectRatioContainer : 'div'
})

const computedAspectRatio = computed(() => {
  return usesAspectRatio() ? props.aspectRatio : undefined
})

const computedWrapperModifierClass = computed(() => {
  return usesAspectRatio()
    ? 'image-wrapper--variableHeight'
    : 'image-wrapper--fixedHeight'
})

const computedImageVisibilityClass = computed(() => {
  // Hide browser broken link icon when `src` attribute missing
  let c
  if (props.lazy) {
    c = imageLoaded.value
      ? 'image-wrapper__img--visible'
      : 'image-wrapper__img--hidden'
  } else {
    c = 'image-wrapper__img--visible'
  }
  return c
})

const computedImageFadeOnLoadClass = computed(() => {
  return props.fadeIn && imageLoaded.value
    ? 'image-wrapper__img--fade-in--final-state'
    : ''
})

const onImageLoad = () => {
  imageLoaded.value = true
}

const lazyLoadInit = () => {
  if (props.lazy) {
    intersectionObserver = new IntersectionObserverWrapper(
      imageRef.value,
      {},
      lazyLoadOnVisible
    )
    intersectionObserver.init()
  }
}

const lazyLoadOnVisible = () => {
  const image = imageRef.value
  if (!image) return

  if (image.dataset.src) {
    image.src = image.dataset.src
  }
  if (image.dataset.srcset) {
    image.srcset = image.dataset.srcset
  }
  if (image.dataset.sizes) {
    image.sizes = image.dataset.sizes
  }

  // No longer needed
  if (intersectionObserver) {
    intersectionObserver.destroy()
  }
}
</script>

<template>
  <component
    :is="computedWrapperTag"
    data-test="image-wrapper"
    :class="['image-wrapper', computedWrapperModifierClass]"
    :aspect-ratio="computedAspectRatio"
    :style="{
      width: width ? width : 'auto',
      height: height ? height : 'auto'
    }"
  >
    <div
      v-if="showPlaceholder"
      class="image-wrapper__placeholder"
      :style="{ background: placeholderBackgroundColor }"
    />
    <img
      ref="imageRef"
      :class="[
        'image-wrapper__img',
        fadeIn ? 'image-wrapper__img--fade-in' : '',
        computedImageFadeOnLoadClass,
        computedImageVisibilityClass
      ]"
      :data-sizes="lazy && sizes ? sizes : undefined"
      :data-src="lazy && src ? src : undefined"
      :data-srcset="lazy && srcset ? srcset : undefined"
      :sizes="lazy ? undefined : sizes"
      :src="lazy ? undefined : src"
      :srcset="lazy ? undefined : srcset"
      :alt="alt"
      :longdesc="longdesc"
      :referrerpolicy="referrerpolicy"
      :title="title"
      draggable="false"
      @load="onImageLoad"
    />
  </component>
</template>

<style lang="scss" scoped>
.image-wrapper {
  &--fixedHeight {
    height: inherit;
  }

  &__placeholder {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__img {
    height: 100%;
    object-fit: v-bind(objectFit);
    object-position: v-bind(objectPosition);
    width: 100%;

    &--fade-in {
      opacity: 0;
      transition: opacity 0.24s ease-in;
      will-change: opacity;
    }

    &--fade-in--final-state {
      opacity: 1;
    }

    &--visible {
      visibility: visible;
    }

    &--hidden {
      visibility: hidden;
    }
  }
}
</style>
