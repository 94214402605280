import { RouteRecordRaw } from 'vue-router'

import { reviewsRouteNames } from './routeNames'
import { usersRouteNames } from '@player/users/router/routeNames'

const reviewsMeta = {
  requiresAuth: {
    auth: true,
    authRedirect: {
      name: usersRouteNames.SignIn
    }
  }
}

export const reviewsRoutes: RouteRecordRaw[] = [
  {
    name: reviewsRouteNames.NewReview,
    path: '/reviews/new',
    component: () => import('../Review'),
    props: (route) => ({
      bookingId: route.query.bookingId,
      rating: route.query.rating
    }),
    meta: {
      ...reviewsMeta
    }
  },
  {
    name: reviewsRouteNames.EditReview,
    path: '/review/:reviewId/edit',
    // component: () => import('../EditReview'),
    component: () => import('../Review'),
    props: (route) => ({
      reviewId: route.params.reviewId
    }),
    meta: {
      ...reviewsMeta
    }
  }
]
