var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import EventEmitter from 'events';
var Lightbox = /** @class */ (function (_super) {
    __extends(Lightbox, _super);
    function Lightbox(lightbox, openBtn, closeBtn, enableKeyboard) {
        if (enableKeyboard === void 0) { enableKeyboard = true; }
        var _this = _super.call(this) || this;
        _this.lightbox = lightbox;
        _this.openBtn = openBtn;
        _this.closeBtn = closeBtn;
        _this.enableKeyboard = enableKeyboard;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        _this._body = document.querySelector('body');
        _this._scrollPosition = 0;
        _this._openState = false;
        return _this;
    }
    /**
     * Init lightbox class
     */
    Lightbox.prototype.init = function () {
        var _this = this;
        if (!this._body || !this.lightbox)
            return false;
        this.openFunction = this.open.bind(this);
        this.closeFunction = this.close.bind(this);
        this.escapeCloseFunction = this._escapeClose.bind(this);
        if (this.enableKeyboard) {
            this._body.addEventListener('keydown', this.escapeCloseFunction);
        }
        // Use toggle button
        if (this.openBtn === this.closeBtn && this.openBtn && this.closeBtn) {
            this.openBtn.addEventListener('click', function () {
                if (_this._openState)
                    _this.close();
                else
                    _this.open();
            });
        }
        else {
            if (this.openBtn)
                this.openBtn.addEventListener('click', this.openFunction);
            if (this.closeBtn) {
                this.closeBtn.addEventListener('click', this.closeFunction);
            }
        }
        return true;
    };
    Lightbox.prototype.destroy = function () {
        if (this.openBtn && this.openFunction)
            this.openBtn.removeEventListener('click', this.openFunction);
        if (this.closeBtn && this.closeFunction)
            this.closeBtn.removeEventListener('click', this.closeFunction);
        if (this._body && this.escapeCloseFunction)
            this._body.removeEventListener('keydown', this.escapeCloseFunction);
    };
    /**
     * Open lightbox
     */
    Lightbox.prototype.open = function () {
        if (!this.lightbox || !this._body)
            throw new Error('Call init first');
        if (this.isOpen())
            return; // Already opened
        this._scrollPosition = window.pageYOffset;
        this.lightbox.classList.add('show');
        this._body.classList.add('lightbox--open');
        this._body.style.top = "-" + this._scrollPosition + "px";
        this._openState = true;
        // Emit event
        this.emit('toggle', this.isOpen());
    };
    /**
     * Close lightbox
     */
    Lightbox.prototype.close = function () {
        if (!this.lightbox || !this._body)
            throw new Error('Call init first');
        if (!this.isOpen())
            return; // Already closed
        this.lightbox.classList.remove('show');
        this._body.classList.remove('lightbox--open');
        this._body.style.removeProperty('top');
        window.scrollTo(0, this._scrollPosition);
        this._openState = false;
        // Emit event
        this.emit('toggle', this.isOpen());
    };
    Lightbox.prototype.isOpen = function () {
        return this._openState;
    };
    /**
     * Close lightbox after clicking escape key
     * @param e keyboard event
     */
    Lightbox.prototype._escapeClose = function (e) {
        if (e.key == 'Escape') {
            this.close();
        }
    };
    return Lightbox;
}(EventEmitter));
export { Lightbox };
