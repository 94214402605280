<template>
  <footer data-test="footer" class="footer">
    <div class="footer__wrapper row">
      <div
        class="footer__content footer__column grid__column grid__column--12 grid__column--breakpoint--8--7 grid__column--breakpoint--13--9"
      >
        <p class="footer__copyright body--p2--regular">
          &copy; 1999-{{ new Date().getFullYear() }} Alberta Golf. All Rights
          Reserved.
        </p>
        <nav
          v-if="links.length && player"
          class="footer__links body--p2--regular"
        >
          <router-link
            v-for="(link, idx) in links"
            :key="idx"
            class="footer__link"
            :to="link.to"
            @click="$emit('click', link)"
          >
            {{ link.label }}
          </router-link>
        </nav>
      </div>
      <nav
        v-if="socialLinks.length"
        class="footer__social-links footer__column grid__column grid__column--12 grid__column--breakpoint--7--3 grid__column--breakpoint--13--3"
      >
        <a
          v-for="(link, j) in socialLinks"
          :key="j"
          :href="link.url"
          target="_blank"
          class="footer__social-link"
        >
          <USIcon
            :name="link.icon"
            class="footer__social-icon"
            fill="var(--color--neutral-a5)"
          />
        </a>
      </nav>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { RouteLocationRaw } from 'vue-router'
import USIcon from '@base/components/USIcon/USIcon.vue'

interface Link {
  label: string
  to: RouteLocationRaw
}

interface SocialLink {
  icon: string
  url: string
}

export default defineComponent({
  name: 'Footer',
  components: { USIcon },
  props: {
    links: {
      type: Array as PropType<Array<Link>>,
      default: [] as Array<Link>
    },
    socialLinks: {
      type: Array as PropType<Array<SocialLink>>,
      default: [] as Array<SocialLink>
    },
    player: {
      type: Boolean,
      default: true
    }
  },
  emits: ['click']
})
</script>

<style lang="scss" scoped>
@use '@base/styles/v1.0/scss/foundations/breakpoint' as bp;

.footer {
  background-color: var(--color--primary-b2);
  color: var(--color--neutral-a5);
  padding-bottom: var(--spacing--5);
  padding-top: var(--spacing--5);

  &__wrapper {
    @include bp.min-width(bp.$breakpoint--tablet) {
      flex-flow: column nowrap;
    }

    @include bp.min-width(bp.$breakpoint--desktop) {
      align-items: center;
      flex-flow: row nowrap;
    }
  }

  &__content {
    order: 2;

    @include bp.min-width(bp.$breakpoint--desktop) {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;

      order: 1;
    }

    & > * {
      margin-bottom: var(--spacing--9);
      margin-top: var(--spacing--9);
    }
  }

  &__copyright {
    color: var(--color--neutral-a5);

    @include bp.min-width(bp.$breakpoint--desktop) {
      margin-right: var(--spacing--6);
    }
  }

  &__link {
    white-space: nowrap;

    &:not(:last-child) {
      margin-right: var(--spacing--6);
    }
  }

  &__social-links {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;

    margin-bottom: var(--spacing--7);
    order: 1;

    @include bp.min-width(bp.$breakpoint--desktop) {
      justify-content: flex-end;
      margin-bottom: 0;
      order: 2;
    }
  }

  &__social-link {
    &:not(:last-child) {
      margin-right: var(--spacing--7);
    }
  }
}
</style>
