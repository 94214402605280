<template>
  <div class="toasts-wrapper">
    <transition-group name="toast-list">
      <button
        v-for="(toast, idx) in toasts"
        :key="toast.id || idx"
        class="toasts-wrapper__toast-button"
        @click="handleClickToast(toast.id || idx.toString())"
      >
        <USToast
          :content="toast.content"
          :icon="toast.icon"
          :semantic="toast.semantic"
          class="toasts-wrapper__toast"
        />
      </button>
    </transition-group>
  </div>
</template>

<script lang="ts" setup>
import { defineComponent, defineEmits, defineProps, PropType } from 'vue'

import USToast from '@base/components/USToast/USToast.vue'

import { Toast } from '@base/_types/Toast'

defineComponent({
  name: 'ToastsWrapper',
  components: {
    USToast
  }
})

defineProps({
  toasts: {
    type: Array as PropType<Toast[]>,
    default: () => []
  }
})

const emit = defineEmits(['clickToast'])

const handleClickToast = (id: string) => {
  emit('clickToast', id)
}
</script>

<style lang="scss" scoped>
.toasts-wrapper {
  pointer-events: none;
  width: 100%;

  &__toast-button {
    display: block;
    margin-top: var(--spacing--7);
    pointer-events: all;
    width: 100%;

    &:first-child {
      margin-top: 0;
    }
  }

  &__toast {
    width: 100%;
  }
}

.toast-list {
  &-enter-active,
  &-leave-active {
    transition: all 1s ease;
  }

  &-enter-from {
    opacity: 0;
    transform: translateY(30px);
  }

  &-leave-to {
    opacity: 0;
    transform: translateY(-30px);
  }
}
</style>
