import Cleave from 'cleave.js';
import { defineComponent } from 'vue';
export var CleaveVue = defineComponent({
    name: 'cleave',
    template: '<input type="text" v-on:keyup="updateValue" />',
    props: ['value', 'options'],
    data: function () {
        return { cleave: Cleave.prototype };
    },
    mounted: function () {
        this.cleave = new Cleave(this.$el, this.options);
        this.cleave.setRawValue(this.value);
    },
    unmounted: function () {
        this.cleave.destroy();
    },
    watch: {
        value: 'updateInput'
    },
    methods: {
        updateValue: function () {
            var val = this.cleave.getRawValue();
            if (val !== this.value) {
                this.$emit('input', val);
            }
        },
        updateInput: function () {
            this.cleave.setRawValue(this.value);
        }
    }
});
