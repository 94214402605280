import { RouteRecordRaw } from 'vue-router'
import { eventsRouteNames } from './routeNames'
import { usersRouteNames } from '@player/users/router/routeNames'

export const eventsRoutes: Array<RouteRecordRaw> = [
  {
    name: eventsRouteNames.Events,
    path: '/events/:page?',
    component: () => import('../Events'),
    props: (route) => {
      const handleNumber = (val: string | undefined, float = false) => {
        if (!val) return undefined
        if (float) {
          const floatVal = parseFloat(val)
          if (!floatVal) return undefined
          return floatVal
        } else {
          const intVal = parseInt(val)
          if (!intVal) return undefined
          return intVal
        }
      }

      const pageNumber = route.params.page
        ? handleNumber(route.params.page.toString())
        : 1

      return {
        sort: route.query.sort?.toString(),
        page: pageNumber,
        type: route.query.type?.toString(),
        types: route.query.types?.toString(),
        start: route.query.start?.toString(),
        end: route.query.end?.toString(),
        lat: handleNumber(route.query.lat?.toString(), true),
        lon: handleNumber(route.query.lon?.toString(), true),
        loc: route.query.loc?.toString(),
        query: route.query.query?.toString(),
        clubs: route.query.clubs?.toString(),
        amenities: route.query.amenities?.toString()
      }
    }
  },
  {
    name: eventsRouteNames.EventSingle,
    path: '/event/:slug',
    component: () => import('../EventSingle'),
    props: true
  },
  {
    name: eventsRouteNames.EventDetail,
    path: '/event/edit/:eventId',
    component: () => import('../EventEdit'),
    meta: {
      requiresAuth: {
        auth: true,
        authRedirect: {
          name: usersRouteNames.SignIn
        }
      }
    }
  }
]
